import React from "react";
import Components from "../..";
const DigitalProcess = () => {
  return (
    <>
      <Components.Common.FaqsSection
        heading="Digital Marketing Process"
        text="At Pixelette Technologies, we meticulously craft your website, ensuring minimal issues and maximum alignment with your business objectives. Here's our streamlined web development process:"
      />
    </>
  );
};

export default DigitalProcess;
