import * as commonAssests from "./common";
import * as home from "./home";
import * as aboutUs from "./aboutUs";
import * as aiServices from "./aiServices";
import * as policies from "./policies";
import * as blogs from "./blogs";
import * as caseSlider from "./caseStudies";
import * as blockChain from "./blockChain";
import * as arAndVr from "./arAndVr";
import * as webDevelopment from "./webDevelopment";
import * as software from "./softWare";
import * as mobileDevelopment from "./mobileDevelopment";
import * as ui from "./ui";
import * as digitalMarketing from "./digitalMarketing";
import * as staff from "./stuffAgumented";
import * as research from "./research";
import * as marketing from "./marketing";
import * as contactUs from "./contactUs";
import * as awards from "./awards";

const assets = {
  commonAssests,
  home,
  aiServices,
  aboutUs,
  blogs,
  policies,
  caseSlider,
  blockChain,
  arAndVr,
  webDevelopment,
  mobileDevelopment,
  software,
  ui,
  digitalMarketing,
  staff,
  marketing,
  research,
  contactUs,
  awards,
};

export default assets;
