import React from "react";

const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`btn btn--${props.className}`}
      data-aos={props.animation}
      type={props.type}
      data-aos-duration={props.duration}
    >
      {props.children}
    </button>
  );
};

export default Button;
