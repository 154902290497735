import React from "react";
import Components from "../..";
import assets from "../../../assets";
import { Link } from "react-scroll";
const CaseStudieHeroSection = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSectionCaseStudies-background">
          <img src={assets.caseSlider.caseSliderBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSectionCaseStudies">
        <Components.Feature.Container className="main margins ">
          <blockquote>
            <img src={assets.caseSlider.box_1} alt="box" />
            <img src={assets.caseSlider.box_3} alt="box" />
            <img src={assets.caseSlider.box_2} alt="box" />
            <img src={assets.caseSlider.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`400`}
            >
              Empowering Businesses with Pixel-Perfect Solutions
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="500"
            >
              Explore Pixelette Technologies: where innovation meets success.
              Discover how we transform visions into reality for businesses.
              Witness our strategies, execution, and groundbreaking results
              firsthand in our case studies.
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="700"
            >
              Let's Discuss Your Project
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default CaseStudieHeroSection;
