import { useEffect, useState } from "react";
import Components from "../..";
import assets from "../../../assets";
import Timeline from "../../Timeline";

const OriginStory = () => {
  const [isWideScreen, setIsWideScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="originStory-background">
        <Components.Feature.Container className="main">
          <img src={assets.aboutUs.howWeWorkBackground} alt="background" />
        </Components.Feature.Container>
      </div>
      <div className="originStory">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="400"
            id="h_ani"
          >
            Our Journey: Milestones and Achievements
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="fade-up"
            duration="500"
          >
            Explore key moments in Pixelette Technologies history, highlighting
            our path from inception to becoming a leader in technology and
            innovation:
          </Components.Feature.Text>
        </center>
        
        <Timeline/>
        {/* <Components.Feature.Container className="main">
          <section>
            <div>
              <div
                data-aos={isWideScreen ? "fade-right" : "fade-up"}
                data-aos-duration="600"
              >
                {!isWideScreen && <b>2018</b>}

                <Components.Feature.Heading className="blogCardHeading">
                  January 2020
                </Components.Feature.Heading>
                <Components.Feature.Text className="titory--bold">
                  Transition into artificial intelligence development, starting
                  with projects aimed at enhancing scalability and performance.
                </Components.Feature.Text>
              </div>
              <div
                data-aos={isWideScreen ? "fade-right" : "fade-up"}
                data-aos-duration="600"
              >
                {!isWideScreen && <b>2020</b>}
                <Components.Feature.Heading className="blogCardHeading">
                  April 2024
                </Components.Feature.Heading>
                <Components.Feature.Text className="titory--bold">
                  Recognised to be in the top 30 software development companies
                  worldwide.
                </Components.Feature.Text>
              </div>
            </div>

            <div>
              <img
                src={assets.aboutUs.originStoryHeroImage}
                alt="hero"
                data-aos="zoom-in"
                data-aos-duration="2900"
              />
            </div>
            
            <div>
              <div
                data-aos={isWideScreen ? "fade-left" : "fade-up"}
                data-aos-duration="700"
              >
                {!isWideScreen && <b>2023</b>}

                <Components.Feature.Heading className="blogCardHeading">
                  Lorem ipsum dolor sit amet!!
                </Components.Feature.Heading>
                <Components.Feature.Text className="titory--bold">
                  Lorem ipsum dolor sit amet consectetur. Enim platea maecenas
                  eget facilisis volutpat habitant feugiat in. At tempor
                  suspendisse netus ut egestas nunc massa justo ut. Amet turpis
                  sagittis id tincidunt quam.
                </Components.Feature.Text>
              </div>
              <div
                data-aos={isWideScreen ? "fade-left" : "fade-up"}
                data-aos-duration="700"
              >
                {!isWideScreen && <b>2024</b>}
                <Components.Feature.Heading className="blogCardHeading">
                  January 2023
                </Components.Feature.Heading>
                <Components.Feature.Text className="titory--bold">
                  Achieved recognition as a Top AI and Blockchain Company in the
                  UK, affirming our position on the global tech stage
                </Components.Feature.Text>
              </div>
            </div>
          </section>
        </Components.Feature.Container> */}
      </div>
    </>
  );
};

export default OriginStory;
