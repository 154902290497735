import React,{useState} from "react";
import Components from "..";
import { FiExternalLink } from "react-icons/fi";
import data from "../../data";
import { v4 as uuidv4 } from "uuid";

// const OurTeamSection = () => {
//   return (
//     <>
//       <div className="ourTeamSection">
//         <Components.Feature.Container className="main margins">
//           <center>
//             <Components.Feature.Heading
//               className="secondry"
//               animation="fade-up"
//               duration="500"
//               id="h_ani"
//             >
//               Our Team
//             </Components.Feature.Heading>
//           </center>
//           <Components.Feature.Text
//             className="secondry"
//             animation="fade-up"
//             duration="700"
//           >
//             Meet the exceptional professionals at Pixelette Technologies, where
//             90% of our staff rank in the global top 5% in their fields. Discover
//             the experts behind our magic.
//           </Components.Feature.Text>
//           <section>
//             {data.teamData.map((el, index) => (
//               <TeamCard
//                 image={el.image}
//                 role={el.role}
//                 name={el.name}
//                 key={uuidv4()}
//                 animation="fade-up"
//                 duration={`${index + 3}00`}
//               />
//             ))}
//           </section>
//           <div>
//             <center style={{ marginTop: "4.8rem" }}>
//               <Components.Feature.Button className="primary">
//                 Load More
//               </Components.Feature.Button>
//             </center>
//           </div>
//         </Components.Feature.Container>
//       </div>
//     </>
//   );
// };

// export default OurTeamSection;

const OurTeamSection = () => {
  const [visibleCards, setVisibleCards] = useState(12);

  const handleLoadMore = () => {
    setVisibleCards((prev) => prev + 8);
  };

  return (
    <div className="ourTeamSection">
      <Components.Feature.Container className="main margins">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="500"
            id="h_ani"
          >
            Our Team
          </Components.Feature.Heading>
        </center>
        <Components.Feature.Text
          className="secondry"
          animation="fade-up"
          duration="700"
        >
          The people behind the work. <br/> 90% of our team ranks among the global top 5 in their fields.
        </Components.Feature.Text>
        <section>
          {data.teamData.slice(0, visibleCards).map((el, index) => (
            <TeamCard
              image={el.image}
              role={el.role}
              name={el.name}
              key={uuidv4()}
              animation="fade-up"
              duration={`${index + 3}00`}
            />
          ))}
        </section>
        {visibleCards < data.teamData.length && (
          <div>
            <center style={{ marginTop: "4.8rem" }}>
              <Components.Feature.Button className="primary" onClick={handleLoadMore}>
                Load More
              </Components.Feature.Button>
            </center>
          </div>
        )}
      </Components.Feature.Container>
    </div>
  );
};

export default OurTeamSection;


const TeamCard = (props) => {
  return (
    <div data-aos={props.animation} data-aos-duration={props.duration}>
      <div className="image-container"> <img src={props.image} alt="profile" /></div>
      <Components.Feature.Text className="primary--bold">
        {props.name}
      </Components.Feature.Text>
      <Components.Feature.Text className="titory">
        {props.role}
      </Components.Feature.Text>
    </div>
  );
};
