import assets from "../../assets";

export const technologyStackArandVrData = [
  assets.arAndVr.tsw_1,
  assets.arAndVr.tsw_2,
  assets.arAndVr.tsw_3,
  assets.arAndVr.tsw_4,
  assets.arAndVr.tsw_5,
  assets.arAndVr.tsw_6,
  assets.arAndVr.tsw_7,
];
