import React from "react";
import Components from "../..";
import assets from "../../../assets";

const UnderStandingUI = () => {
  return (
    <Components.Feature.Container className="main margins">
      <div className="underStandingUI">
        <header>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="500"
          >
            Understanding UI/UX: The Cornerstones of Digital Success
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="fade-up"
            duration="600"
          >
            UI (User Interface) and UX (User Experience) are pivotal in crafting
            digital experiences that not only look good but also function
            seamlessly. UI enhances the touchpoints along that journey with engaging visuals
            and interactions. UX focuses on the journey your customers take, aiming to
            make it as intuitive and pleasant as possible. Meanwhile,  together, they play a crucial role in satisfying
            users and boosting your business’s online performance.
          </Components.Feature.Text>
        </header>
        <div>
          <img src={assets.ui.bannerImage} alt="banner" />
        </div>
      </div>
    </Components.Feature.Container>
  );
};

export default UnderStandingUI;
