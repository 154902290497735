import assets from "../../assets";
export const expertiseSoftwareData = [
  {
    image: assets.software.e_1,
    text: "Healthcare ",
  },
  {
    image: assets.software.e_2,
    text: "Finance",
  },
  {
    image: assets.software.e_3,
    text: "Retail",
  },
  {
    image: assets.software.e_4,
    text: "Manufacturing",
  },
  {
    image: assets.software.e_5,
    text: "Energy",
  },
  {
    image: assets.software.e_6,
    text: "Government",
  },
  {
    image: assets.software.e_7,
    text: "Media and Advertising",
  },
  {
    image: assets.software.e_8,
    text: "Tourism and Hospitality",
  },
];
