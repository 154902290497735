import React from "react";
import Components from "../..";
import assets from "../../../assets";

const OurTeamAbout = () => {
  return (
    <>
      <div className="ourTeamAboutSection">
        <Components.Feature.Container className="main">
          <img src={assets.aboutUs.howWeWorkBackground} alt="background" />
          <img src={assets.aboutUs.ourTeamBackgroundLeft} alt="background" />
        </Components.Feature.Container>
        <Components.Common.OurTeamSection />
        <center>

        <button
              style={{
                marginTop: "30px",
                background: "gray",
                borderRadius: "10px",
                padding: "10px 20px",
                color: "white",
                // fontSize:"16px"
              }}
            >
              Load More
            </button>
                </center>
      </div>
    </>
  );
};

export default OurTeamAbout;
