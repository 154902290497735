import assets from "../assets";

export const teamData = [
  {
    image: assets.commonAssests.Asif,
    role: "CEO & Founder",
    name: "Mr. Asif Ashiq Rana",
  },
  {
    image: assets.commonAssests.Hamza,
    role: "MD (Global)",
    name: "Humza Chishty",
  },
  {
    image: assets.commonAssests.Eric_R_Smithers,
    name: "Eric R. Smithers",
    role: "MD (USA)",
  },
  {
    image: assets.commonAssests.barry,
    name: "Barry Perkins",
    role: "SVP (USA)",
  },
  {
    image: assets.commonAssests.Temur_Khan,
    name: "Temur Khan",
    role: "Chief Technology Officer",
  },
  {
    image: assets.commonAssests.Emmanuel_Ruiz_Jobim,
    name: "Emmanuel Ruiz-Gimenez Jobim",
    role: "Head of Business Development",
  },
  {
    image: assets.commonAssests.Gulafsha,
    name: "Gul Afshan",
    role: "Head of Engineering (AI)",
  },
  {
    image: assets.commonAssests.Ammar_Hanif,
    name: "Ammar Hanif",
    role: "Head of Engineering (Blockchain)",
  },
  {
    image: assets.commonAssests.Ayesha_Yaqoob,
    name: "Ayesha Yaqoob",
    role: "Solutions Architect (Blockchain)",
  },
  {
    image: assets.commonAssests.Kainat_Mansoor,
    name: "Kainat Mansoor",
    role: "Solutions Architect (AI)",
  },
  {
    image: assets.commonAssests.Jawad,
    name: "Muhammad Jawad",
    role: "Head of Project Management",
  },
  {
    image: assets.commonAssests.Ghulam,
    name: "Ghulam Mustafa",
    role: "Head of Product Design",
  },
  {
    image: assets.home.nurben_karatuna,
    name: "Yann B",
    role: "Web3 Marketing Strategist",
  },
  {
    image: assets.home.yan_b,
    name: "Nurben Karatunee",
    role: "Head of Bussiness Development Turkiye",
  },
];
