import assets from "../../assets";

export const mobileServicesData = [
  {
    icon: assets.mobileDevelopment.s_1,
    name: "Responsive Design    ",
    description:
      "Capture every user's attention. Our responsive designs ensure your app looks and works perfectly on any device, eliminating the frustration of usability issues and enhancing user retention.",
  },
  {
    icon: assets.mobileDevelopment.s_2,
    name: "Custom Functionality",
    description:
      "Tailored to fit your needs. We develop custom functionalities that solve specific business challenges, enhancing your operational efficiency and providing a seamless user experience that drives conversion.",
  },
  {
    icon: assets.mobileDevelopment.s_3,
    name: "Rapid Development",
    description:
      "Speed to market matters. Our agile development processes ensure that your app is developed not just quickly but also correctly, reducing time-to-market and allowing you to capitalise on opportunities faster.",
  },
  {
    icon: assets.mobileDevelopment.s_4,
    name: "Robust Security",
    description:
      "Security is paramount. We embed strong security protocols from the ground up to protect your data and maintain user trust, addressing the critical pain point of vulnerability to data breaches",
  },
  {
    icon: assets.mobileDevelopment.s_5,
    name: "Scalable Architectures",
    description:
      "Grow without barriers. We build scalable mobile applications that can handle an increasing load of users and data, ensuring your app supports your business growth without performance hitches.",
  },
  {
    icon: assets.mobileDevelopment.s_7,
    name: "Seamless Integration",
    description:
      "Integrate effortlessly. Our solutions ensure that your app integrates smoothly with existing systems and third-party services, enhancing functionality and user experience without disrupting your ecosystem.",
  },
  {
    icon: assets.mobileDevelopment.s_6,
    name: "Continuous Support and optimisation",
    description:
      "Beyond launch support. We provide continuous monitoring, updates, and optimisations based on user feedback and evolving technology trends, ensuring your app stays relevant and performs optimally.",
  },
  {
    icon: assets.mobileDevelopment.s_8,
    name: "Analytics and Insights",
    description:
      "Drive decisions with data. Gain valuable insights into user behaviour and app performance with our comprehensive analytics services, helping you make informed decisions to enhance user engagement and ROI.",
  },
];
