import React from "react";
import Components from "../components";

const CaseStudie = () => {
  return (
    <>
      <Components.UI.CaseStudieHeroSection />
      <Components.Common.OurClients />
      <Components.UI.ExploreProjectsSections />
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default CaseStudie;
