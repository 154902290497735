import assets from "../assets";

export const aboutTeamData = [
  {
    image: assets.commonAssests.Hamza,
    role: "MD (Global)",
    name: "Humza Chishty",
  },
  {
    image: assets.commonAssests.Eric_R_Smithers,
    role: "MD (USA)",
    name: "Eric R. Smithers",
  },
  {
    image: assets.commonAssests.barry,
    name: "Barry Perkins",
    role: "SVP (USA)",
  },
  {
    image: assets.commonAssests.Temur_Khan,
    name: "Temur Khan",
    role: "Chief Technology Officer",
  },
  {
    image: assets.commonAssests.ShadowFemale,
    name: "Aliya Maqsood",
    role: "Chief Marketing Officer",
    //ShadowFemale
  },
  {
    image: assets.commonAssests.Shadowmale,
    name: "Asid Hussain",
    role: "Chief Partnerships Officer",
    //Shadowmale
  },
  {
    image: assets.commonAssests.Gulafsha,
    name: "Gul Afshan",
    role: "Head of Engineering (AI)",
  }, 
  {
    image: assets.commonAssests.Ammar_Hanif,
    name: "Ammar Hanif",
    role: "Head of Engineering (Blockchain)",
  },
  {
    image: assets.commonAssests.Shadowmale,
    name: "Muhammad Harris",
    role: "Team Lead (AI)",
    //Shadowmale
  },
  {
    image: assets.commonAssests.M_Adnan,
    name: "Muhamad Adnan",
    role: "Team Lead (Blockchain)",
  },
  {
    image: assets.commonAssests.Ayesha_Yaqoob,
    name: "Ayesha Yaqoob",
    role: "Solutions Architect (Blockchain)",
  },
  {
    image: assets.commonAssests.Kainat_Mansoor,
    name: "Kainat Mansoor",
    role: "Solutions Architect (AI)",
  },
  {
    image: assets.commonAssests.Arooj_Sattar,
    name: "Arooj Sattar",
    role: "Team Lead AI Research",
  },
  {
    image: assets.commonAssests.Faraz_Iqbal,
    name: "Faraz Iqbal",
    role: "Head of Tokenomics",
  },

  {
    image: assets.commonAssests.Adam,
    name: "Adam Hook",
    role: "Senior Biotech Advisor (USA)",
  },
  {
    image: assets.commonAssests.Lawton,
    name: "Lawton Bennati",
    role: "Senior Startup Advisor (Brazil)",
  },
  {
    image: assets.commonAssests.Shadowmale,
    name: "Anysio Espíndola",
    role: "Strategic Growth Advisor (Brazil)",
    //ShadowMale
  },
  {
    image: assets.commonAssests.ShadowFemale,
    name: "Andrea Moura",
    role: "Senior ESG Advisor (Brazil)",
  },
  {
    image: assets.commonAssests.Shadowmale,
    name: "Lenny Krotty",
    role: "Senior Fintech Advisor (USA)",
  },
  
  {
    image: assets.commonAssests.ShadowFemale,
    name: "Mirna Ampuero",
    role: "Strategic Growth Advisor (Peru)",
  },
  {
    image: assets.commonAssests.Cyril_Chiffot,
    name: "Cyril Chiffot",
    role: "Head of Operations (Switzerland)",
  },
  {
    image: assets.commonAssests.Daniel_C_Tschinkel,
    name: "Daniel C. Tschinkel",
    role: "Head of Operations (Germany)",
  },
  {
    image: assets.commonAssests.Jeremy_Joo,
    name: "Jeremy Joo",
    role: "Head of Operations (Malaysia)",
  },
  {
    image: assets.commonAssests.Sackett,
    name: "Dave Sackett",
    role: "Head of Operations (Boston, USA)",
  },
  {
    image: assets.commonAssests.Jawad,
    name: "Muhammad Jawad",
    role: "Head of Project Management",
  },
  {
    image: assets.commonAssests.Ghulam,
    name: "Ghulam Mustafa",
    role: "Head of Product Design",
  },
  {
    image: assets.commonAssests.Emmanuel_Ruiz_Jobim,
    name: "Emmanuel Ruiz Jobim",
    role: "Head of BD (Global)",
  },
  {
    image: assets.commonAssests.Yilma_karatuna,
    name: "Yilma Karatuna",
    role: "Head of BD (Türkiye)",
  },
  {
    image: assets.commonAssests.Ghanva_Azwar,
    name: "Ghanva Anwar",
    role: "Head of Community Management",

  },
  {
    image: assets.commonAssests.Shadowmale,
    name: "Don Retz",
    role: "Head of Marketing (USA)",
    //shadowMale
  },
  {
    image: assets.commonAssests.Sehr,
    name: "Sehr Navid",
    role: "Head of Client Relations",
  },
  {
    image: assets.commonAssests.ShadowFemale,
    name: "Rumaan Naeem",
    role: "Marketing Manager",
    //ShadowFemale
  },
  
  {
    image: assets.commonAssests.M_Ali,
    name: "Syed Muhammad Ali",
    role: "Principal Staff Officer to the CEO",
  },
];
