import assets from "../assets";

export const customerReviews = [
  {
    profile: assets.home.profile1,
    name: "- James K., ",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },
  {
    profile: assets.home.profile2,
    name: "-Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile3,
    name: " Tech ",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile4,
    name: "- James K.,  Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile1,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },
  {
    profile: assets.home.profile2,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile3,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile4,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile1,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile2,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },

  {
    profile: assets.home.profile3,
    name: "- James K., Tech Entrepreneur",
    review:
      "Copto coins changed my game! As a tech enthusiast and savvy investor, I was instantly drawn to the transparency and accessibility of owning real assets through digital tokens. Now, I can track real-time market trends and easily manage my portfolio anytime, anywhere. ",
    rating: 4,
  },
];
