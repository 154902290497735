const answer =
  "Our team starts by identifying the client's goals and requirements for the VR project. Determine what the project should achieve. We also identify what features and functionalities are needed for the client’s project.";

export const arProcessData = [
  {
    question: "Planning and Requirements",
    answer,
  },
  {
    question: "Research and Ideation",
    answer,
  },
  {
    question: "Plan and Design",
    answer,
  },
  {
    question: "Development",
    answer,
  },
  {
    question: "Quality Assurance",
    answer,
  },
  {
    question: "Deployment",
    answer,
  },
  {
    question: "Support and Maintaince",
    answer,
  },
];
