import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import assets from "../../../assets";
import Components from "../..";
import data from "../../../data";
import { v4 as uuidv4 } from "uuid";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SwiperSliderSection = () => {
  return (
    <>
      <div className="swiper-slider-container">
        <Components.Feature.Container className="main margins">
          {/* <blockquote>
            <img src={assets.home.box_14} alt="box" />
            <img src={assets.home.box_15} alt="box" />
          </blockquote> */}
          <center>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="400"
              id="h_ani"
            >
              Industry Focus
            </Components.Feature.Heading>

            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="500"
            >
              Our proficiency in engineering and design extends seamlessly
              across diverse sectors within the realm of blockchain technology.
            </Components.Feature.Text>
          </center>
          <section>
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={3.5}
              spaceBetween={20}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
              {data.swiperSliderData.map((el, index) => (
                <SwiperSlide key={uuidv4()}>
                  <Components.Feature.SwiperCard
                    image={el.image}
                    name={el.name}
                    description={el.description}
                    animation="zoom-out"
                    duration={`1000`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default SwiperSliderSection;
