import React from "react";
import assets from "../../../assets";
import Components from "../..";
import { FiDownload } from "react-icons/fi";
import World from "./World";
import { Link } from "react-router-dom";

const HeroSectionResearch = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSectionResearch-background">
          <img
            src={assets.research.heroSectionBackground}
            alt="researchbackground"
          />
        </div>
      </Components.Feature.Container>
      <div className="heroSectionResearch">
        <center>
          <Components.Feature.Heading
            className="heroHeading"
            animation="zoom-out"
            duration={`500`}
          >
            Pixelette Research
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="zoom-in"
            duration="600"
          >
            Pixelette Research empowers the realm of emerging tech through
            pioneering research and cutting-edge innovation. Our team of experts
            pushes the boundaries, driving transformative solutions that shape
            the future and revolutionise industries.
          </Components.Feature.Text>
        </center>
        <Components.Feature.Container className="main margins">
          <div className="content">
            <div className="globe-container">
              <World className="globe" />
            </div>
            <div className="description">
              <section>
                <Components.Feature.Heading
                  className="secondry"
                  animation="fade-up"
                  duration="500"
                >
                  About Pixelette Research
                </Components.Feature.Heading>
                <Components.Feature.Text
                  className="titory--bold"
                  animation="fade-up"
                  duration="600"
                >
                  Welcome to Pixelette Research, a dynamic research arm driving
                  growth and transformation across industries.
                </Components.Feature.Text>
                <Components.Feature.Text
                  className="titory--bold"
                  animation="fade-up"
                  duration="700"
                >
                  With a focus on areas such as artificial intelligence, machine
                  learning, data science, blockchain, and more, our services
                  encompass comprehensive research projects, insightful data
                  analysis, predictive modeling, and tailored strategies.
                  Additionally, we offer cutting-edge solutions in areas like
                  robotic process automation (RPA), natural language processing
                  (NLP), and decentralised finance (DeFi), enabling our clients
                  to stay ahead in the fast-paced digital landscape.
                </Components.Feature.Text>
                <Components.Feature.Text
                  className="titory--bold"
                  animation="fade-up"
                  duration="700"
                >
                  At Pixelette Research, we are dedicated to pushing the
                  boundaries and empowering organisations to thrive in the
                  digital age. Explore our comprehensive range of services and
                  embark on a transformative journey with Pixelette Research
                  today.
                </Components.Feature.Text>
              </section>
            </div>
          </div>
          <section>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="2500"
            >
              Featured Whitepapers
            </Components.Feature.Heading>
            <div data-aos="fade-up" data-aos-duration="2500">
              <div>
                <Components.Feature.Text className="primary--bold">
                  Unlocking the Power of <br />
                  Machine Learning
                </Components.Feature.Text>
                <Link
                  to="https://drive.google.com/file/d/1eZkyxHc8SuLETYRSO6vkK4nc71fGqIlc/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Components.Feature.Button className="primary">
                    <FiDownload />
                    &nbsp; Download
                  </Components.Feature.Button>
                </Link>
              </div>
              <img src={assets.research.resourceGroup} alt="group" />
            </div>
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionResearch;
