import React, { useEffect, useState } from "react";
import Components from "..";
import { FiExternalLink } from "react-icons/fi";
import data from "../../data";
import { Link } from "react-router-dom";
import { createClient } from "contentful";

const CaseStudies = (props) => {
  const [caseStudyData, setCaseStudyData] = useState([]);

  useEffect(() => {
    const client = createClient({
      space: "ggtsbq0gqfii",
      accessToken: "VZvVye8dMIc497wF-1pNt5rdYUG-h4E30uX58AcGVUo",
    });

    client
      .getEntries({content_type: "caseStudies"})
      .then((response) => {
        console.log("response: ", response);
        setCaseStudyData(response.items);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  return (
    <div className="caseStudySection">
      <Components.Feature.Container className="main margins">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="500"
            id="h_ani"
          >
            {props.heading ? props.heading : "Case Studies"}
          </Components.Feature.Heading>

          <Components.Feature.Text
            className="secondry"
            animation="fade-up"
            duration="600"
          >
            {props.text
              ? props.text
              : "  Explore how our cutting-edge solutions have transformed industries and empowered clients."}
          </Components.Feature.Text>

          <Link to="/case-studies">
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="400"
            >
              View Our Projects
              <FiExternalLink />
            </Components.Feature.Button>
          </Link>
        </center>
        <section>
        
          {/* <Components.Feature.CaseSlider data={data.caseSliderData} /> */}
          
          <Components.Feature.CaseSlider data={caseStudyData} />
          
        </section>
      </Components.Feature.Container>
    </div>
  );
};

export default CaseStudies;
