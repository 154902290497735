import assets from "../../assets";

export const thirdPartyIntegrationData = [
  {
    icon: assets.mobileDevelopment.a_1,
    name: "Skyscanner API",
  },
  {
    icon: assets.mobileDevelopment.a_2,
    name: "Movie Database API",
  },
  {
    icon: assets.mobileDevelopment.a_3,
    name: "Google Map API",
  },
  {
    icon: assets.mobileDevelopment.a_4,
    name: "Spoonacular API",
  },
  {
    icon: assets.mobileDevelopment.a_5,
    name: "Uber API",
  },
  {
    icon: assets.mobileDevelopment.a_6,
    name: "Facebook API",
  },
];
