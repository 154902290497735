import assets from "../../assets";

export const staffServicesData = [
  {
    icon: assets.staff.s_1,
    name: "Flexibility",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempus sagittis porttitor euismod sed nibh sagittis blandit volutpat nunc. Nisl cras purus nec vitae scelerisque.",
  },
  {
    icon: assets.staff.s_2,
    name: "Cost-Effectiveness",
    description:
      "Lorem ipsum dolor sit amet consectetur. Justo in nulla ultricies eget tincidunt nisi aliquam aliquam tellus. Cras euismod ipsum",
  },
  {
    icon: assets.staff.s_3,
    name: "Scalability",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis adipiscing metus egestas nunc enim nisl. Tellus fermentum nisi duis bibendum vitae sit.",
  },
  {
    icon: assets.staff.s_4,
    name: "Reduced Risk",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ullamcorper gravida facilisis erat morbi ipsum montes blandit. Molestie ut sit ",
  },
  {
    icon: assets.staff.s_5,
    name: "Improved Productivity",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ullamcorper gravida facilisis erat morbi ipsum montes blandit. Molestie ut sit ",
  },
  {
    icon: assets.staff.s_6,
    name: "Reduced Overhead Costs",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ullamcorper gravida facilisis erat morbi ipsum montes blandit. Molestie ut sit ",
  },
];
