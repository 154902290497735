import React from "react";
import Components from "../components";
import data from "../data";

const AiServices = () => {
  return (
    <>
      <Components.UI.HeroSectionAiServices />
      <Components.Common.OurClients />
      <Components.UI.ServicesAi />
      <Components.UI.TechnologyStackAi />
      <Components.Common.WhatMakesUsSpecial />
      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.UnlockBusinessPotential
        heading="Explore What AI Has in Store for You"
        text="Ready to transform your business with AI? Schedule your free consultation with our experts today and explore a smarter future."
        btnText="Let’s Innovate!"
      />
      <Components.Common.CaseStudies
        heading="Success in Action: Our AI Case Studies"
        text="Explore our AI Success Stories: real-world examples of how our AI solutions have propelled businesses forward, showcasing tangible results and transformative impacts."
      />
      <Components.Common.ExpertiseGrid
        data={data.expertiseAiChangeData}
        heading="Expertise Across Industries: Tailored AI Solutions"
        text="Spanning Sectors: From healthcare to finance, our AI proficiency is shaping the future of diverse industries with precision-crafted solution."
      />
      <Components.Common.Partners />
      {/* <Components.UI.ExpertiseAi />
      <Components.Common.AchievementsSection /> */}
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection />
      {/* <Components.Common.ContactUs /> */}
      {/* <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default AiServices;
