import React from "react";
import Components from "../..";
import { Formik, Form } from "formik";
import data from "../../../data";

const StartUpsHelpForm = () => {
  const initalData = {
    companyName: "",
    personName: "",
    email: "",
    linkedInURL: "",
    phone: "",
    industry: "",
    websiteUrl: "",
    enterFigure: "",
    competitors: "",
    uniqueSellingProposition: "",
    targetAudience: "",
    allocationOfFunds: "",
    marketingStrategy: "",
    projectDescription: "",
    file: "",
    additionalComments: "",
  };
  return (
    <Components.Feature.Container className="main margins">
      <div className="startUpsFrom">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="500"
          >
            We're here to help!
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="fade-up"
            duration="600"
          >
            At Pixelette Technologies, we offer affordable services and funding
            opportunities for eligible start-ups. Our team of experts in AI,
            blockchain, metaverse, mobile web development and digital marketing
            are here to help you turn your ideas into reality. Apply for funding
            now!
          </Components.Feature.Text>


            
        </center>
        {/* <Components.Common.LetsTalkSection />  */}
        {/*<section>
          { <Components.Feature.Heading className="policyHeading">
            Apply for Funding
          </Components.Feature.Heading> }

          <div>
            { <Formik
              initialValues={initalData}
              validateOnMount
              validationSchema={data.validationStartUps}
              onSubmit={(values, { resetForm }) => {
                console.log(values);
                resetForm();
              }}
            >
              {(formik) => (
                <Form>
                  <section className="startUpsFrom-container">
                    <Components.Feature.Text>
                      Business Contact Information
                    </Components.Feature.Text>
                    <blockquote>
                      <Components.Feature.FormInput
                        name="companyName"
                        label="Company Name"
                        place="Enter your company name"
                      />
                      <Components.Feature.FormInput
                        name="personName"
                        label="Person Name"
                        place="Enter your founder/co-founder’s name"
                      />
                    </blockquote>
                    <blockquote>
                      <Components.Feature.FormInput
                        name="email"
                        label="Email Address"
                        place="Enter your email address"
                      />
                      <Components.Feature.FormInput
                        name="linkedInURL"
                        label="Founder/CEO's Linkedin profile"
                        place="Enter linkedin profile"
                      />
                    </blockquote>
                    <Components.Feature.InputPhoneNo
                      name="phone"
                      label="Phone Number"
                      place="+1 (555) 000-0000"
                    />
                    <p>Business Details</p>
                    <blockquote>
                      <Components.Feature.FormInput
                        name="industry"
                        label="Industry"
                        place="Enter industry"
                      />
                      <Components.Feature.FormInput
                        name="websiteUrl"
                        label="Website"
                        place="Enter your website URL"
                      />
                    </blockquote>
                    <blockquote>
                      <Components.Feature.FormInput
                        name="enterFigure"
                        label="Enter figure in  £"
                        place="Enter figure in  £"
                      />
                      <Components.Feature.FormInput
                        name="competitors"
                        label="Competitors"
                        place="Enter top 3 competitors website URL"
                      />
                    </blockquote>
                    <Components.Feature.FormInput
                      name="uniqueSellingProposition"
                      label="Unique Selling Proposition"
                      place="Enter unique selling proposition"
                    />
                    <blockquote>
                      <Components.Feature.FormInput
                        name="targetAudience"
                        label="Target Audience"
                        place="Enter target audience"
                      />
                      <Components.Feature.FormInput
                        name="allocationOfFunds"
                        label="Allocation of Funds"
                        place="Enter allocation of funds"
                      />
                    </blockquote>
                    <Components.Feature.FormInput
                      name="marketingStrategy"
                      label="Marketing Strategy"
                      place="Enter marketing strategy"
                    />
                    <Components.Feature.FromTextArea
                      name="projectDescription"
                      label="Project Description"
                      place="Write something about project..."
                      rows="7"
                      cols="30"
                    />
                    <Components.Feature.FileInput name="file" />
                    <p>Additional Information</p>
                    <Components.Feature.FromTextArea
                      name="additionalComments"
                      label="Additional Comments (Optional)"
                      place="Write additional comments..."
                      rows="7"
                      cols="30"
                    />
                    <Components.Feature.Button
                      className="primary"
                      type="submit"
                    >
                      Submit Resume
                    </Components.Feature.Button>
                  </section>
                </Form>
              )}
            </Formik> }
          </div>
        </section>*/}
      </div>
         
    </Components.Feature.Container>

  );
};

export default StartUpsHelpForm;
