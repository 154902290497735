import assets from "../../assets";

export const softwareServicesData = [
  {
    icon: assets.software.s_1,
    name: "ERP (Enterprise Resource Planning) Systems",
    description:
      "Streamline business processes and centralise data across finance, HR, procurement, distribution, and other departments.",
  },
  {
    icon: assets.software.s_2,
    name: "CRM (Customer Relationship Management) Systems",
    description:
      "Streamline business processes and centralise data across finance, HR, procurement, distribution, and other departments.",
  },
  {
    icon: assets.software.s_3,
    name: "HRMS (Human Resource Management Systems)",
    description:
      "Automate HR tasks such as payroll, performance evaluations, and benefits management to increase efficiency and ensure compliance.",
  },
  {
    icon: assets.software.s_4,
    name: "Document Management Systems",
    description:
      "Solutions for storing, managing, and tracking electronic documents and electronic images of paper-based information captured through the use of a document scanner.",
  },

  {
    icon: assets.software.s_5,
    name: "Custom E-commerce Solutions",
    description:
      "Tailored platforms to enhance online shopping experiences, integrate with existing operations, and support sales strategies",
  },
  {
    icon: assets.software.s_6,
    name: "Web Application Development",
    description:
      "Create custom web applications tailored to unique business needs, improving usability and functionality for users.",
  },
];
