export const uiFaqs = [
  {
    question: "Where can I find your portfolio of design work?",
    answer:
      "On request, we can send you a link that will take you to our design portfolio.",
  },
  {
    question: "How quickly can you begin working on my project?",
    answer:
      "We always aim to be responsive to our clients. We will not take on work that we cannot commit to.",
  },
  {
    question: "How long will it take to design a product?",
    answer:
      "This depends on how complex the requirements are. We can give firm timescales once we know what the task is.",
  },
  {
    question: "Who will carry out my work?",
    answer:
      "We will align our UX/UI experts along with a dedicated account manager who will be in place throughout the project lifecycle. ",
  },
  {
    question: "How do you keep in touch throughout the project?",
    answer:
      "As our client base is global, we use platforms such as Zoom to interact with clients. We can also agree on other approaches to meet the client’s needs. We aim to have a minimum of fortnightly meetings to review progress.",
  },
];
