export const blockChainSpecialData = [
  {
    heading: "Government Endorsement",
    text: "Blockchain leaders by decree: Chairs of the UK Government’s blockchain Task Force – APPG BLOCKCHAIN. Also working with 7 other governments worldwide",
  },
  {
    heading: "World-Class Team",
    text: "Elite minds at work: Over 200 staff, 90% in the top 5% of global blockchain expertise, with senior management in the top 1%",
  },
  {
    heading: "Extensive Expertise & Worldwide Presence",
    text: "Elevating tech landscapes: 200+ projects, 30,000+ hours of development. Global reach, local genius: Active in 13 countries",
  },
  {
    heading: "Proven Case Studies",
    text: "Transforming industry giants: Our blockchain boosts efficiency for Beowulf, PIP Zero, BlockGuard, Buttersmiles and more",
  },
  {
    heading: "Cost Effective",
    text: "Maximise value, minimise cost: Streamlined blockchain solutions without the premium price tag ",
  },
  {
    heading: "Unmatched Success",
    text: "UK’s blockchain pacesetter 2023 and 2024: A legacy of groundbreaking achievements",
  },
];
