export const mobileFaqs = [
  {
    question:
      "How do you handle project management and communication during the app development process?",
    answer:
      "We use a combination of project management tools and regular progress updates to ensure clear communication and efficient project management throughout the development process. Our clients have access to a dedicated project manager who will be their main point of contact.",
  },
  {
    question:
      "How do you ensure the security as well as the privacy of my app and user data?",
    answer:
      "We follow industry-standard security practices and guidelines. This ensures the security and privacy of your app and user data. This includes implementing encryption, secure data storage, and regular security testing.",
  },
  {
    question: "Can you help with app store optimisation and marketing?",
    answer:
      "Yes, we can help with app store optimisation and marketing. This increases the visibility and the amount of downloads of the app. We can also create a marketing strategy and even create promotional materials.",
  },
  {
    question:
      "Do you have experience in creating apps for different industries?",
    answer:
      "Yes, we have experience in creating apps for various industries such as healthcare, education, e-commerce, and more.",
  },
  {
    question:
      "How do you handle changes and updates to the app after it is launched?",
    answer:
      "We offer ongoing maintenance and support services to ensure the app continues to function properly. Therefore, it can be updated with the latest features and security updates. We can also handle any changes or updates requested by the client.",
  },
  {
    question:
      "Yes, we can help with integrating your existing business systems with the mobile app, such as CRM, ERP, and e-commerce systems.",
    answer:
      "Yes, we can help with integrating your existing business systems with the mobile app, such as CRM, ERP, and e-commerce systems.",
  },
  {
    question:
      "How do you handle intellectual property and confidentiality during the app development process?",
    answer:
      "We sign non-disclosure agreements and take other measures to ensure the confidentiality of your intellectual property and any other sensitive information throughout the development process.",
  },
  {
    question:
      "What is your process for testing and quality assurance of the app?",
    answer:
      "We use a combination of manual and automated testing to ensure the app is functioning correctly while meeting the client’s specifications. This includes functional testing, usability testing, and performance testing.",
  },
];
