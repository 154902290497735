import assets from "../../assets";

export const expertiseAiChangeData = [
  {
    image: assets.aiServices.e_1,
    text: "Financial Services",
  },
  {
    image: assets.aiServices.e_2,
    text: "Food & Beverage",
  },
  {
    image: assets.aiServices.e_3,
    text: "Healthcare & Pharmaceutics",
  },
  {
    image: assets.aiServices.e_4,
    text: "Tourism & Hospitality",
  },
  {
    image: assets.aiServices.e_5,
    text: "Entertainment & Leisure",
  },
  {
    image: assets.aiServices.e_6,
    text: "Media & Telecommunications",
  },
  {
    image: assets.aiServices.e_7,
    text: "Public Sector",
  },
  {
    image: assets.aiServices.e_8,
    text: "Retail",
  },
];
