// import React from "react";
import Components from "../components";
import data from "../data";

const Home = () => {
  return (
    <>
      <Components.UI.HeroSection />
      <Components.Common.OurClients />
      <Components.UI.AboutUsSection />
      <Components.UI.OurServicesSection />
      <Components.UI.YourPlan />
      <Components.UI.CaseStudyHome />
      <Components.UI.SpecialHome />
      <Components.Common.Testimonial />
      <Components.Common.UnlockBusinessPotential
        heading="Unlock Your Business Potential"
        text="Ready to take your business to the next level? Discover how Pixelette Technologies can transform your vision into reality with our innovative tech solutions."
        btnText="Book a Discovery Call"
      />
      <Components.Common.Partners />
      <Components.UI.TeamSectionHome />
      <Components.UI.BlogSection
        heading="The Pixelette Post"
        text="Explore a world of insights through the Pixelette Post. Engage with expert opinions, groundbreaking ideas, and in-depth guides that empower and inspire. Dive into our content today."
      />
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default Home;
