



import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

import Components from "../..";
import assets from "../../../assets";
import data from "../../../data";

const MillstoneList = () => {
  return (
    <div
      className="millstoneList-container"
      style={{
        position: "relative",
        backgroundImage: `url(${assets.commonAssests.serviceSectionBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Components.Feature.Container className="main margins">
        <div className="millstoneList">
          <header>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="300"
              id="h_ani"
            >
              Our Journey: Milestones and Achievements
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="500"
            >
              Explore key moments in Pixelette Technologies' history,
              highlighting our path from inception to becoming a leader in
              technology and innovation:
            </Components.Feature.Text>
          </header>
          <section>
            <header>
              {data.millstoneListLeft.map((el) => (
                <ItemLeft key={el.date} date={el.date} text={el.text} />
              ))}
            </header>
            <div></div>
            <section>
              {data.millstoneListRight.map((el) => (
                <ItemRight key={el.date} date={el.date} text={el.text} />
              ))}
            </section>
          </section>
        </div>
      </Components.Feature.Container>
    </div>
  );
};

export default MillstoneList;

const useBlurEffect = () => {
  const { ref, inView, entry } = useInView({
    threshold: [0, 0.5, 1],
    rootMargin: "-30% 0px -30% 0px",
  });

  const [isBlurred, setIsBlurred] = useState(true);

  useEffect(() => {
    if (entry) {
      if (entry.intersectionRatio >= 0.5) {
        setIsBlurred(false);
      } else {
        setIsBlurred(true);
      }
    }
  }, [entry]);

  return { ref, isBlurred };
};

const ItemLeft = (props) => {
  const { ref, isBlurred } = useBlurEffect();

  return (
    <div
      className={`millstoneList-itemLeft ${isBlurred ? "blur" : ""}`}
      ref={ref}
    >
      <section>
        <div>
          <Components.Feature.Heading className="titory--bold">
            {props.date}
          </Components.Feature.Heading>
          <Components.Feature.Text className="titory--bold">
            {props.text}
          </Components.Feature.Text>
        </div>
        <img src={assets.commonAssests.edeg} alt="icon" />
      </section>
      <header>
        <img src={assets.commonAssests.dot} alt="icon" />
      </header>
    </div>
  );
};

const ItemRight = (props) => {
  const { ref, isBlurred } = useBlurEffect();

  return (
    <div
      className={`millstoneList-itemRight ${isBlurred ? "blur" : ""}`}
      ref={ref}
    >
      <header>
        <img src={assets.commonAssests.dot} alt="icon" />
      </header>
      <section>
        <img src={assets.commonAssests.edegLeft} alt="icon" />
        <div>
          <Components.Feature.Heading className="titory--bold">
            {props.date}
          </Components.Feature.Heading>
          <Components.Feature.Text className="titory--bold">
            {props.text}
          </Components.Feature.Text>
        </div>
      </section>
    </div>
  );
};
