import React from "react";
import { useParams } from "react-router-dom";
import Components from "../components";
import data from "../data";

const CareerDetail = () => {
  let { id } = useParams();
  let el = data.careerData.find((item) => item.id === id * 1);

  return (
    <>
      <Components.UI.HeroSectionCareerDetail
        title={el.title}
        location={el.location}
        postedDate={el.postedDate}
      />
      <Components.UI.CareerDetailWithForm data={el} />
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default CareerDetail;
