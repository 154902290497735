import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
import Components from "..";
import assets from "../../assets";

const FileInput = ({ name }) => {
  const [file, setFile] = useState(null);
  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const preventDefaultBehavior = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUpload = () => {
    console.log("Uploading file:", file);
  };

  return (
    <>
      <div className="inputFile" data-aos="fade-up" data-aos-duration={`600`}>
        <input type="file" id="file-input" onChange={handleFileInputChange} />
        <label htmlFor="file-input">Upload Pitch Deck (Optional)</label>
        <section onDrop={handleFileDrop} onDragOver={preventDefaultBehavior}>
          <section>
            <img src={assets.commonAssests.fileUpload} alt="icon" />
            <Components.Feature.Text className="titory">
              You can drag or <span onClick={handleUpload}>upload a file</span>
            </Components.Feature.Text>
          </section>
          <blockquote></blockquote>
        </section>
        <header></header>

        {/* {file && (
          <div>
            <p>File Selected: {file.name}</p>
            <button onClick={handleUpload}>Upload</button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default FileInput;
