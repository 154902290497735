import React from "react";
import Components from "..";
import assets from "../../assets";

const SelectPlan = () => {
  return (
    <div className="SelectPlan">
      <Components.Feature.Container className="main">
        <center data-aos-duration={`700`} data-aos="fade-up">
          <Components.Feature.Heading className="primary " id="h_ani">
          Select a Plan That Aligns Best with <br /> Your Project Goals
          </Components.Feature.Heading>
        </center>
        <div data-aos-duration={`500`} data-aos="fade-up">
          <Table />
        </div>
      </Components.Feature.Container>
    </div>
  );
};

const Table = () => {

  return (
    <>
    <div className="PlanSelect">
        <table>
            <thead>
            <tr>
                <th rowSpan={2} colSpan={2}></th>
                <th>Complete Outsourcing</th>
                <th>Staff Augmentation</th>
                <th>Dedicated Teams</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Scale your team when you need it</td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Get top talent in your area</td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Bridge specific skill gaps</td>
                <td></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td></td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Take charge of your team</td>
                <td></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Work alongside a full team</td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Ease your team's workload</td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: 'left'}}>Succeed in your project</td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
                <td><img src={assets.home.PlanIcon} alt="" /></td>
            </tr>
            <tr>
                <td colSpan={2}></td>
                <td><Components.Feature.Button className="primary--light">Schedule a call</Components.Feature.Button></td>
                <td><Components.Feature.Button className="primary--light">Schedule a call</Components.Feature.Button></td>
                <td><Components.Feature.Button className="primary--light">Schedule a call</Components.Feature.Button></td>
            </tr>
            </tbody>
        </table>
    </div>
    </>
  );
};

export default SelectPlan;
