import React from "react";
import Components from "..";
import assets from "../../assets";

const CustomerCard = (props) => {
  return (
    <>
      <div className="customerCard">
        <img src={props.image} alt="profile" />

        <div>
          <img src={assets.home.stars} alt="stars" />
        </div>

        <Components.Feature.Text className="titory">
          “ {props.review} ”
          <br />
          {props.name}
        </Components.Feature.Text>
      </div>
    </>
  );
};

export default CustomerCard;
