import React, { useRef } from "react";
import Slider from "react-slick";
import Components from "..";
import { Link } from "react-router-dom";
import assets from "../../assets";
import { v4 as uuidv4 } from "uuid";

const CaseSlider = (props) => {
  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  function nextButtonHandler() {
    slider?.current?.slickNext();
  }

  function previousButtonHandler() {
    slider?.current?.slickPrev();
  }
 
  return (
    <>
      <div className="caseSlider">
        <section data-aos="fade-up" data-aos-duration="600">
          <Slider ref={slider} {...settings}>
            {props.data.map((el) => (
              <Card
                image={el.fields?.image?.fields?.file?.url}
                blockChainLink={el.fields?.tags}
                // uiDesignLink={el.uiDesignLink}
                // developmentLink={el.developmentLink}
                id={el.sys?.id}
                name={el.fields?.shortDescription}
                description={el.fields?.longDescription}
                key={el.sys?.id}
              />
              
            ))}
          </Slider>
        </section>

        <div>
          <img
            src={assets.home.arrowLeftSlider}
            alt="icon"
            onClick={previousButtonHandler}
            data-aos-duration="600"
            data-aos="fade-right"
          />
          <img
            src={assets.home.arrowRightSlider}
            alt="icon"
            onClick={nextButtonHandler}
            data-aos="fade-left"
            data-aos-duration="600"
          />
        </div>
      </div>
    </>
  );
};

export default CaseSlider;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <img src={arrowLeftSlider} alt="arrow" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      <img src={arrowRightSlider} alt="arrow" />
    </div>
  );
}

const Card = (props) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const truncatedName = truncateText(props.name, 50); // Adjust the maxLength as needed
  const truncatedDescription = truncateText(props.description, 100);
  
const link = "/case-study/" + props.id;
const category = props.blockChainLink;
  return (
    <Link to={link} >
    <div className="caseSlider-card">       
      <div>
        <img src={props.image} alt="slider hero" />
      </div>
      <div>
        <div>
          <Link to= {link} >
          <Components.Feature.Button className="primary">
          {category}
          </Components.Feature.Button>
          </Link>
          {/* <Components.Feature.Button className="primary">
            UI UX Design
          </Components.Feature.Button>
          <Components.Feature.Button className="primary">
            Development
          </Components.Feature.Button> */}
        </div>
        {/* <h1>{props.name}</h1> */}
        <h1>{truncatedName}</h1>

        <Components.Feature.Text className="titory">
          {/* {props.description} */}
          {truncatedDescription}
        </Components.Feature.Text>
        {/* <button>Read More</button> */}
      </div>
    </div>
    </Link>
  );
};
