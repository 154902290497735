import React from "react";
import Components from "../..";
import assets from "../../../assets";
import {Link} from "react-scroll";

const HeroSectionUI = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-UI-background">
          <img src={assets.ui.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-UI">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.ui.box_1} alt="box" />
            <img src={assets.ui.box_3} alt="box" />
            <img src={assets.ui.box_2} alt="box" />
            <img src={assets.ui.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`500`}
            >
              Interface Design Services That Speak to Your Audience
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="600"
            >
              Unlock the full potential of your digital interfaces with our
              bespoke UI  and UX design services. Tailored to resonate with your
              specific audience, our designs ensure that every interaction is
              intuitive, engaging, and effective. Let us help you craft an
              experience that not only looks great but feels right to your
              users.
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="700"
            >
              Discuss Your Vision
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionUI;
