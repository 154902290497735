import React, { useState } from "react";
import Components from "../..";
import assets from "../../../assets";
import data from "../../../data";
import { v4 as uuidv4 } from "uuid";

const UIProcess = () => {

  const [openAccordion, setOpenAccordion] = useState(null);

  return (
    <>
      <Components.Feature.Container className="main">
        <div className="ui-process-background">
          <img src={assets.home.testimonialBackground} alt="testimonialCard" />
        </div>
      </Components.Feature.Container>
      <Components.Feature.Container className="main margins">
        <div className="ui-process">
          <center>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="300"
              id="h_ani"
            >
              Our UI/UX Design Process
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="500"
            >
              Our approach to UI/UX design is focused on creating solutions that
              not only look great but also function seamlessly, addressing your
              specific business challenges and enhancing user engagement. Here's
              how we do it:
            </Components.Feature.Text>
          </center>
          <section>
            <div>
              {data.uiProcessData.map((el, index) => (
                <Components.Feature.Accordian
                  key={uuidv4()}
                  question={el.question}
                  answer={el.answer}
                  isOpen={openAccordion === index}
                onClick={() => setOpenAccordion(openAccordion === index ? null : index)}
                />
              ))}
            </div>
            <img src={assets.ui.processImage} alt="banner" />
          </section>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default UIProcess;
