import React from "react";
import assets from "../../../assets";
import Components from "../..";

const HeroSectionMarketing = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSectionMarketing-background">
          <img
            src={assets.marketing.heroSectionBackground}
            alt="researchbackground"
          />
        </div>
      </Components.Feature.Container>
      <div className="heroSectionMarketing">
        <center>
          <Components.Feature.Heading
            className="heroHeading"
            animation="zoom-out"
            duration={`500`}
          >
            Ready to scale? Partner with Pixelette Technologies
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="zoom-in"
            duration="600"
          >
            At Pixelette Technologies, we're passionate about startups and
            innovation, committed to turning your ideas into reality. With our
            expert support, we guide you from ideation to execution, ensuring
            your project receives the attention and dedication it deserves.
          </Components.Feature.Text>
        </center>

        <Components.Feature.Container className="main margins">
          <section data-aos="fade-up" data-aos-duration={`500`}>
            <div>
              <Components.Feature.Heading className="policyHeading">
                Our commitment to innovation
              </Components.Feature.Heading>
              <Components.Feature.Text className="titory--bold">
                Pixelette Technologies recognizes the scarcity of innovative
                thinkers and the challenges they face in accessing funding. We
                advocate for the process of thesis, antithesis, and synthesis,
                believing it leads to meaningful innovation. With a focus on
                early-stage startups, we invest our resources and expertise to
                support ideas from conception to execution, ensuring promising
                ventures see the light of day. Join us to navigate the hurdles
                hindering your startup's success and turn your vision into
                reality.
              </Components.Feature.Text>
            </div>
            <div>
              <Components.Feature.Heading className="policyHeading">
                Kickstart your journey
              </Components.Feature.Heading>
              <Components.Feature.Text className="titory--bold">
                Pixelette Technologies offers start-ups access to a seasoned
                team of experts deeply ingrained in the start-up ecosystem,
                dedicated to facilitating success. Choose us for funded
                opportunities, a robust partner network, top-notch development
                and marketing services, all at heavily subsidised fees. With
                minimal control relinquished, we prioritise your start-up's
                growth and prosperity, ensuring unparalleled support on your
                journey to success.
              </Components.Feature.Text>
            </div>
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionMarketing;
