import React from "react";
import Components from "../components";
import data from "../data";

const ArAndVr = () => {
  return (
    <>
      <Components.UI.HeroSectionArandVr />
      <Components.Common.OurClients />
      <Components.UI.ServicesArAndVr />
      {/* <Components.UI.ArProcess /> */}
      <Components.UI.TechnologyStackArandVr />
      <Components.Common.WhatMakesUsSpecial data={data.arAndVrSpecialData} />
      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.UnlockBusinessPotential
        heading="Discover the Potential of AR and VR"
        text="Ready to elevate your reality? Schedule your free consultation with our AR and VR specialists today and step into a world of enhanced possibilities."
        btnText="Let's Explore!"
      />
      <Components.Common.CaseStudies
        heading="Success in Sight: Our AR and VR Case Studies"
        text="Discover how our AR and VR solutions have enhanced user engagement, improved training outcomes, and created memorable experiences biacross various industries."
      />
      <Components.Common.ExpertiseGrid
        data={data.expertiseArData}
        heading="Expertise Across Industries: Tailored AR and VR Solutions"
        text="Spanning sectors from retail to healthcare, our AR and VR proficiencies are redefining how businesses engage with their environments and customers."
      />
      <Components.Common.Partners />

      {/* <Components.UI.ExpertiseArAndVr /> */}
      {/* <Components.Common.AchievementsSection /> */}
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection data={data.arFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default ArAndVr;
