const responsibilities = [
  "Basic knowledge of liquid programming language",
  "Basic knowledge of developing Shopify apps",
  "Applying best practices and standard operating procedures.",
  "Creating innovative solutions to meet our company’s technical needs.",
  "Making documents when required.",
  "Make sure the 100% bug-free product before deployment.",
  "Clear concept of HTML5, CSS3, javascript/Jquery.",
];
const skillsRequired = [
  "BS/MS degree in Computer Science, Software Engineering, or a related subject.",
  "Fresh to 1 year of experience is required.",
  "Excellent written and verbal communication skills.",
  "Highly self-motivated and proven Analytical and Problem-Solving skills.",
  "Ability to work both independently and in a collaborative environment.",
  "Basic knowledge of Shopify and Shopify Plus platforms",
  "Basic knowledge of private and public Shopify Apps",
  "Basic knowledge in front-end technologies including, but not limited to, JavaScript, AJAX, HTML, CSS, SASS",
  "Superb troubleshooting & debugging skills",
  "A disciplined approach to testing and quality assurance",
];
const description =
  "Pixelette Technologies is hiring a full-time Shopify Web Developer/Designer. The ideal candidate must have basic knowledge of Shopify development, including building custom themes and features. You will be working alongside other engineers and developers working on different layers of the infrastructure. Therefore, a commitment to collaborative problem-solving, sophisticated design, and the creation of quality productsare essential.";
const detail =
  "Lorem ipsum dolor sit amet consectetur. Tempus sagittis porttitor euismod sed nibh sagittis blandit volutpat nunc. Nisl cras purus nec vitae scelerisque.";
const postedDate = "31-03-2024";
const location = "Lahore, Pakistan";

export const careerData = [
  {
    id: 1,
    title: "Internee",
    detail: "Are you passionate about learning and gaining practical experience? Our internship program provides a platform for you to enhance your skills and contribute to real projects. Join us as an intern and kickstart your career in the digital industry.",
    description,
    responsibilities,
    skillsRequired,
    postedDate,
    location,
  },
  {
    id: 2,
    title: "Web Developer",
    detail: "Join our team of talented web developers and contribute to building innovative and user-friendly websites. From frontend to backend development, you will have the opportunity to work with cutting-edge technologies and turn ideas into reality.",
    description,
    responsibilities,
    skillsRequired,
    postedDate,
    location,
  },
  {
    id: 3,
    title: "UI / UX Designer",
    detail: "Are you a creative genius with an eye for aesthetics and user experience? As a UI/UX designer at Pixelette Technologies, you will create visually stunning designs that captivate users and enhance their interaction with digital products.",
    description,
    responsibilities,
    skillsRequired,
    postedDate,
    location,
  },
  {
    id: 4,
    title: "Graphic Designer",
    detail: "Bring visuals to life and leave a lasting impact through your designs. As a graphic designer at Pixelette Technologies, you will collaborate with cross-functional teams to create captivating visuals for various marketing and branding initiatives.",
    description,
    responsibilities,
    skillsRequired,
    postedDate,
    location,
  },
  {
    id: 5,
    title: "Web Content Writer",
    detail: "Do you have a way with words? Join our team of skilled writers and contribute to crafting engaging and informative content for websites, blogs and social media platforms. Your words will inspire and engage our audience to drive organic traffic and boost brand visibility.",
    description,
    responsibilities,
    skillsRequired,
    postedDate,
    location,
  },
  {
    id: 6,
    title: "WordPress / HTML / CSS",
    detail: "Showcase your technical expertise as a WordPress, HTML, or CSS developer at Pixelette Technologies. You will have the opportunity to work with industry-leading tools and frameworks to create robust and visually appealing websites.",
    description,
    responsibilities,
    skillsRequired,
    postedDate,
    location,
  },
];
