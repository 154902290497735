import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesMobile = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Comprehensive Mobile App Development Services"
        text="Unlock the full potential of your business with our end-to-end mobile app development services. We deliver tailored solutions that drive engagement, enhance functionality, and boost your app's market performance."
        data={data.mobileServicesData}
      />
    </>
  );
};

export default ServicesMobile;
