import React from "react";
import Components from "..";

const IntegrationItem = (props) => {
  return (
    <div
      className="integrationItem"
      data-aos="fade-up"
      data-aos-duration={`500`}
    >
      <img src={props.icon} alt="icon" />
      <Components.Feature.Text className="primary">
        {props.name}
      </Components.Feature.Text>
    </div>
  );
};

export default IntegrationItem;
