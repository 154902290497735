import assets from "../../assets";

export const transformtechData = [
  {
    icon: assets.aboutUs.scope,
    name: "Scope",
    description:
      "We'll guide you through our comprehensive process to define your platform's blueprint and ensure it delivers optimal value to your customers.",
  },
  {
    icon: assets.aboutUs.build,
    name: "Build",
    description:
      "Our skilled development team will build your app based on the blueprint, ensuring it meets your specifications and is ready for launch.",
  },
  {
    icon: assets.aboutUs.launch,
    name: "Launch ",
    description:
      "We’ll launch your app, gather early user feedback, and help you make necessary improvements to ensure market success.",
  },
];
