import assets from "../../assets";

export const arAndVRServicesData = [
  {
    icon: assets.arAndVr.s_1,
    name: "VR and AR Game Development",
    description:
      "Engage users like never before with our VR and AR game development, creating captivating worlds that offer both entertainment and educational value, driving deeper user engagement and retention.",
  },
  {
    icon: assets.arAndVr.s_2,
    name: "VR and AR Application Development",
    description:
      "Transform training and demonstrations with our VR and AR applications, designed to enhance learning efficiency and provide immersive, interactive experiences that improve understanding and recall.",
  },
  {
    icon: assets.arAndVr.s_3,
    name: "VR Simulation Development",
    description:
      "Enhance professional training and research with our VR simulations, offering realistic, controlled environments that accelerate learning and improve decision-making skills.",
  },
  {
    icon: assets.arAndVr.s_4,
    name: "VR and AR Content Creation",
    description:
      "Boost your marketing and advertising impact with our VR and AR content creation, producing visually stunning materials that capture attention and create memorable brand experiences.",
  },
  {
    icon: assets.arAndVr.s_5,
    name: "VR and AR Consulting",
    description:
      "Maximise the potential of your AR and VR projects with our expert consulting, ensuring you're using the best technologies and practices for cutting-edge results.",
  },
  {
    icon: assets.arAndVr.s_6,
    name: "VR and AR Maintenance and Support",
    description:
      "Keep your VR and AR systems running flawlessly with our dedicated support, reducing downtime and ensuring consistent quality in user experience.",
  },
];
