import Components from "../components";
import assets from "../assets";
// import CommonComp from "../components/CommonComp";

const AboutUs = () => {
  return (
    <>
      <Components.UI.HeroSectionAbout />
      <Components.UI.HowWeWork />
      <Components.UI.MillstoneList />

      {/* <Components.Common.CaseStudies /> */}
      {/* <Components.UI.OriginStory /> */}
      {/* <CommonComp /> */}
      {/* <Components.UI.OurTeamAbout /> */}

      <Components.UI.BannerSection />
      <Components.Common.OurAboutTeamSection />

      <Components.Common.Map />
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default AboutUs;
