import React from "react";
import Components from "../components";
import data from "../data";

const Mobile = () => {
  return (
    <>
      <Components.UI.HeroSectionMobile />
      <Components.Common.OurClients />
      <Components.UI.ServicesMobile />
      {/* <Components.UI.IntegrationMobile /> */}
      {/* <Components.UI.MobileProcess /> */}
      <Components.UI.TechnologyStackMobile />
      <Components.Common.WhatMakesUsSpecial data={data.mobileSpecialData} />
      <Components.Common.UnlockBusinessPotential
        heading="Elevate Your Mobile Experience"
        text="Transform your ideas into reality with our cutting-edge mobile app development services. Whether you're looking to boost user engagement, streamline operations, or innovate within your market, our expert team is ready to help you lead the way. Connect with us today to see how we can turn your vision into a high-performing mobile app."
        btnText="Start Your Project"
      />
      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.CaseStudies
        heading="Success in Action: Our Mobile App Case Studies"
        text="Explore our mobile app Success Stories: real-world examples of how our mobile solutions have driven innovation and growth for our clients. Discover how we've transformed ideas into powerful apps that excel in user engagement, functionality, and market penetration."
      />
      {/* <Components.UI.ExpertiseMobile /> */}
      {/* <Components.Common.AchievementsSection /> */}
      <Components.Common.Partners />
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection data={data.mobileFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default Mobile;
