import Components from "../components";
// import { Testimonial } from "../components/common";
import AwardWinning from "../components/common/AwardWinning";
import BottomForm from "../components/common/BottomForm";
import ClutchForm from "../components/common/ClutchForm";
import TrustedClients from "../components/common/TrustedClients";
import BrilliantIdea from "../components/common/BrilliantIdea";
import TrasformYourTech from "../components/ui/TransformYourTech/TranformYourTech";
import data from "../data";
import StartupStats from "../components/common/StartupStats";
import NoDeveloperReq from "../components/common/NoDeveloperReq";
import ClutchTestimonial from "../components/common/ClutchTestimonial";
import { Link } from "react-router-dom";
import assets from "../assets";
function Clutch() {
  return (
    <div className="clutch-container">
      <div className="specialSection-background">
        <img src={assets.commonAssests.clutchBackground} alt="background" />
      </div>
      <div className="heroSectionAiServices-background">
        {/* <img src={assets.aiServices.heroSectionBackground} alt="background" /> */}
        <div className="pixelate-logo">
          <Link to={"/"}>
            <img src={assets.commonAssests.logo} alt="" />
          </Link>
        </div>
        <ClutchForm />
        <TrustedClients />
        <StartupStats />
        <BrilliantIdea />
        <AwardWinning />
        <NoDeveloperReq
          heading={<>100+ clients never had to hire tech staff</>}
          text={
            <>
              We handle all the development for you. All you need to do is share
              your vision, and we turn it into your platform&apos;s Minimum
              Viable Product. You don&apos;t have to waste months and thousands
              of dollars assembling a team of in-house developers. Focus on what
              you do best while we figure out the technical stuff.
            </>
          }
          btnText="Book a discovery call"
          text2={
            <>
              
            </>
          }
        />

        <ClutchTestimonial />
        <Components.Common.FaqsSection data={data.clFaqs} />
        {/* <TrasformYourTech /> */}
        <br />
        <br />
        <BottomForm />
      </div>
    </div>
  );
}

export default Clutch;
