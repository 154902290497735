// import React from "react";
// import Components from "..";
// import data from "../../data";
// import { v4 as uuidv4 } from "uuid";

// const FaqsSection = (props) => {
//   const splitIndex = Math.ceil(
//     props.data ? props.data.length / 2 : data.deployFaqs.length / 2
//   );
//   const faqsLeft = props.data
//     ? props.data.slice(0, splitIndex)
//     : data.deployFaqs.slice(0, splitIndex);
//   const faqsRight = props.data
//     ? props.data.slice(splitIndex)
//     : data.deployFaqs.slice(splitIndex);

//   return (
//     <div className="faqsSection">
//       <center>
//         <Components.Feature.Heading
//           className="secondry"
//           animation="fade-up"
//           duration="500"
//           id="h_ani"
//         >
//           {props.heading ? props.heading : "Frequently Asked Questions"}
//         </Components.Feature.Heading>
//         <Components.Feature.Text
//           className="secondry"
//           animation="fade-up"
//           duration="600"
//         >
//           {props.text
//             ? props.text
//             : "Ask everything you need to know about our products and services."}
//         </Components.Feature.Text>
//       </center>
//       <Components.Feature.Container className="main margins">
//         <section>
//           <div>
//             {faqsLeft.map((el) => (
//               <Components.Feature.Accordian
//                 key={uuidv4()}
//                 question={el.question}
//                 answer={el.answer}
//               />
//             ))}
//           </div>
//           <div>
//             {faqsRight.map((el) => (
//               <Components.Feature.Accordian
//                 key={uuidv4()}
//                 question={el.question}
//                 answer={el.answer}
//               />
//             ))}
//           </div>
//         </section>
//       </Components.Feature.Container>
//     </div>
//   );
// };

// export default FaqsSection;

import { useState } from "react";
import Components from "..";
import data from "../../data";
import { v4 as uuidv4 } from "uuid";

const DeployFaqs = (props) => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const splitIndex = Math.ceil(
    props.data ? props.data.length / 2 : data.deployFaqs.length / 2
  );
  const faqsLeft = props.data
    ? props.data.slice(0, splitIndex)
    : data.deployFaqs.slice(0, splitIndex);
  const faqsRight = props.data
    ? props.data.slice(splitIndex)
    : data.deployFaqs.slice(splitIndex);

  return (
    <div className="faqsSection">
      <center>
        <Components.Feature.Heading
          className="secondry"
          animation="fade-up"
          duration="500"
          id="h_ani"
        >
          {props.heading ? (
            props.heading
          ) : (
            <>
              Have questions? We've got answers!
              <br /> Here are the most frequent ones
            </>
          )}
        </Components.Feature.Heading>
        <Components.Feature.Text
          className="secondry"
          animation="fade-up"
          duration="600"
        >
          {/* {props.text
            ? props.text
            : "Ask everything you need to know about our products and services."} */}
        </Components.Feature.Text>
      </center>
      <Components.Feature.Container className="main margins">
        <section>
          <div>
            {faqsLeft.map((el, index) => (
              <Components.Feature.Accordian
                key={uuidv4()}
                question={el.question}
                answer={el.answer}
                isOpen={openAccordion === index}
                onClick={() =>
                  setOpenAccordion(openAccordion === index ? null : index)
                }
              />
            ))}
          </div>
          <div>
            {faqsRight.map((el, index) => (
              <Components.Feature.Accordian
                key={uuidv4()}
                question={el.question}
                answer={el.answer}
                isOpen={openAccordion === splitIndex + index}
                onClick={() =>
                  setOpenAccordion(
                    openAccordion === splitIndex + index
                      ? null
                      : splitIndex + index
                  )
                }
              />
            ))}
          </div>
        </section>
      </Components.Feature.Container>
    </div>
  );
};

export default DeployFaqs;
