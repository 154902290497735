import React, { useRef, useState, useEffect } from "react";
import Components from "../..";
import data from "../../../data";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";
import assets from "../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { createClient } from "contentful";

const BlogSection = (props) => {




  const [blogdata, setBlogData] = useState([]);

  useEffect(() => {
    const client = createClient({
      space: "ggtsbq0gqfii",
      accessToken: "VZvVye8dMIc497wF-1pNt5rdYUG-h4E30uX58AcGVUo",
    });

    client
      .getEntries({content_type: "blogs"})
      .then((response) => {
        console.log("response: ", response);
        setBlogData(response.items);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);







  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  function nextButtonHandler() {
    slider?.current?.slickNext();
  }

  function previousButtonHandler() {
    slider?.current?.slickPrev();
  }

  return (
    <>
      <div className="blogSection">
        <section>
          <Components.Feature.Container className="main">
            <blockquote>
              <img src={assets.home.box_28} alt="box" />
              <img src={assets.home.box_29} alt="box" />
            </blockquote>
            <center>
              <Components.Feature.Heading
                className="secondry"
                animation="fade-up"
                duration="600"
                id="h_ani"
              >
                {props.heading ? props.heading : "The Pixelette Post"}
              </Components.Feature.Heading>

              <Components.Feature.Text
                className="secondry"
                animation="fade-up"
                duration="700"
              >
                {props.text
                  ? props.text
                  : " Dive into our curated collection of updates and guides to deepen your understanding of diverse technologies."}
              </Components.Feature.Text>
              <Link to="blogs">
                <Components.Feature.Button
                  className="primary"
                  animation="fade-up"
                  duration="400"
                  id="h_ani"
                >
                  Explore Blogs
                  <FiExternalLink />
                </Components.Feature.Button>
              </Link>
            </center>
          </Components.Feature.Container>
        </section>

        <div>
          <Components.Common.BlogGridWithBanner data={blogdata} />
        </div>
      </div>
      <div className="blogSection-mobile">
        <section>
          <div>
            <Slider ref={slider} {...settings}>
              {blogdata.map((el) => (
                <Components.Feature.BlogCard
                  image={el.fields.images?.fields?.file?.url || "default-image-url"}
                  date={el.fields.date || "No date available"}
                  heading={el.fields.name || "No title available"}
                  description={el.fields.shortDescription || "No description available"}
                  to={el.sys?.id || mainBlogId}
                  key={el.sys?.id}
                />
              ))}
            </Slider>
          </div>
        </section>
        <div>
          <img
            src={assets.home.arrowLeftSlider}
            alt="icon"
            data-aos-duration="500"
            data-aos="fade-right"
            onClick={previousButtonHandler}
          />
          <img
            src={assets.home.arrowRightSlider}
            alt="icon"
            onClick={nextButtonHandler}
            data-aos="fade-left"
            data-aos-duration="500"
          />
        </div>
      </div>
    </>
  );
};

export default BlogSection;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <img src={arrowLeftSlider} alt="arrow" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      <img src={arrowRightSlider} alt="arrow" />
    </div>
  );
}
