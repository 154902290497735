export const arAndVrSpecialData = [
  {
    heading: "Expert Development Team",
    text: "Our team comprises over 200 experts, including top AR and VR developers globally recognised for their pioneering contributions to immersive technology.",
  },
  {
    heading: "Global Projects",
    text: "With over 200 immersive technology projects and 30,000+ development hours, we've established a significant footprint across 13 countries, delivering bespoke AR and VR experiences.",
  },
  {
    heading: "Proven Success",
    text: "Our AR and VR projects have successfully transformed operations in various sectors, including entertainment, education, and retail, proving our capability to deliver practical and innovative solutions.",
  },
  {
    heading: "Cost-Effective Immersive Solutions",
    text: "We provide high-value solutions that maximise ROI without the premium cost, ensuring affordable access to cutting-edge technology.    ",
  },
  {
    heading: "Unmatched Achievements",
    text: "Recognised as leaders in AR and VR development, our projects have set benchmarks for innovation and effectiveness in the field. ",
  },
];
