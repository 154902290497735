import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesDigital = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Digital Marketing Services"
        text="Our web development services offer numerous convenient features to enhance user experience and engagement. "
        data={data.digitalServicesData}
      />
    </>
  );
};

export default ServicesDigital;
