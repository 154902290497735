import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesWebDevelopment = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Explore Our Web Development Services"
        text="Discover the variety of web development services we offer at Pixelette Technologies. Each service is designed to meet unique business needs, ensuring your website not only looks great but functions seamlessly. Below is an overview of our key services tailored to elevate your online presence: "
        data={data.webDevelopmentServicesData}
      />
    </>
  );
};

export default ServicesWebDevelopment;
