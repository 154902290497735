import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";
import assets from "../../assets";
import Components from "..";
import { FaCheck } from "react-icons/fa";

const ExpertiseSection = (props) => {
  const slider = useRef(null);
  const [selectedData, setSelectedData] = useState(props.data[0]);
  const [active, setActive] = useState(props.data[0].name);
  const [showPrevious, setShowPrevious] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  function nextButtonHandler() {
    slider?.current?.slickNext();
    setShowPrevious(true);
  }

  function previousButtonHandler() {
    slider?.current?.slickPrev();
  }
  const handleActiveBtn = (element) => {
    setSelectedData(element);
    setActive(element.name);
  };

  return (
    <div className="expertiseSection">
      <center>
        <Components.Feature.Heading
          className="secondry"
          animation="fade-up"
          duration="500"
          id="h_ani"
        >
          {props.heading}
        </Components.Feature.Heading>
        <Components.Feature.Text
          className="secondry"
          animation="fade-up"
          duration="600"
        >
          {props.text}
        </Components.Feature.Text>
      </center>
      <Components.Feature.Container className="main margins">
        <section>
          <div data-aos="fade-up" data-aos-duration={`700`}>
            {showPrevious && (
              <img
                src={assets.home.arrowLeftSlider}
                alt="icon"
                onClick={previousButtonHandler}
              />
            )}
            <div>
              <Slider ref={slider} {...settings}>
                {props.data.map((el) => (
                  <button
                    id={active == el.name ? "toggleActive" : "toggleInActive"}
                    key={uuidv4()}
                    onClick={() => handleActiveBtn(el)}
                    style={{ width: "90rem" }}
                  >
                    {el.name}
                  </button>
                ))}
              </Slider>
            </div>
            <img
              src={assets.home.arrowRightSlider}
              alt="icon"
              onClick={nextButtonHandler}
            />
          </div>
          <section>
            <img
              src={selectedData.image}
              alt="heroImage"
              data-aos="fade-up"
              data-aos-duration={`600`}
            />
            <div>
              <Components.Feature.Heading
                className="titory"
                animation="fade-up"
                duration="700"
              >
                {selectedData.name}
              </Components.Feature.Heading>
              <Components.Feature.Text
                className="titory--bold"
                animation="fade-up"
                duration="700"
              >
                {selectedData.description}
              </Components.Feature.Text>

              <section>
                <div>
                  {selectedData.checkPoints.slice(0, 5).map((el) => (
                    <div
                      key={uuidv4()}
                      className="checkPoints"
                      data-aos="fade-up"
                      data-aos-duration={`700`}
                    >
                      <div>
                        <FaCheck />
                      </div>
                      <div>
                        <Components.Feature.Text className="titory">
                          {el}
                        </Components.Feature.Text>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  {selectedData.checkPoints.slice(5).map((el) => (
                    <div
                      key={uuidv4()}
                      className="checkPoints"
                      data-aos="fade-up"
                      data-aos-duration={`700`}
                    >
                      <div>
                        <FaCheck />
                      </div>
                      <div>
                        <Components.Feature.Text className="titory">
                          {el}
                        </Components.Feature.Text>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </section>
        </section>
      </Components.Feature.Container>
    </div>
  );
};

export default ExpertiseSection;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <img src={arrowLeftSlider} alt="arrow" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      <img src={arrowRightSlider} alt="arrow" />
    </div>
  );
}
