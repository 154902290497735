import React from "react";

import Components from "../..";
import data from "../../../data";
const TechnologyStackWebDevelopment = () => {
  return (
    <>
      <Components.Common.TechnologyGrid
        heading="Empowered by Leading Technologies: Our Web Development Stack"
        text="Develop powerful web applications tailored to streamline your business processes. Our developers create solutions that are robust, scalable, and capable of handling complex functionalities."
        data={data.technologyStackWebDevelopmentData}
      />
    </>
  );
};

export default TechnologyStackWebDevelopment;
