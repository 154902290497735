import React from "react";
import Components from "../..";
import assets from "../../../assets";
import {Link} from "react-scroll";

const HeroSectionArAndVr = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-ar-background">
          <img src={assets.arAndVr.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-ar">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.arAndVr.box_1} alt="box" />
            <img src={assets.arAndVr.box_3} alt="box" />
            <img src={assets.arAndVr.box_2} alt="box" />
            <img src={assets.arAndVr.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
       Redefine Reality with Pixelette Technologies
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
        Step beyond reality with Pixelette Technologies. We develop immersive virtual and augmented reality experiences designed to exceed your expectations and spark innovation.
         Ready to explore new dimensions?
         
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="2800"
            >
Start Your Virtual Journey
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionArAndVr;
