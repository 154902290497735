import React from "react";
import assets from "../../../assets";
import Components from "../..";

import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { HiLocationMarker } from "react-icons/hi";

import { Formik, Form } from "formik";

import emailjs from 'emailjs-com';

const HeroSectionContactUs = () => {
  const initalData = {
    fullName:"",
    email: "",
    company:"",
    jobTitle:"",
    phone: "",
    industry:"",
    projectType: "",
    budget: "",
    refValue: "",
    message: "",
  };
  const projectType = ["BlockChain Development","Software Development" ,"AI Solutions", "Mobile Application Development", "Digital Marketing"];
  const refValues = ["Search Engine","Clutch", "Social Media", "Referral", "Others"];

  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSectionContactUs-background">
          <img
            src={assets.contactUs.heroSectionBackground}
            alt="researchbackground"
          />
        </div>
      </Components.Feature.Container>
      <Components.Feature.Container className="main margins">
        <div className="heroSectionContactUs">
          <header>
            <Components.Feature.Text className="titory">
              Let's Talk
            </Components.Feature.Text>
            <Components.Feature.Heading className="heroHeading">
              Your Goals, Our Mission
            </Components.Feature.Heading>
            <Components.Feature.Text className="secondry">
              Have a project in mind? Connect directly with our team to see how
              we can turn your vision into reality. Reach out today and take the
              first step towards transforming your business with tailored
              solutions.
            </Components.Feature.Text>

            <div>
              <section>
                <div>
                  <MdEmail />
                </div>
                <Components.Feature.Text
                  // duration={`800`}
                  // animation="fade-up"
                  className="titory--bold"
                >
                  sales@pixelettetech.com
                </Components.Feature.Text>
              </section>
              <section>
                <div>
                  <FaPhone />
                </div>
                <Components.Feature.Text
                  // duration={`800`}
                  // animation="fade-up"
                  className="titory--bold"
                >
                  +44 2045188226
                </Components.Feature.Text>
              </section>
              <section>
                <div>
                  <FaPhone />
                </div>
                <Components.Feature.Text
                  // duration={`800`}
                  // animation="fade-up"
                  className="titory--bold"
                >
                  +1 7732709034
                </Components.Feature.Text>
              </section>
              <section>
                <div>
                  <HiLocationMarker />
                </div>
                <Components.Feature.Text
                  // duration={`800`}
                  // animation="fade-up"
                  className="titory--bold"
                >
                  71-75 Shelton Street, WC2 H9J, London, UK (Headquarter)
                </Components.Feature.Text>
              </section>
              <section>
                <div>
                  <HiLocationMarker />
                </div>
                <Components.Feature.Text
                  // duration={`800`}
                  // animation="fade-up"
                  className="titory--bold"
                >
                  2335. W. Devon, Ave, IL 6065, Chicago, USA (Headquarter)
                </Components.Feature.Text>
              </section>
            </div>
          </header>
          <section>
            <div>
              <Components.Feature.Heading className="primary">
                Start Your Journey
              </Components.Feature.Heading>

              <Formik
                initialValues={initalData}
                validateOnMount
                // validationSchema={data.validationJobApply}
                onSubmit={(values, { resetForm }) => {
                 
    const templateParams = {
      fullName: values.fullName,
      email: values.email,
      company:values.companyName,
      jobTitle:values.jobTitle,
      phone: `+${values.phone}`,
      industry: values.industry,
      projectType: values.projectType,
      budget: values.budget,
      refValue: values.refValue,
      message: values.message,
    };

    emailjs.send('service_5y0itcg', 'template_3j94qlf', templateParams, 'LYS_0H8byHSkeaSrz')
      .then(response => {
        console.log(response);
       // setStatusMessage('Email sent successfully!');
      })
      .catch(error => {
       // setStatusMessage('Failed to send email.');
       console.log(error);
      });

                  console.log(values);
                
                  resetForm();
                }}
              >
                {(formik) => (
                  <Form>
                    <blockquote className="heroSectionContactUs-flex">
                      <Components.Feature.FormInput
                        name="fullName"
                        label="Full Name"
                        place="Enter Name"
                      />
                      <Components.Feature.FormInput
                        name="email"
                        label="Email"
                        place="Enter Email"
                      />
                    </blockquote>
                    <Components.Feature.InputPhoneNo
                      name="phone"
                      label="Phone Number"
                      place="+1 (555) 000-0000"
                    />
                    <blockquote className="heroSectionContactUs-flex">
                      <Components.Feature.FormInput
                        name="companyName"
                        label="Company Name"
                        place="Organisation Name"
                      />
                      <Components.Feature.FormInput
                        name="jobTitle"
                        label="Job Title"
                        place="Enter Job title"
                      />
                    </blockquote>
                    <Components.Feature.FormInput
                      name="industry"
                      label="Industry"
                      place="Enter Industry"
                    />
                    <Components.Feature.FormInputWithDropDown
                      name="projectType"
                      label="Project Type"
                      place="Select Project Type"
                      data={projectType}
                    />
                    <Components.Feature.FormInput
                      name="budget"
                      label="Budget (Optional)"
                      place="Enter Budget"
                    />
                    <Components.Feature.FormInputWithDropDown
                      name="refValue"
                      label="How did you hear about us?"
                      place="Select Reference"
                      data={refValues}
                    />
                    <Components.Feature.FromTextArea
                      name="message"
                      label="Message"
                      place="Write Message..."
                      rows="7"
                      cols="30"
                    />
                    <section className="heroSectionContactUs-check">
                      <div>
                        <input type="checkbox" defaultChecked/>
                      </div>
                      <Components.Feature.Text className="titory">
                        I want to receive information about Pixelette
                        Technologies and its services. I agree to share my data
                        with Pixelette Technologies.
                      </Components.Feature.Text>
                    </section>
                    <Components.Feature.Button
                      className="primary"
                      animation="fade-up"
                      duration="700"
                    >
                      Submit
                    </Components.Feature.Button>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </div>
      </Components.Feature.Container>
      {/* <div className="heroSectionContactUs">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.contactUs.box_1} alt="box" />
            <img src={assets.contactUs.box_2} alt="box" />
            <img src={assets.contactUs.box_3} alt="box" />
            <img src={assets.contactUs.box_4} alt="box" />
          </blockquote>
          <Components.Common.ContactUs backgrounds={true} />
        </Components.Feature.Container>
      </div> */}
    </>
  );
};

export default HeroSectionContactUs;
