import Components from "../..";
import assets from "../../../assets";
import data from "../../../data";

const OurServicesSection = () => {
  return (
    <div className="ourServices">
      <Components.Feature.Container className="main margins">
        <blockquote>
          <img src={assets.home.box_10} alt="box" />
          <img src={assets.home.box_11} alt="box" />
        </blockquote>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Featured Services"
        text="Pixelette Technologies delivers specialised services designed to enhance your digital transformation journey."
        data={data.ourServcesData}
      />
    </div>
  );
};

export default OurServicesSection;
