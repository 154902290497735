import React from "react";
import Components from "../..";
import data from "../../../data";

const TechnologyStackMobile = () => {
  return (
    <>
      <Components.Common.TechnologyGrid
        heading="Powered by Innovation: Our Technology Stack"
        text="We have a multitude of tools at our disposal. This ensures we remain lightyears ahead of our competitors in developing mobile applications."
        data={data.technologyStackMobileData}
      />
    </>
  );
};

export default TechnologyStackMobile;
