import assets from "../../assets";

export const technologyStackAiData = [
  assets.aiServices.tsw_1,
  assets.aiServices.tsw_2,
  assets.aiServices.tsw_3,
  assets.aiServices.tsw_4,
  assets.aiServices.tsw_5,
  assets.aiServices.tsw_6,
  assets.aiServices.tsw_7,
  assets.aiServices.tsw_8,
  assets.aiServices.tsw_9,
  assets.aiServices.tsw_10,
  assets.aiServices.tsw_11,
  assets.aiServices.tsw_12,
];
