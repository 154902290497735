export const homeSpecialData = [
  {
    heading: "Government Endorsement",
    text: "Recognised by key government bodies, such as the prestigious APPG AI and APPG Blockchain, showcasing our trusted expertise and leadership in technology.",
  },
  {
    heading: "World-Class Team",
    text: "Elite minds at work: Over 200 staff, 90% in the top 5% in their respective fields, with senior management in the top 1%.",
  },
  {
    heading: "Extensive Expertise & Worldwide Presence",
    text: "With over 200 projects completed and active in 13 countries, we deliver global solutions with a local touch.",
  },
  {
    heading: "Proven Case Studies",
    text: "Transforming businesses with successful projects for startups, industry giants and governments across various sectors and geographies.",
  },
  {
    heading: "Cost Effective",
    text: "Delivering high-value solutions without the premium price tag.",
  },
  {
    heading: "Unmatched Success",
    text: "Global tech pacesetter 2023 and 2024: A legacy of groundbreaking achievements.",
  },
];
