export const shippingData = [
  {
    id: 1,
    year: 2024,
    text: "Launch of BGLD on Arbitrum and Binance Smart Chain, with 100% proof of reserves for 1:1 backing audited.",
  },
  {
    id: 2,
    year: 2024,
    text: "Rapid expansion across leading blockchains along with announcements of partnerships increasing the utility of BGLD.",
  },
  {
    id: 3,
    year: 2024,
    text: "Launch of Gold Bar NFT that allows converting tokens into NFTs with true ownership of individual 100 Gram and Kilogram Gold",
  },
  {
    id: 4,
    year: 2024,
    text: "Utilization of BlockGold NFTs as collateral for loans.",
  },
  {
    id: 5,
    year: 2025,
    text: "Option to migrate to BlockGuard Network",
  },
];
