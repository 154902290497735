import assets from "../../assets";

export const aboutServicesData = [
  {
    icon: assets.aboutUs.w_1,
    name: "Discovery and Planning:",
    description:
      "Every project begins with a thorough analysis where we understand your business goals, project expectations, and the technical and operational requirements. This stage sets the foundation for project success.",
  },
  {
    icon: assets.aboutUs.w_2,
    name: "Design and Prototyping:",
    description:
      "Leveraging the latest UX/UI design principles, we create intuitive and engaging prototypes, allowing you to visualise the end product and ensure it aligns perfectly with your expectations before full-scale development begins.",
  },
  {
    icon: assets.aboutUs.w_3,
    name: "Development and Implementation: ",
    description:
      "Our teams of engineers and developers use cutting-edge technologies to build robust, scalable, and secure applications. We ensure that every code snippet is optimised for performance and longevity.",
  },
  {
    icon: assets.aboutUs.w__4,
    name: "Quality Assurance:",
    description:
      "Rigorous testing routines are integrated throughout the development process. Our QA experts use automated and manual testing techniques to ensure the software is bug-free and meets the highest quality standards.",
  },
  {
    icon: assets.aboutUs.w_4,
    name: "Deployment and Continuous Improvement:",
    description:
      "After deployment, we monitor the application’s performance continuously and iterate to enhance functionalities and integrate new features as your business evolves.",
  },
  {
    icon: assets.aboutUs.w_6,
    name: "Support and Maintenance:",
    description:
      "Our relationship with clients extends beyond delivery. We provide ongoing support and maintenance to ensure your software continues to operate flawlessly over time.",
  },
];
