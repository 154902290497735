import React, { useRef } from "react";
import Slider from "react-slick";
import Components from "..";
import { Link } from "react-router-dom";
import assets from "../../assets";
import { v4 as uuidv4 } from "uuid";

const DeliverHeroCard = (props) => {
  
 
  return (
    <>
      <div className="caseSlider">
        <section data-aos="fade-up" data-aos-duration="600">
              <Card
                image={props.heroImage}
                blockChainLink={props.category}
                // uiDesignLink={el.uiDesignLink}
                // developmentLink={el.developmentLink}
                id={props.id}
                name={props.name}
                description={props.description}
                key={uuidv4()}
              />
              
        </section>
      </div>
    </>
  );
};

export default DeliverHeroCard;


const Card = (props) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const truncatedName = truncateText(props.name, 50); // Adjust the maxLength as needed
  const truncatedDescription = truncateText(props.description, 100);
  
const link = "/case-study/" + props.id;
const category = props.blockChainLink;
  return (
    <Link to={link} >
    <div className="caseSlider-card">       
      <div>
        <img src={props.image} alt="slider hero" />
      </div>
      <div>
        <div>
          <Link to= {link} >
          <Components.Feature.Button className="primary">
          {category}
          </Components.Feature.Button>
          </Link>
          {/* <Components.Feature.Button className="primary">
            UI UX Design
          </Components.Feature.Button>
          <Components.Feature.Button className="primary">
            Development
          </Components.Feature.Button> */}
        </div>
        {/* <h1>{props.name}</h1> */}
        <h1>{truncatedName}</h1>

        <Components.Feature.Text className="titory">
          {/* {props.description} */}
          {truncatedDescription}
        </Components.Feature.Text>
        {/* <button>Read More</button> */}
      </div>
    </div>
    </Link>
  );
};
