import Components from "..";
import assets from "../../assets";
assets; // import AwardCards from "./AwardCards";

const BrilliantIdea = (props) => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="specialSection-background">
          <img src={assets.aboutUs.howWeWorkBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="specialSection">
        <Components.Feature.Container className="main margins">
          <section>
            <div className="award-text">
              <Components.Feature.Heading
                className="secondry"
                animation="fade-up"
                duration={`400`}
                id="h_ani"
              >
                {props.heading ? (
                  props.heading
                ) : (
                  <>
                    Shipped 200+ <br />
                    tech projects and
                    <br /> counting
                  </>
                )}
              </Components.Feature.Heading>
              <br /> <br />
              <Components.Feature.Text
                className="secondry"
                // animation="fade-up"
                // duration={`500`}
              >
                {props.text ? "" : "Not to forget,"}
                <ul className="project-deilvered">
                  <li>35,000+ hours in development</li>
                  <li>1,000,000,00+ project visitors garnered</li>
                  <li>100% project completion success rate</li>
                  <li>Secured over £100M in funding through MVPs</li>
                </ul>
              </Components.Feature.Text>
              {/* <div>
                {props.data
                  ? props.data.map((el) => (
                      <Item
                        key={uuidv4()}
                        heading={el.heading}
                        text={el.text}
                      />
                    ))
                  : data.whatMakeSpecialData.map((el) => (
                      <Item
                        key={uuidv4()}
                        heading={el.heading}
                        text={el.text}
                      />
                    ))}
              </div> */}
            </div>
            <img src={assets.commonAssests.BriliantIdea} alt="" />
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default BrilliantIdea;

const Item = (props) => {
  return (
    <>
      <div>
        <section>
          <img src={assets.commonAssests.dot} alt="dot" />
        </section>
        <div>
          <Components.Feature.Text
            className="primary--bold"
            // animation="fade-up"
            // duration={`600`}
          >
            {props.heading}
          </Components.Feature.Text>
          <Components.Feature.Text
            className="titory--bold"
            // animation="fade-up"
            // duration={`400`}
          >
            {props.text}
          </Components.Feature.Text>
        </div>
      </div>
    </>
  );
};
