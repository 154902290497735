export const uiSpecialData = [
  {
    heading: "Government Trust and Recognition:",
    text: "Endorsed by industry and governments for excellence, exclusively chair UK’s leading APPGs.",
  },
  {
    heading: "World-Class Design Team: ",
    text: "Our team comprises over 200 design specialists, with a significant number ranking in the top 5% talent globally. Our senior designers are recognised leaders in creating user-centric design solutions.",
  },
  {
    heading: "Global Reach in Design Innovation: ",
    text: "With projects spanning over 200 clients globally and more than 30,000 hours of execution, our solutions are crafted to meet diverse user needs across multiple sectors.    ",
  },
  {
    heading: "Proven Design Efficiency: ",
    text: "Our designs have revolutionised user engagement for global brands like Bosch, Mitsubishi, Pfizer, NASDAQ, Siemens, Nestle and more, improving customer interactions and enhancing overall digital experience.",
  },
  {
    heading: "Cost-Effective Solutions: ",
    text: "We deliver high-value design at competitive pricing, ensuring you get the best ROI on your design investment without compromising on quality.    ",
  },
  {
    heading: "Unmatched Design Success: ",
    text: "Our portfolio features award-winning designs recognised by industry authorities, underlining our commitment to pushing creative boundaries and setting industry standards.",
  },
];
