import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../data";
import Components from "../components";
import assets from "../assets";
import { createClient } from "contentful";
const BlogDetail = () => {
  const [singleBlogDetail, setSingleBlogDetail] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const client = createClient({
      space: "ggtsbq0gqfii",
      accessToken: "VZvVye8dMIc497wF-1pNt5rdYUG-h4E30uX58AcGVUo",
    });
    const getEntryById = async () =>{
      try {
        client.getEntry({content_type: 'blogs'}).then((response) => {
        console.log("response: ", response);
        setSingleBlogDetail(response);
        })
      } catch (error) {
        console.log(error)
      }
    }

    getEntryById()
}, [])
  console.log(singleBlogDetail) 

  
  // let el = data.blogsData.find((item) => item.id === id * 1);
  return (
    <>
      <div className="blogDetail">
        <Components.Feature.Container className="main">
          <div className="blogDetail-background">
            <img src={assets.blogs.singleBlogBackground} alt="blog" />
          </div>
        </Components.Feature.Container>
        <section>
          <Components.Feature.Container className="main margins">
            <Components.Common.DetailsNavigate
              // data={singleBlogDetail}
              headingIndex={false}
              overViewIndex={false}
              headerSection={true}
            />
          </Components.Feature.Container>
        </section>
      </div>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default BlogDetail;
