import React from "react";
import Components from "..";
import { Formik, Form } from "formik";
import data from "../../data";
import assets from "../../assets";
import { useState } from "react";

import emailjs from "emailjs-com";

const ContactUs = (props) => {

  const [statusMessage, setStatusMessage] = useState("");
  const dummyValues = [
    "Search Engine",
    "Clutch",
    "Social Media",
    "Referral",
    "Others",
  ];
  const initalValues = {
    fullName: "",
    email: "",
    message: "",
    phone: "",
     refer: "N/A",
  };

  return (
    <>
      {props.backgrounds ? (
        ""
      ) : (
        <Components.Feature.Container className="main">
          <div className="contactUs-background">
            <img
              src={assets.contactUs.contactUsLeftBackground}
              alt="researchbackground"
            />
            <img
              src={assets.contactUs.contactUsRightBackground}
              alt="researchbackground"
            />
          </div>
        </Components.Feature.Container>
      )}
      {/* <Components.Feature.Container className="main"> */}
      <div className="contactUs">
        {props.header ? (
          ""
        ) : (
          <center>
            <Components.Feature.Heading
              className="secondry"
              id="h_ani"
              animation="fade-up"
              duration="500"
            >
              Contact Us
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="600"
            >
              Get in touch to let us know what you’re looking for, and one of
              our solutions architects will get back to you.
            </Components.Feature.Text>
          </center>
        )}

        <section>
          <div>
            {props.insideHeading && (
              <Components.Feature.Heading className="policyHeading">
                {props.insideHeading}
              </Components.Feature.Heading>
            )}

            <Formik
              initialValues={initalValues}
              validateOnMount
              validationSchema={data.validationContactUs}
              onSubmit={(values, { resetForm }) => {
                if (window.lintrk) {
                  window.lintrk('track', { conversion_id: 19141409 });
                  console.log("conversion");
                } else {
                  console.error("LinkedIn tracking not initialized.");
                }

                const templateParams = {
                  fullName: values.fullName,
                  email: values.email,
                  phone: `+ ${values.phone}`,
                  reference: values.refer,
                  message: values.message,
                };

                emailjs
                  .send(
                    "service_5y0itcg",
                    "template_4aiiawr",
                    templateParams,
                    "LYS_0H8byHSkeaSrz"
                  )
                  .then((response) => {
                    console.log(values);
                    console.log(response);

                     setStatusMessage('Email sent successfully!');
                  })
                  .catch((error) => {
                    console.log(error);

                    //  setStatusMessage('Failed to send email.');
                  });

                console.log(values + "component/common");
                resetForm();
              }}
            >
              {(formik) => (
                <Form>
                  <div className="contactUs-form">
                    <div className="contactUs-form-flex">
                      <Components.Feature.FormInput
                        name="fullName"
                        label="Full Name"
                        place="Enter your full name"
                      />
                      <Components.Feature.FormInput
                        name="email"
                        label="Email"
                        place="Enter your email"
                      />
                    </div>
                    <Components.Feature.InputPhoneNo
                      name="phone"
                      label="Phone Number"
                      place="+1 (555) 000-0000"
                    />
                    {/* <Components.Feature.FormInputWithDropDown
                      name="refer"
                      label="Reference"
                      place="How did you hear about our company?"
                      data={dummyValues}
                    /> */}
                    <Components.Feature.FromTextArea
                      name="message"
                      label="Message"
                      place="Write your message..."
                      rows="7"
                      cols="30"
                    />
                    <Components.Feature.Button
                      className="primary"
                      animation="fade-up"
                      duration="800"
                      type="submit"
                    >
                      Submit
                    </Components.Feature.Button>
                    {statusMessage && (
                      <div className="status-message">{statusMessage}</div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </div>
      {/* </Components.Feature.Container> */}
    </>
  );
};

export default ContactUs;
