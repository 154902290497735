export const mapIndexData = [
  {
    city: "Boston, USA",
    phone: "+1 978 761 6348",
    mail: "dave@pixelettetech.com",
    address: "9 May St, Dracut, MA 01826",
  },
  {
    city: "Chicago, USA",
    phone: "+1 7732709034",
    mail: "sales@pixelettetech.com",
    address: "2335. W. Devon, Ave, IL 6065",
  },

  {
    city: "Florida, USA",
    phone: "+1 7275128281",
    mail: "scott@pixelettetech.com",
    address: "680 Still Meadows Circle E Palm Harbor, Florida. 34683",
  },
  {
    city: "New York, USA",
    phone: "+718-915-4500",
    mail: "hm4rk07@gmail.com",
    address: "NYC, NY, USA",
  },
  {
    city: "Georgia, USA",
    phone: "+1 407 446 3408",
    mail: "the.alan.espinoza@gmail.com",
    address: "325 Crestbrook Lane Dallas GA 30157",
  },
  
  {
    city: "Alicante, Spain",
    phone: "+34 689408339",
    mail: "alejandro@pixelettetech.com",
    address: "Calle Virgen del Socorro 79",
  },
  
  
  {
    city: "Hague, Netherland",
    phone: "+31 641381152",
    mail: "carlotta@pixelettetech.com",
    address: "2403SG, Elzekrulzoom 4 in Alphen aan den Rijn",
  },
  
  {
    city: "Istanbul, Türkiye",
    phone: "+90 531 270 62 61",
    mail: "yilma.karatuna@cresc.com.tr",
    address:
      "Metropol Istanbul Sitesi, C1 Business Center No: 68, TR-34758 Atasehir, Istanbul, Türkiye",
  },
  
  {
    city: "Ampang, Malaysia",
    phone: "+60165185765",
    mail: "Jeremy@pixelettetech.com",
    address: "Ampang, Selangor Darul Ehsan, Malaysia",
  },

  {
    city: "Zug, Switzerland",
    phone: "+41 786436475",
    mail: "cyril.chiffot@dinergypartners.com",
    address: "Dinergy Partners GmbH Bellevueweg 36B 6300 Zug Switzerland",
  },
  
  {
    city: "Lodz, Poland",
    phone: "+48 793399185",
    mail: "oskar@pixelettetech.com",
    address: "Kronikarska 13, postcode 92-624",
  },
  

  {
    city: "Salzburg, Austria",
    phone: "+436706525979",
    mail: "Daniel@pixelettetech.com",
    address: "Straubingerstrasse 1a, 5020 Salzburg",
  },
  
  {
    city: "Mexico, Mexico",
    phone: "+52 5591081521",
    mail: "carlos.rojas.noveron@gmail.com",
    address: "#20, Las Arboledas, Atizapán de Zaragoza, México, México",
  },
  
  {
    city: "St Catherine’s Jamaica",
    phone: "+1 (876) 803 3981",
    mail: "loraeklife@gmail.com",
    address: "304 Marylebone Road Westmore Gardens St Catherine’s Jamaica",
  },

  {
    city: "Florianópolis, Brazil",
    phone: "+3 466 546 2209",
    mail: "Emmanuel@pixelettetech.com",
    address:
      "Rua Laélia Purpurata, 272 – João Paulo, Florianópolis – SC, 88030-354, Brazil",
  },
  {
    city: "Lahore, Pakistan",
    phone: "+92 3114612831",
    mail: "sales@pixelettetech.com",
    address: "737-G4 , Johar Town",
  },
  {
    city: "London, UK",
    phone: "+44 2045188226",
    mail: "sales@pixelettetech.com",
    address: "71-75 Shelton Street, WC2H9J",
  },
  
];
