import React, { useEffect, useState } from "react";
import Components from "../components";
import assets from "../assets";
import data from "../data";
import { useParams } from "react-router-dom";
import { createClient } from "contentful";


const CaseStudieDetail = () => {
  const [blogdata, setBlogData] = useState([]);
  const el = data.testimonialData[0];
  const { id } = useParams();
  useEffect(() => {
    const client = createClient({
      space: "ggtsbq0gqfii",
      accessToken: "VZvVye8dMIc497wF-1pNt5rdYUG-h4E30uX58AcGVUo",
    });
  
    client
      .getEntry(id)
      .then((response) => {
        console.log("response: ", response);
        setBlogData(response); // response itself is the data you need
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  return (
    <>
      <div className="caseStudieDetail">
        <Components.Feature.Container className="main">
          <div className="caseStudieDetail-background">
            <img
              src={assets.caseSlider.heroSectionBackgroundLeft}
              alt="background"
            />
            <img
              src={assets.caseSlider.heroSectionBackgroundRight}
              alt="backgroundImage"
            />
          </div>
        </Components.Feature.Container>
        <section>
          <Components.Feature.Container className="main margins">
            <header>
              <div>
                <Components.Feature.Heading
                  className="secondry"
                  animation="zoom-out"
                  duration={`700`}
                >
                  {blogdata.fields?.name}
                </Components.Feature.Heading>
                <Components.Feature.Text
                  className="primary"
                  animation="zoom-in"
                  duration="1000"
                >
                  {blogdata.fields?.longDescription}
                </Components.Feature.Text>
                {blogdata.fields?.projectLink && (
                  <a href={blogdata.fields?.projectLink} target="blank">
                    <Components.Feature.Button
                      className="primary"
                      animation="fade-up"
                      duration="1100"
                    >
                      View Project
                    </Components.Feature.Button>
                  </a>
                )}
              </div>
              <figure>
                <img src={blogdata.fields?.image?.fields?.file?.url} alt="cardImage" />
              </figure>
            </header>
            <section>
              <header data-aos="fade-up" data-aos-duration={`900`}>
                <div>
                  <Components.Feature.Heading className="primary">
                    Problem Statement
                  </Components.Feature.Heading>
                  <Components.Feature.Text className="titory--bold">
                    {blogdata.fields?.problemStatement}
                  </Components.Feature.Text>
                </div>
                <div>
                  <Components.Feature.Heading className="primary">
                    Solution
                  </Components.Feature.Heading>
                  <Components.Feature.Text className="titory--bold">
                    {blogdata.fields?.solutions}
                  </Components.Feature.Text>
                </div>
              </header>

              <section data-aos="fade-up" data-aos-duration={`900`}>
                <Components.Feature.Heading className="primary">
                  Tech Stack
                </Components.Feature.Heading>
                <div>
                  <div>
                    <Components.Feature.Text className="primary--bold">
                      Front-End
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      HTML/CSS
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      TailwindCSS
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Bootstrap
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Angular
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Typescript
                    </Components.Feature.Text>
                  </div>
                  <div>
                    <Components.Feature.Text className="primary--bold">
                      Back-End
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      (Cross Platform)
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Backend
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      NestJS
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      PostgreSQL
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      ExpressJS
                    </Components.Feature.Text>
                  </div>
                  <div>
                    <Components.Feature.Text className="primary--bold">
                      Integrations
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      TaxJar
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Stripe
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Two-way POS
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Maps
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Braintree PayPal
                    </Components.Feature.Text>
                  </div>
                  <div>
                    <Components.Feature.Text className="primary--bold">
                      Other
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Jenkins
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      AWS
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Docker
                    </Components.Feature.Text>
                    <Components.Feature.Text className="titory--bold">
                      Sonar
                    </Components.Feature.Text>
                  </div>
                </div>
              </section>
              <figure>
                <img src={blogdata.fields?.image?.fields?.file?.url} alt="Main Image" />
              </figure>
              <div>
                <Components.Feature.Heading className="primary">
                  Conclusion
                </Components.Feature.Heading>
                <Components.Feature.Text className="titory--bold">
                  {blogdata.fields?.conclusion}
                </Components.Feature.Text>
              </div>
            </section>
            <blockquote>
              <header>
                <center data-aos-duration={`700`} data-aos="fade-up">
                  <Components.Feature.Heading className="secondry" id="h_ani">
                    Conclusion
                  </Components.Feature.Heading>
                </center>
                <Components.Feature.TestimonialCard
                  comment={el.comment}
                  profile={el.user_profile}
                  rating={el.rating}
                  userName={el.user_name}
                  role={el.user_role}
                  animation="fade-up"
                  duration={`1000`}
                />
              </header>
            </blockquote>
          </Components.Feature.Container>
        </section>
      </div>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default CaseStudieDetail;

{/* <section>
  <center data-aos-duration={`700`} data-aos="fade-up">
    <Components.Feature.Heading id="h_ani" className="secondry">
      More Projects
    </Components.Feature.Heading>
  </center>
  <div>
    <Components.Feature.CaseStudiCard />
    <Components.Feature.CaseStudiCard />
    <Components.Feature.CaseStudiCard />
  </div>
</section> */}