import assets from "../../assets";

export const technologyStackSoftware = [
  assets.software.tsw_1,
  assets.software.tsw_2,
  assets.software.tsw_3,
  assets.software.tsw_4,
  assets.software.tsw_5,
  assets.software.tsw_6,
  assets.software.tsw_7,
  assets.software.tsw_8,
  assets.software.tsw_9,
  assets.software.tsw_10,
  assets.software.tsw_11,
  assets.software.tsw_12,
  assets.software.tsw_13,
  assets.software.tsw_14,
  assets.software.tsw_15,
  assets.software.tsw_16,
  assets.software.tsw_17,
  assets.software.tsw_18,
];
