export const softwareSpecialData = [
  {
    heading: "Government Endorsement",
    text: "Recognised by governmental bodies for outstanding contributions to technology and innovation, including our influential role in various government advisory groups across multiple countries.",
  },
  {
    heading: "World-Class Development Team",
    text: "Our team comprises over 200 skilled professionals globally, with senior members recognised among the top 1% in their fields. Their expertise ensures innovative, reliable, and scalable software solutions.",
  },
  {
    heading: "Global Reach, Local Expertise",
    text: "With more than 200 custom projects and over 30,000 hours of development, we've helped businesses around the world leverage technology for competitive advantage. Active in 13 countries, we bring global insights with local execution to every project.",
  },
  {
    heading: "Proven Success Across Industries",
    text: "From enterprise resource planning systems to advanced CRM solutions, our custom software has driven significant efficiency gains for clients like Dodge, Fiat, and Sandoz, demonstrating our capability to deliver complex solutions across diverse industries.",
  },
  {
    heading: "Cost Efficiency",
    text: "We provide high-value custom software development without the premium price tag, focusing on creating cost-effective solutions that maximize your return on investment.",
  },
  {
    heading: "Unmatched Track Record",
    text: "Our dedication to excellence in custom software development has earned us numerous awards and a reputation as a leader in the industry, underscoring our commitment to quality and innovative solutions.",
  },
];
