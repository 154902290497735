import { Link } from "react-router-dom";
import Components from "..";
import { v4 as uuidv4 } from "uuid";

const YourPlanCard = (props) => {
  return (
    <>
      {props.data.map((el, index) => {
        const cardContent = (
          <div>
            <div style={{display: 'flex'}}>
            <div style={{backgroundColor: '#006FF026', display: 'flex', alignItems: 'center', gap: '7px', padding: '5px 20px', borderRadius: '100px'}}>
            <img src={el.icon} alt="icon" />
            <h3 className="">{el.iconhead}</h3>
            </div>
            </div>
            <Components.Feature.Text className="primary--bold">
              {el.name}
            </Components.Feature.Text>
            <Components.Feature.Text className="titory">
              {el.description}
            </Components.Feature.Text>
            <Components.Feature.Text className="titory-link">
              {el.link} <img src={el.linkIcon} alt="" />
            </Components.Feature.Text>
          </div>
        );

        return el.path ? (
          <Link
            to={el.path}
            key={uuidv4()}
            id="servicesCard_id"
            className="servicesCard"
            data-aos="fade-up"
            data-aos-duration={`2${index}00`}
            style={{ cursor: el.path ? "pointer" : "default" }}
          >
            {cardContent}
          </Link>
        ) : (
          <div
            key={uuidv4()}
            id="servicesCard_id"
            className="servicesCard"
            data-aos="fade-up"
            data-aos-duration={`2${index}00`}
          >
            {cardContent}
          </div>
        );
      })}
    </>
  );
};

export default YourPlanCard;
