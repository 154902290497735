import React from "react";
import Components from "../..";
import assets from "../../../assets";

const HeroSectionStaff = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-staff-background">
          <img src={assets.staff.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-staff">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.staff.box_1} alt="box" />
            <img src={assets.staff.box_3} alt="box" />
            <img src={assets.staff.box_2} alt="box" />
            <img src={assets.staff.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              Expertise on demand - Premium Staff Augmentation
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              Pixelette Technologies specializes in providing businesses with
              skilled professionals to meet their goals, whether for specific
              roles, project scaling, or additional expertise.
            </Components.Feature.Text>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="2400"
            >
              Let's discuss your project
            </Components.Feature.Button>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionStaff;
