import assets from "../../assets";

export const digitalServicesData = [
  {
    icon: assets.digitalMarketing.s_1,
    name: "Social Media Marketing",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempus sagittis porttitor euismod sed nibh sagittis blandit volutpat nunc. Nisl cras purus nec vitae scelerisque.",
  },
  {
    icon: assets.digitalMarketing.s_2,
    name: "Video Marketing",
    description:
      "Lorem ipsum dolor sit amet consectetur. Justo in nulla ultricies eget tincidunt nisi aliquam aliquam tellus. Cras euismod ipsum",
  },
  {
    icon: assets.digitalMarketing.s_3,
    name: "Google Ads",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis adipiscing metus egestas nunc enim nisl. Tellus fermentum nisi duis bibendum vitae sit.",
  },
  {
    icon: assets.digitalMarketing.s_4,
    name: "Content Marketing",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ullamcorper gravida facilisis erat morbi ipsum montes blandit. Molestie ut sit ",
  },
  {
    icon: assets.digitalMarketing.s_5,
    name: "Email Marketing",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ullamcorper gravida facilisis erat morbi ipsum montes blandit. Molestie ut sit ",
  },
  {
    icon: assets.digitalMarketing.s_6,
    name: "Lead Generation",
    description:
      "Lorem ipsum dolor sit amet consectetur. Ullamcorper gravida facilisis erat morbi ipsum montes blandit. Molestie ut sit ",
  },
];
