import React from "react";
import Components from "../..";
import data from "../../../data";

const ExpertiseMobile = () => {
  return (
    <>
      <Components.Common.ExpertiseSection
        heading="Multi-Platform App Services"
        text="Our company provides cutting-edge app services designed for seamless operation across various platforms, ensuring a consistent user experience across all devices."
        data={data.expertiseAiData}
      />
    </>
  );
};

export default ExpertiseMobile;
