import React from "react";
import Components from "../components";
import data from "../data";
import assets from "../assets";

const Suppliment = () => {
  return (
    <>
      <Components.Common.HeroSectionAiServices
      title="Expand Your Capabilities, Instantly"
      description="Add top-tier talent to your project without the hiring headache."
      cardtitle="How It Works?"
      carddescription1="Our staff augmentation services allows you to easily expand your team with our skilled developers and specialists, integrating them into your ongoing projects."
      carddescription2="We match you with the right talent based on your specific needs. Our developers work alongside your existing team, contributing their expertise and ensuring smooth collaboration. We take care of all the sourcing, recruitment, and onboarding, so you can focus on progress."
      carddescription3="You can expect an immediate boost in capacity and expertise, leading to faster project completion and enhanced quality without the administrative burden of hiring and training new staff."
      cardimage={assets.home.deliver2}
       />
      <Components.Common.DeliverBenifits
      title=<p>Benefits of Staff Augmentation <br/> with Us</p>
      title1="Quick Scaling"
      description1="You can rapidly expand your team without the lengthy recruitment process"
      title2="Cost Efficient"
      description2="You get to save time and money on sourcing, recruiting, and training"
      title3="Flexibility"
      description3="You can scale your team up or down as needed, with no long-term commitment"
      title4="Expertise on Demand"
      description4="You will have access to skilled professionals who bring specialised knowledge to your projects"
       />
      <Components.Common.SelectPlan />
      <Components.Common.ServiceWork />
      <Components.Common.SupplimentFaqs />
      {/* <Components.Common.ContactUs /> */}
      {/* <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default Suppliment;
