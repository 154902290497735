import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const NavbarSubDropDown = (props) => {
  const [active, setActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="how-it-works" ref={dropdownRef}>
        <section
          style={{paddingLeft: '10px'}}
          onClick={() => setActive(!active)}
          data-aos-duration={`100`}
          data-aos="fade-down"
        >
          <p>{props.name}</p>
          <motion.div
            animate={
              active
                ? {
                    rotate: -180,
                  }
                : { rotate: 0 }
            }
          >
            <IoIosArrowDown />
          </motion.div>
        </section>
        {active && (
          <motion.div
            initial={{ y: "-6rem", opacity: 0 }}
            animate={{ y: "0rem", opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {props.subMenus.map((el) => (
              <Link key={uuidv4()} onClick={() => setActive(false)} to={el.to}>
                {el.name}
              </Link>
            ))}
          </motion.div>
        )}
      </div>
    </>
  );
};

export default NavbarSubDropDown;
