import React from "react";
import Components from "..";
import assets from "../../assets";

const TestimonialCard = (props) => {
  return (
    <>
      <div
        className="testimonialCard"
        data-aos={props.animation}
        data-aos-duration={props.duration}
      >
        <div>
          <img src={assets.home.stars} alt="stars" />
          <Components.Feature.Text className="primary">
            {props.rating}
          </Components.Feature.Text>
        </div>
        <Components.Feature.Text className="primary">
          “ {props.comment} ”
        </Components.Feature.Text>

        <section>
          <img src={props.profile} alt="profile" />
          <div>
            <Components.Feature.Text className="primary--bold">
              {props.userName}
            </Components.Feature.Text>
            <Components.Feature.Text className="titory">
              {props.role}
            </Components.Feature.Text>
          </div>
        </section>
      </div>
    </>
  );
};

export default TestimonialCard;
