import {
  Home,
  AboutUs,
  Blogs,
  Careers,
  BlogDetail,
  AiServices,
  RefundPolicy,
  PrivacyPolicy,
  TermAndConditions,
  CaseStudie,
  BlockChainDevelopment,
  WebDevelopment,
  ArAndVr,
  Mobile,
  Software,
  UI,
  DigitalMarketing,
  StaffAugmented,
  Research,
  StartUps,
  ContactUs,
  CaseStudieDetail,
  CareerDetail,
  Clutch,
  Deliver,
  Deploy,
  Suppliment
} from "../modules";

export const routesData = [
  {
    path: "/",
    element: Home,
    title:
      "Pixelette Technologies | Empowering Businesses with AI & Blockchain",
  },
  {
    path: "about-us",
    element: AboutUs,
    title: "About Us",
  },
  {
    path: "careers",
    element: Careers,
    title: "Careers",
  },
  {
    path: "career/:id",
    element: CareerDetail,
    title: "Career Detail",
  },
  {
    path: "ai-services",
    element: AiServices,
    title: "Artificial Intelligence Solutions",
  },
  {
    path: "privacy-policy",
    element: PrivacyPolicy,
    title: "Our Privacy Policy",
  },
  {
    path: "term-conditions",
    element: TermAndConditions,
    title: "Terms and Conditions",
  },
  {
    path: "refund-policy",
    element: RefundPolicy,
    title: "Cancellation and Refund Policy",
  },
  {
    path: "blogs",
    element: Blogs,
    title: "Blogs ",
  },
  {
    path: "blog/:id",
    element: BlogDetail,
    title: "Blog Detail",
  },
  {
    path: "case-studies",
    element: CaseStudie,
    title: "Case Studies",
  },
  {
    path: "blockchain-development",
    element: BlockChainDevelopment,
    title: "Blockchain Solutions",
  },
  {
    path: "vr-ar",
    element: ArAndVr,
    title: "VR Development Services",
  },
  {
    path: "web-development",
    element: WebDevelopment,
    title: "Website Development Services",
  },
  {
    path: "mobile-development",
    element: Mobile,
    title: "Mobile App Development - iOS, Android, and Cross-Platform",
  },
  {
    path: "custome-software-development",
    element: Software,
    title: "Software Development",
  },
  {
    path: "ui-ux",
    element: UI,
    title: "UI/UX Design Services",
  },
  {
    path: "digital-marketing",
    element: DigitalMarketing,
    title: "Digital Marketing",
  },
  {
    path: "staff-augmented",
    element: StaffAugmented,
    title: "Staff Augmentation Services",
  },
  {
    path: "pixelette-research",
    element: Research,
    title: "Pixelette Research",
  },
  {
    path: "start-ups",
    element: StartUps,
    title: "Our Start-Ups",
  },
  {
    path: "contact-us",
    element: ContactUs,
    title: "Contact Us",
  },
  {
    path: "case-study/:id",
    element: CaseStudieDetail,
    title: "Case Study By Pixelette Technologies",
  },
  {
    path: "clutch",
    element: Clutch,
    title: "Pixelette Technologies | Empowering Businesses with AI & Blockchain",
  },
  {
    path: "idiyas",
    element: Clutch,
    title: "Pixelette Technologies | Empowering Businesses with AI & Blockchain",
  },
  {
    path: "Deliver",
    element: Deliver,
    title: "Pixelette Technologies | Empowering Businesses with AI & Blockchain",
  },
  {
    path: "Deploy",
    element: Deploy,
    title: "Pixelette Technologies | Empowering Businesses with AI & Blockchain",
  },
  {
    path: "Supplement",
    element: Suppliment,
    title: "Pixelette Technologies | Empowering Businesses with AI & Blockchain",
  },
];
