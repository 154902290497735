import React from "react";
import Components from "../..";
import assets from "../../../assets";
import { Link } from "react-scroll";

const HeroSectionBlockChain = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-blockChain-background">
          <img src={assets.blockChain.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-blockChain">
        <Components.Feature.Container className="main ">
          <blockquote>
            <img src={assets.blockChain.box_1} alt="box" />
            <img src={assets.blockChain.box_3} alt="box" />
            <img src={assets.blockChain.box_2} alt="box" />
            <img src={assets.blockChain.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              Unleash the Power of Blockchain - Effortlessly
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              Pixelette Technologies offers custom blockchain solutions to
              revolutionise your business. Our expertise ensures security,
              growth, and efficiency in the digital age. Let us navigate the
              complexities of blockchain for you, empowering you to lead in a
              world where efficiency is paramount.
            </Components.Feature.Text>
            <Components.Feature.Text
              className="primary"
              animation="fade-up"
              duration="2400"
            >
              Experience Excellence in Blockchain – Your journey begins here.
            </Components.Feature.Text>
            <div style={{
              marginTop:"15px"
            }}>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="2800"
            >
              Start Your Transformation
            </Components.Feature.Button>
            </Link>
            </div>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionBlockChain;
