import React from "react";
import Components from "..";

const ExpertiseGrid = (props) => {
  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="expertiseGrid">
          <header>
            <Components.Feature.Heading className="secondry">
              {props.heading}
            </Components.Feature.Heading>
            <Components.Feature.Text className="secondry">
              {props.text}
            </Components.Feature.Text>
          </header>
          <section>
            {props.data.map((el,index) => (
              <div key={index}>
                <img src={el.image} alt="icon" />
                <Components.Feature.Text className="primary">
                  {el.text}
                </Components.Feature.Text>
              </div>
            ))}
          </section>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default ExpertiseGrid;
