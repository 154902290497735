import React from "react";
import Components from "../..";
import assets from "../../../assets";
const HeroSectionDigital = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-digital-background">
          <img
            src={assets.digitalMarketing.heroSectionBackground}
            alt="background"
          />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-digital">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.digitalMarketing.box_1} alt="box" />
            <img src={assets.digitalMarketing.box_3} alt="box" />
            <img src={assets.digitalMarketing.box_2} alt="box" />
            <img src={assets.digitalMarketing.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`500`}
            >
              Grow Your Revenue
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="600"
            >
              Let Pixelette Technologies’ grow your revenue with
              state-of-the-art digital marketing services.
            </Components.Feature.Text>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="700"
            >
              Let's discuss your project
            </Components.Feature.Button>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionDigital;
