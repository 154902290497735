import React from "react";
import Components from "../..";
import assets from "../../../assets";
import { Link } from "react-scroll";
import data from "../../../data";
const HeroSectionAiServices = () => {

  const imageStyle = {
    width: '100px', // Adjust the width as needed
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover', // Ensure the image covers the entire area without stretching
    borderRadius: '8px', // Optional: Add border radius to the images
  };

  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="heroSectionAiServices-background">
          <img src={assets.aiServices.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div id="heroSectionAiServices" className="heroSectionAiServices">
        <Components.Feature.Container className="main margins">
          
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              From Concept to Completion, We Deliver
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              We handle every step of development so you can concentrate on growth
            </Components.Feature.Text>
            
          </center>
          <section>
          <div className="deliverHeroCard">
            <section>
              <h1>How Does It Work?</h1>
              <p>Our Complete Outsourcing services take the entire software development process off your hands. We manage every aspect, from initial planning and design to development, testing, and deployment.</p>
              <p>Once you provide your requirements and objectives, our team takes full control, assembling the right resources and managing the entire project. We work closely with you to ensure alignment with your vision, but the day-to-day execution is our responsibility.</p>
              <p>You can expect a high-quality product delivered on time and within budget, without the need to manage the complexities of development themselves. You’ll have a ready-to-use solution tailored to your business needs, allowing you to focus on growth and strategy.</p>
            </section>
              <img src={assets.home.deliver1} alt="" />
          </div>          
        </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionAiServices;
