import { Link } from "react-router-dom";
import Components from "..";
import { v4 as uuidv4 } from "uuid";

const ServicesCard = (props) => {
  return (
    <>
      {props.data.map((el, index) => {
        const cardContent = (
          <div>
            <img src={el.icon} alt="icon" />
            <Components.Feature.Text className="primary--bold">
              {el.name}
            </Components.Feature.Text>
            <Components.Feature.Text className="titory">
              {el.description}
            </Components.Feature.Text>
          </div>
        );

        return el.path ? (
          <Link
            to={el.path}
            key={uuidv4()}
            id="servicesCard_id"
            className="servicesCard"
            data-aos="fade-up"
            data-aos-duration={`2${index}00`}
            style={{ cursor: el.path ? "pointer" : "default" }}
          >
            {cardContent}
          </Link>
        ) : (
          <div
            key={uuidv4()}
            id="servicesCard_id"
            className="servicesCard"
            data-aos="fade-up"
            data-aos-duration={`2${index}00`}
          >
            {cardContent}
          </div>
        );
      })}
    </>
  );
};

export default ServicesCard;
