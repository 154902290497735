import PropTypes from "prop-types";
import Components from "..";
import assets from "../../assets";
// import AwardCards from &apos;./AwardCards&apos;;

const StartupStats = ({ heading, text }) => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="specialSection-background">
          <img src={assets.aboutUs.howWeWorkBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="specialSection">
        <Components.Feature.Container className="main margins">
          <section>
            <div className="award-text-wrapper">
              <div className="vertical-bar"></div>
              <div className="award-text">
                <Components.Feature.Heading
                  className="secondry"
                  animation="fade-up"
                  duration="400"
                  id="h_ani"
                >
                  {heading ? (
                    heading
                  ) : (
                    <>
                      <span className="percentage">95%</span> <br /> of startup
                      ideas <br />
                      never get to <br /> market
                    </>
                  )}
                </Components.Feature.Heading>
              </div>
            </div>
            <Components.Feature.Text className="secondry">
              {text ? (
                text
              ) : (
                <>
                  <span className="stats-text">
                    And it&apos;s a real setback, because these ideas have the
                    <br />
                    potential to revolutionise industries and solve real-world
                    <br />
                    problems. Here&apos;s why it happens:
                    <br />
                    <br />
                    <div className="list-container">
                      <ul className="ul-stats">
                        <li>
                          <strong>Technical barriers:</strong> Many founders,
                          while solid on the <br />
                          business end, lack the technical expertise needed to
                          build <br />
                          their vision
                        </li>
                        <li>
                          <strong>Resource strain:</strong> Hiring and managing
                          developers can be <br />
                          prohibitively expensive and time-consuming.
                        </li>
                        <li>
                          <strong>Quality issues:</strong> Low-cost solutions
                          often fail to deliver the <br />
                          quality and functionality needed for success.
                        </li>
                      </ul>
                    </div>
                  </span>
                </>
              )}
              <br />
              <br />
            </Components.Feature.Text>
          </section>
          <center className="center-text">
            <span className="stat-mid-text">
              The consequences are significant, valuable ideas remain
              unrealised, and market gaps go unfilled, potential
              <br /> profits slip away as projects stall or fail to meet market
              standards and founders experience ongoing stress and
              <br /> setbacks, delaying their path to success.
            </span>
            <div
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                marginTop: "3rem",
                wordSpacing: "0.2rem",
              }}
            >
              That&apos;s why we can help you out. Just look at our track
              record!
            </div>
          </center>
        </Components.Feature.Container>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

StartupStats.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
};

const Item = ({ heading, text }) => {
  return (
    <>
      <div>
        <section>
          <img src={assets.commonAssests.dot} alt="dot" />
        </section>
        <div>
          <Components.Feature.Text className="primary--bold">
            {heading}
          </Components.Feature.Text>
          <Components.Feature.Text className="titory--bold">
            {text}
          </Components.Feature.Text>
        </div>
      </div>
    </>
  );
};

Item.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default StartupStats;
