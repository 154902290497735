import Components from "../..";
import data from "../../../data";

const HowWeWork = () => {
  return (
    <>
      <Components.Common.CardSectionGrid
        heading="How We Work"
        text="At Pixelette Technologies, our approach to software development and project management is defined by precision, agility, and innovation. We follow a structured yet flexible methodology to ensure we meet your business needs effectively and efficiently:"
        data={data.aboutServicesData}
      />
    </>
  );
};

export default HowWeWork;
