import React, { useState } from "react";
import { motion } from "framer-motion";
import Components from "..";
import assets from "../../assets";
import data from "../../data";

const Map = () => {
  // Find the index of the London location
  const londonIndex = data.mapIndexData.findIndex(location => location.city === "London, UK");
  
  // Set the initial hoveredIndex to the London location index
  const [hoveredIndex, setHoveredIndex] = useState(londonIndex);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(londonIndex); // Revert to London index on mouse leave
  };

  return (
    <Components.Feature.Container className="main margins">
      <div className="map">
        <center>
          <Components.Feature.Heading className="secondry">
            Global Reach, Local Impact: Pixelette Technologies Around the World
          </Components.Feature.Heading>
          <Components.Feature.Text className="titory">
            Discover how Pixelette Technologies spans across continents, with
            dedicated teams in 13 countries ensuring we are both globally
            connected and locally present. Each location is strategically chosen
            to foster close relationships with local markets, offering tailored
            solutions that respect regional nuances and requirements.
          </Components.Feature.Text>
        </center>

        <div>
          {data.mapIndexData.map((el, index) => (
            <header
            key={index}
            
            >
              <div>
                {hoveredIndex === index && (
                  <div className="hide-section show">                    
                    <img src={assets.commonAssests.curve} alt="curve" />
                    <section>
                      <Components.Feature.Text className="primary--bold">
                        {el.city}
                      </Components.Feature.Text>
                      <div>
                        <img src={assets.commonAssests.phone} alt="phone" />
                        <Components.Feature.Text className="titory">
                          {el.phone}
                        </Components.Feature.Text>
                      </div>
                      <div>
                        <img src={assets.commonAssests.mail} alt="mail" />
                        <Components.Feature.Text className="titory">
                          {el.mail}
                        </Components.Feature.Text>
                      </div>
                      <div>

                        <img src={assets.commonAssests.pin} alt="pin" />
                        
                        <Components.Feature.Text className="titory">
                          {el.address}
                        </Components.Feature.Text>
                      </div>
                    </section>
                  </div>
                )}

                <motion.img
                  src={
                    hoveredIndex === index
                      ? assets.commonAssests.pinMapColor
                      : assets.commonAssests.pinMap
                  }

                  alt="pin"
                  className="image-for-hover"
                  whileHover={{ scale: 1.1 }}
                  onMouseEnter={() => handleMouseEnter(index)}
                 // onMouseLeave={handleMouseLeave}
                />
              </div>
            </header>
          ))}

          <section>
            <img src={assets.commonAssests.map} alt="map" />
          </section>
        </div>
      </div>
    </Components.Feature.Container>
  );
};

export default Map;
