import React from "react";
import Components from "../..";

const SoftwareProcess = () => {
  return (
    <>
      <Components.Common.FaqsSection
        heading="Custom Development Process"
        text="Pixelette Technologies employs a meticulous software development process, transforming client visions into reality through expert teamwork"
      />
    </>
  );
};

export default SoftwareProcess;
