export const uiProcessData = [
  {
    question: "Discovery & Research",
    answer: "We begin by understanding your business, market, and users. This stage involves thorough research to define your users’ needs and align them with your business goals, ensuring the design effectively addresses key user pain points and enhances user experience.",
  },
  {
    question: "Strategy & Planning",
    answer:"Our team develops a comprehensive UX strategy, outlining key features, functionalities, and structural design based on the insights gathered. This roadmap guides the project to ensure all goals are met efficiently.",
  },
  {
    question: "Wireframing & Prototyping",
    answer: "We create detailed wireframes and prototypes, providing a visual blueprint of the user interface. This step allows us to experiment with the arrangement of elements to create an intuitive user flow before final design and development.",
  },
  {
    question: "User Interface Design",
    answer: "Our designers craft aesthetically pleasing interfaces that resonate with users while maintaining brand consistency. This involves selecting the right colour schemes, typography, and interactive elements that enhance the user’s interaction with the product.",
  },
  {
    question: "User Testing & Feedback",
    answer:"Rigorous user testing is conducted to gather feedback and identify any usability issues. This iterative process ensures the interface is not only attractive but also intuitive and user-friendly.",
  },
  {
    question: "Implementation & Technical Integration",
    answer:"Our development team integrates the final designs with the backend systems, ensuring technical feasibility and smooth functionality across all platforms and devices.",
  },
  {
    question: "Launch & Optimisation",
    answer:"After thorough testing and quality assurance, the product is launched. Post-launch, we continue to monitor user interactions and make iterative improvements to optimise performance and user satisfaction.",
  },
  {
    question: "Ongoing Support & Iteration",
    answer:"We provide continuous support and updates based on user feedback and evolving business needs, ensuring the product remains relevant and effective over time.",
  },
];
