import awardsData from "../../data/awardcard/awardcardData.js";
import assets from "../../assets";

const AwardCards = () => {
  return (
    <>
      <div className="award-section">
        <div className="award-cards">
          {awardsData.map((award) => (
            <div key={award.id} className="award-card">
              <img src={award.src} alt={award.alt} />
            </div>
          ))}
        </div>
        <div>
          <img src={assets.awards.portfolio} alt="img" />
        </div>
      </div>
    </>
  );
};

export default AwardCards;
