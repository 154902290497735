import React from "react";
import Components from "../components";
import data from "../data";

const BlockChainDevelopment = () => {
  return (
    <>
      <Components.UI.HeroSectionBlockChain />
      <Components.Common.OurClients />
      <Components.UI.ServicesBlockChain />
      <Components.UI.TechnologyStackBlockChain />
      <Components.Common.WhatMakesUsSpecial data={data.blockChainSpecialData} />
      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.UnlockBusinessPotential
        heading="Stay Ahead of the Curve With Blockchain"
        text="Ready to lead the change with blockchain? Connect with our blockchain architects and together, let’s build something extraordinary."
        btnText="Let’s Go"
      />
      <Components.Common.CaseStudies
        heading="Success in Action: Our Blockchain Case Studies"
        text="Explore our blockchain Success Stories: real-world examples of how our blockchain solutions have propelled businesses forward, showcasing tangible results and transformative impacts."
      />
      <Components.Common.Partners />
      <Components.Common.Testimonial />
      {/* <Components.UI.ExpertiseBlockChain /> */}
      {/* <Components.Common.AchievementsSection /> */}
      <Components.Common.FaqsSection data={data.blockFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default BlockChainDevelopment;
