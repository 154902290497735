import React from "react";
import Components from "../..";
import assets from "../../../assets";
import {Link} from "react-scroll";

const HeroSectionSoftware = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-software-background">
          <img src={assets.software.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-software">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.software.box_1} alt="box" />
            <img src={assets.software.box_3} alt="box" />
            <img src={assets.software.box_2} alt="box" />
            <img src={assets.software.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              {/* for Streamlined Business Operations */}
              Custom Software Development 
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              Navigate your business challenges with tailored software solutions
              from Pixelette Technologies. Whether you need an advanced ERP
              system, an intuitive CRM, or a robust Document Management System,
              our expert team understands the intricacies of your industry and
              the specific hurdles you face. We specialise in developing
              high-quality, scalable software that simplifies complex processes,
              enhances productivity, and integrates seamlessly with your
              existing operations. With a focus on delivering user-friendly and
              result-driven applications, we ensure that our custom solutions
              empower your team and drive your business success.
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>

            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="2400"
            >
              Let&#39;s Solve Your Challenges
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionSoftware;
