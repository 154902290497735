export const softwareFaqs = [
  {
    question: "What services does your company provide?",
    answer:
      "Pixelette Technologies specialises in custom software development. This includes web and mobile app development, software integration and maintenance along with support services. However, these are only some of our services. Please contact us for more info.",
  },
  {
    question: "What programming languages and technologies do you use?",
    answer:
      "Our company possesses expertise in a wide range of programming languages and technologies, including but not limited to: Python, JavaScript, C#, Java, React, Angular, and Vue.js.",
  },
  {
    question:
      "Can you provide examples of projects you have completed in the past?",
    answer:
      "Yes, we have completed a wide range of projects for various industries, including e-commerce, healthcare, finance, and education. Please contact us for specific case studies.",
  },
  {
    question:
      "How do you handle project management and communication with clients?",
    answer:
      "We use agile methodologies for project management and maintain open communication with our clients through regular meetings, progress updates, and a dedicated project management tool. On the other hand, we are also open to different approaches, should the need arise.",
  },
  {
    question: "How do you ensure the security and privacy of client data?",
    answer:
      "We take data security and privacy very seriously at Pixelette Technologies. Thus, our team carefully implements industry-standard best practices, such as implementing SSL encryption, secure coding guidelines and regular security testing.",
  },
  {
    question:
      "Do you provide maintenance and support services after a project is completed?",
    answer:
      "Yes, we offer maintenance and support services to ensure that the software continues to function properly while addressing any issues that may arise after the project is completed. These services are offered on the client’s approval.",
  },
  {
    question:
      "How do you handle change requests or scope changes during a project?",
    answer:
      "We take a flexible approach to change requests and scope changes. Therefore, Pixelette Technologies will work with the client to ensure that their needs are met while also maintaining the project timeline and budget.",
  },
];
