import assets from "../../assets";

export const technologyStackUI = [
  assets.ui.tsw_1,
  assets.ui.tsw_2,
  assets.ui.tsw_3,
  assets.ui.tsw_4,
  assets.ui.tsw_5,
  assets.ui.tsw_6,
  assets.ui.tsw_7,
];
