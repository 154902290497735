import React, { useState, useEffect, useMemo, useRef } from 'react';
import Globe from 'react-globe.gl';

const World = () => {
  const [rise, setRise] = useState(false);
  const [loading, setLoading] = useState(true);
  const globeEl = useRef();

  
  useEffect(() => {
    if (globeEl.current) {
      const controls = globeEl.current.controls();
      controls.enableZoom = false;
      controls.enablePan = false; 
    }
    setLoading(false); // Set loading to false once the component has been set up
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setRise(true), 6000);
    return () => clearTimeout(timer);
  }, []);

  const N_PATHS = 10;
  const MAX_POINTS_PER_LINE = 10000;
  const MAX_STEP_DEG = 1;
  const MAX_STEP_ALT = 0.015;
  const gData = useMemo(() => [...Array(N_PATHS).keys()].map(() => {
    let lat = (Math.random() - 0.5) * 90;
    let lng = (Math.random() - 0.5) * 360;
    let alt = 0;

    return [[lat, lng, alt], ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
      lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
      lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
      alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
      alt = Math.max(0, alt);

      return [lat, lng, alt];
    })];
  }), []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Globe
      ref={globeEl}
      width={500}
      height={375}
      enablePointerInteraction={false}
      backgroundColor='rgba(0,0,0,0)'
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
      bumpImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
      pathsData={gData}
      pathColor={() => ['rgba(0,0,255,0.6)', 'rgba(255,0,0,0.6)']}
      pathDashLength={0.01}
      pathDashGap={0.004}
      pathDashAnimateTime={100000}
      pathPointAlt={rise ? pnt => pnt[2] : undefined}
      pathTransitionDuration={rise ? 4000 : undefined}
      
      controls={{
        minDistance: 300,
        maxDistance: 300,
      }}
    />
  );
};

export default World;
