import assets from "../../assets";

export const technologyBlockChainData = [
  assets.blockChain.tsw_1,
  assets.blockChain.tsw_12,
  assets.blockChain.tsw_2,
  assets.blockChain.tsw_3,
  assets.blockChain.tsw_4,
  assets.blockChain.tsw_5,
  assets.blockChain.tsw_6,
  assets.blockChain.tsw_7,
  assets.blockChain.tsw_8,
  assets.blockChain.tsw_9,
];
