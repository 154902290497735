import React from "react";
import Components from "..";
import assets from "../../assets";
import Marquee from "react-fast-marquee";
import data from "../../data";
import { v4 as uuidv4 } from "uuid";

const Achievements = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div
          className="achievementSection"
          data-aos="fade-up"
          data-aos-duration={`800`}
        >
          <div>
            <Components.Feature.Heading className="secondry" id="h_ani">
              Achievements & Award
            </Components.Feature.Heading>
            <img src={assets.commonAssests.borderRight} alt="icon" />
          </div>

          <section>
            <Marquee speed={60}>
              {data.achievementData.map((el) => (
                <div key={uuidv4()} className="achievement-icon">
                  <img src={el} alt="icon" />
                </div>
              ))}
            </Marquee>
          </section>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default Achievements;
