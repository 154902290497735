import React from "react";
import Components from "..";
import assets from "../../assets";
import Marquee from "react-fast-marquee";

const Partners = () => {
  return (
    <>
      <div className="partners" data-aos="fade-up" data-aos-duration="600">
        <Components.Feature.Container className="main margins">
          <center>
            <Components.Feature.Heading className="secondry" id="h_ani">
              Industry Partnerships and Accreditation
            </Components.Feature.Heading>
          </center>
          <div>
            <Marquee speed={60}>
              <div className="partners-image">
                <img src={assets.home.p_1} alt="icon" />
              </div>
              <div className="partners-image">
                <img src={assets.home.p_2} alt="icon" />
              </div>
              <div className="partners-image">
                <img src={assets.home.p_3} alt="icon" />
              </div>
              <div className="partners-image">
                <img src={assets.home.p_4} alt="icon" />
              </div>
              <div className="partners-image">
                <img src={assets.home.p_5} alt="icon" />
              </div>
              
              {/* new */}
              <div className="partners-image">
                <img src={assets.home.p6} alt="icon" />
              </div>
              <div className="partners-image">
                <img src={assets.home.p_7} alt="icon" />
              </div>
              <div className="partners-image">
                <img src={assets.home.p_8} alt="icon" />
              </div>
            </Marquee>
          </div>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default Partners;
