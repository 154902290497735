import React, { useEffect, useState } from "react";
import Components from "../components";
// import data from "../data";
import assets from "../assets";
import { createClient } from "contentful";
import BlogGridWithBanner from "../components/common/BlogGridWithBanner"; // Import the BlogGridWithBanner component

const Blogs = () => {
  const [blogdata, setBlogData] = useState([]);

  useEffect(() => {
    const client = createClient({
      space: "ggtsbq0gqfii",
      accessToken: "VZvVye8dMIc497wF-1pNt5rdYUG-h4E30uX58AcGVUo",
    });

    client
      .getEntries({content_type: "blogs"})
      .then((response) => {
        console.log("response: ", response);
        setBlogData(response.items);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <div className="blogsPage">
        <Components.Feature.Container className="main">
          <div className="blogsPage-background">
            <img src={assets.blogs.blogsMainBackground} alt="background" />
          </div>
        </Components.Feature.Container>
        <center>
          <Components.Feature.Heading
            className="heroHeading"
            animation="zoom-out"
            duration={`2000`}
          >
            The Pixelette Post
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="zoom-in"
            duration="2200"
          >
            Dive into our curated collection of updates and guides to deepen
            your understanding of diverse technologies.
          </Components.Feature.Text>
        </center>
        <section>
          <BlogGridWithBanner
            data={blogdata}
            singleView={true}
          />
        </section>
      </div>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default Blogs;
