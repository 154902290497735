import React from "react";
import assets from "../../../assets";
import Components from "../..";

const TeamSectionHome = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="ourTeamSectionHome-background">
          <img src={assets.home.ourTeamBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="ourTeamSectionHome">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.home.box_26} alt="box" />
            <img src={assets.home.box_27} alt="box" />
          </blockquote>
        </Components.Feature.Container>
      </div>
      <Components.Common.OurTeamSection />
    </>
  );
};

export default TeamSectionHome;
