import assets from "../assets";

export const aiServicesData = [
  {
    icon: assets.aiServices.s_1,
    name: "Generative AI",
    description:
      "Unleash unparalleled creativity and innovation with our Generative AI services. Tap into the power of AI to generate novel designs, videos, content and ideas that can transform your brand and captivate your audience.",
  },

  {
    icon: assets.aiServices.s_2,
    name: "Chatbots and Conversational AI",
    description:
      "Enhance user engagement and streamline customer service with our advanced Chatbots and Conversational AI. Deliver instant, 24/7 support and personalised interactions that drive customer satisfaction and loyalty.",
  },

  {
    icon: assets.aiServices.s_3,
    name: "Computer Vision",
    description:
      "NLP involves the development of AI models for tasks such as text classification, sentiment analysis, language translation, and text generation. These services can be used for customer service chatbots, content moderation, and language-based data analysis.",
  },

  {
    icon: assets.aiServices.s_4,
    name: "Process Optimisation and Automation",
    description:
      "Elevate efficiency and productivity with our Process Optimisation and Automation solutions. Let our experts Analyse, simplify workflows, reduce operational costs, and free up valuable resources to focus on strategic growth initiatives.",
  },

  {
    icon: assets.aiServices.s_5,
    name: "Recommendation Engines",
    description:
      "Transform user experiences with our sophisticated Recommender Engines. Provide tailored recommendations that resonate with your customers, boosting sales and enhancing the personal touch that sets your service apart.",
  },

  {
    icon: assets.aiServices.s_6,
    name: "Custom AI Services",
    description:
      "Craft your success with Pixelette Technologies' Custom AI Services. Using our expertise in NLP, Deep Learning, RPA, Predictive Analytics and more, design and develop tailored solutions that meet your unique business challenges, streamline operations, and drive industry leadership.",
  },
];
