import assets from "../assets";

export const blockChainServicesData = [
  {
    icon: assets.blockChain.s_1,
    name: "Decentralised Finance (DeFi) Development",
    description:
      "Unlock financial innovation with our DeFi services, facilitating borderless, efficient, and secure financial operations.",
  },
  {
    icon: assets.blockChain.s_2,
    name: "Smart Contract Development",
    description:
      "Automate and secure your business agreements with our smart contract solutions that minimise disputes and enhance trust.",
  },
  {
    icon: assets.blockChain.s_3,
    name: "NFT Marketplace Creation",
    description:
      "Tap into the digital economy with our NFT marketplace development, opening new revenue streams through tokenisation of assets.",
  },
  {
    icon: assets.blockChain.s_4,
    name: "Tokenisation Services",
    description:
      "Transform your valuable assets into tradeable digital tokens, providing liquidity and expanding your market reach.",
  },
  {
    icon: assets.blockChain.s_5,
    name: "Custom Use Cases/Custom Development",
    description:
      "Bespoke blockchain solutions crafted for your unique challenges, ensuring your business stands out and leads innovation.",
  },
  {
    icon: assets.blockChain.s_6,
    name: "Blockchain for Compliance and Transparency",
    description:
      "Enhance your compliance posture and supply chain visibility, establishing trust with our blockchain traceability solutions.",
  },
  {
    icon: assets.blockChain.s_7,
    name: "Private Blockchain Networks",
    description:
      "Create secure and permissioned blockchain ecosystems for your enterprise, ensuring privacy and control over your internal processes.",
  },
  {
    icon: assets.blockChain.s_8,
    name: "Cryptocurrency Wallet Development",
    description:
      "Securely store and manage digital currencies with our custom wallet solutions, enhancing user experience and safety.",
  },
  {
    icon: assets.blockChain.s_9,
    name: "DApp Development:",
    description:
      "Deploy decentralised applications that are resilient, transparent, and which cut out the middleman, directly connecting service providers and users.",
  },
];
