import React, { useState } from "react";
import Components from "..";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const BlogGridWithBanner = (props) => {
  const blogCardsMain = props.data[0] || {}; // Fallback to an empty object if `props.data` is empty
  const [visibleCount, setVisibleCount] = useState(3); // Initial number of visible cards
  const blogCardsData = props.data.slice(1, visibleCount + 1);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3); // Increase the number of visible cards by 3
  };

  // Debugging logs
  console.log("Blog data:", props.data);

  // Safeguard against undefined or null properties
  const imageUrl = blogCardsMain.fields?.images?.fields?.file?.url;
  const mainBlogName = blogCardsMain.fields?.name || "No title available";
  const mainBlogDate = blogCardsMain.fields?.date || "No date available";
  const mainBlogDescription = blogCardsMain.fields?.shortDescription || "No description available";
  const mainBlogId = blogCardsMain.sys?.id || "No ID available";

  return (
    <div className="blogGridWithBanner">
      <Components.Feature.Container className="main margins">
        <div>
          <header>
            {imageUrl && (
              <figure id="blogGridWithBanner_heroImage">
                <img
                  src={imageUrl}
                  alt="Hero-image-for-blog"
                  data-aos="fade-up"
                  data-aos-duration="800"
                />
              </figure>
            )}
            <div>
              <Components.Feature.Text
                className="titory--bold"
                animation="fade-up"
                duration="500"
              >
                {mainBlogDate}
              </Components.Feature.Text>
              <Components.Feature.Heading
                className="titory"
                animation="fade-up"
                duration="600"
              >
                {mainBlogName}
              </Components.Feature.Heading>
              <Components.Feature.Text
                className="secondry"
                animation="fade-up"
                duration="700"
              >
                {mainBlogDescription}
              </Components.Feature.Text>
              <div className="text-button-container">
                <Link to={`/blog/${mainBlogId}`}>
                  <Components.Feature.Button
                    className="primary"
                  >
                    Read More
                  </Components.Feature.Button>
                </Link>
              </div>
            </div>
          </header>
          <div>
            {blogCardsData.map((el, index) => (
              el.fields ? (
                <Components.Feature.BlogCard
                  image={el.fields.images?.fields?.file?.url || "default-image-url"}
                  date={el.fields.date || "No date available"}
                  heading={el.fields.name || "No title available"}
                  description={el.fields.shortDescription || "No description available"}
                  to={el.sys?.id || mainBlogId}
                  key={el.sys?.id}
                  duration={`${index + 2}00`}
                  animation="fade-up"
                />
              ) : (
                <div key={uuidv4()}>Missing data for this blog card</div>
              )
            ))}
            {visibleCount < props.data.length && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "fit-content" }}>
                  <Components.Feature.Button className="primary" onClick={handleLoadMore}>
                    Load More
                  </Components.Feature.Button>
                </div>
              </div>
            )}
          </div>
        </div>

        {props.singleView && (
          <section>
            {props.data.map((el, index) => (
              el.fields ? (
                <Components.Feature.BlogCard
                  image={el.fields.images?.fields?.file?.url || "default-image-url"}
                  date={el.fields.date || "No date available"}
                  heading={el.fields.name || "No title available"}
                  description={el.fields.description || "No description available"}
                  to={el.sys?.id || "No ID available"}
                  key={uuidv4()}
                  duration={`${index + 2}00`}
                  animation="fade-up"
                />
              ) : (
                <div key={uuidv4()}>Missing data for this blog card</div>
              )
            ))}
          </section>
        )}
      </Components.Feature.Container>
    </div>
  );
};

export default BlogGridWithBanner;
