import React from "react";
import Components from "..";
import { Link } from "react-router-dom";
const BlogCard = (props) => {
  return (
    <div
      className="blogCard"
      data-aos={props.animation}
      data-aos-duration={props.duration}
    >
      <figure>
        <img src={props.image} alt="Blog-Profile" />
      </figure>
      <Components.Feature.Text className="titory--bold">
        {props.date}
      </Components.Feature.Text>
      <Components.Feature.Heading className="blogCardHeading">
        {props.heading}
      </Components.Feature.Heading>
      <Components.Feature.Text className="blogCardDescription">
        {props.description}
      </Components.Feature.Text>
      <Link to={`/blog/${props.to}`}>
        <Components.Feature.Button className="primary">
          Read More
        </Components.Feature.Button>
      </Link>
    </div>
  );
};

export default BlogCard;
