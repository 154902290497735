import { ErrorMessage, useField } from "formik";
import { useState } from "react";
const FormInput = ({ label, place, className, ...props }) => {
  const [field, meta] = useField(props);
  const [value, setValue] = useState("");
  return (
    <>
    {/* data-aos="fade-up" data-aos-duration="500" */}
      <div className="forminput" >
        <label
          htmlFor={field.name}
          className={`form-label ${value !== "" && "form-label-input-value"}`}
        >
          {label}
        </label>
        <input
          placeholder={place}
          type="text"
          className={`form-input ${className}   ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
          onChangeCapture={(e) => setValue(e.target.value)}
          style={{ border: meta.touched && meta.error && "1px solid red" }}
        />

        <ErrorMessage
          component="div"
          name={field.name}
          className="form-error"
        />
      </div>
    </>
  );
};

export default FormInput;
