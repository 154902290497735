import React from "react";
import Components from "../..";
import data from "../../../data";
import { v4 as uuidv4 } from "uuid";

const IntegrationMobile = () => {
  return (
    <>
      <div className="integrationMobile">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration={`400`}
            id="h_ani"
          >
            API Integration
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="fade-up"
            duration={`500`}
          >
            Pixelette Technologies facilitates seamless API integration,
            enabling smooth data exchange between software components and mobile
            apps.
          </Components.Feature.Text>
        </center>
        <Components.Feature.Container className="main margins">
          <section>
            <div data-aos="fade-up" data-aos-duration={`500`}>
              <Components.Feature.Heading
                className="blogCardHeading"
                animation="fade-up"
                duration={`600`}
              >
                Third Party Integration
              </Components.Feature.Heading>
              <section>
                <div>
                  {data.thirdPartyIntegrationData.slice(0, 3).map((el) => (
                    <Components.Feature.IntegrationItem
                      name={el.name}
                      icon={el.icon}
                      key={uuidv4()}
                    />
                  ))}
                </div>
                <div>
                  {data.thirdPartyIntegrationData.slice(3).map((el) => (
                    <Components.Feature.IntegrationItem
                      name={el.name}
                      icon={el.icon}
                      key={uuidv4()}
                    />
                  ))}
                </div>
              </section>
            </div>
            <div data-aos="fade-up" data-aos-duration={`600`}>
              <Components.Feature.Heading
                className="blogCardHeading"
                animation="fade-up"
                duration={`700`}
              >
                E-Commerce App Integrations
              </Components.Feature.Heading>
              <section>
                <div>
                  {data.crmIntegrationData.slice(0, 3).map((el) => (
                    <Components.Feature.IntegrationItem
                      name={el.name}
                      icon={el.icon}
                      key={uuidv4()}
                    />
                  ))}
                </div>
                <div>
                  {data.crmIntegrationData.slice(3).map((el) => (
                    <Components.Feature.IntegrationItem
                      name={el.name}
                      icon={el.icon}
                      key={uuidv4()}
                    />
                  ))}
                </div>
              </section>
            </div>
          </section>
          <blockquote data-aos="fade-up" data-aos-duration={`800`}>
            <Components.Feature.Heading
              className="blogCardHeading"
              animation="fade-up"
              duration={`600`}
            >
              CRM Integrations for Mobile App
            </Components.Feature.Heading>
            <section>
              <div>
                {data.ecommerApiIntegrationData.slice(0, 3).map((el) => (
                  <Components.Feature.IntegrationItem
                    name={el.name}
                    icon={el.icon}
                    key={uuidv4()}
                  />
                ))}
              </div>
              <div>
                {data.ecommerApiIntegrationData.slice(3, 6).map((el) => (
                  <Components.Feature.IntegrationItem
                    name={el.name}
                    icon={el.icon}
                    key={uuidv4()}
                  />
                ))}
              </div>
              <div>
                {data.ecommerApiIntegrationData.slice(6, 9).map((el) => (
                  <Components.Feature.IntegrationItem
                    name={el.name}
                    icon={el.icon}
                    key={uuidv4()}
                  />
                ))}
              </div>
              <div>
                {data.ecommerApiIntegrationData.slice(9, 12).map((el) => (
                  <Components.Feature.IntegrationItem
                    name={el.name}
                    icon={el.icon}
                    key={uuidv4()}
                  />
                ))}
              </div>
            </section>
          </blockquote>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default IntegrationMobile;
