import assets from "../assets";

export const caseSliderData = [
  {
    image: assets.home.caseSliderImage,
    blockChainLink: "/",
    uiDesignLink: "/",
    developmentLink: "/",
    heading: "Sandoz",
    description:
      "Centralized operations dashboard for Sandoz SA, projected to cut decision-making time by 35%. ",
  },
  {
    image: assets.home.caseSliderImage,
    blockChainLink: "/",
    uiDesignLink: "/",
    developmentLink: "/",
    heading: "Sandoz",
    description:
      "Centralized operations dashboard for Sandoz SA, projected to cut decision-making time by 35%. ",
  },
  {
    image: assets.home.caseSliderImage,
    blockChainLink: "/",
    uiDesignLink: "/",
    developmentLink: "/",
    heading: "Sandoz",
    description:
      "Centralized operations dashboard for Sandoz SA, projected to cut decision-making time by 35%. ",
  },
  {
    image: assets.home.caseSliderImage,
    blockChainLink: "/",
    uiDesignLink: "/",
    developmentLink: "/",
    heading: "Sandoz",
    description:
      "Centralized operations dashboard for Sandoz SA, projected to cut decision-making time by 35%. ",
  },
  {
    image: assets.home.caseSliderImage,
    blockChainLink: "/",
    uiDesignLink: "/",
    developmentLink: "/",
    heading: "Sandoz",
    description:
      "Centralized operations dashboard for Sandoz SA, projected to cut decision-making time by 35%. ",
  },
  {
    image: assets.home.caseSliderImage,
    blockChainLink: "/",
    uiDesignLink: "/",
    developmentLink: "/",
    heading: "Sandoz",
    description:
      "Centralized operations dashboard for Sandoz SA, projected to cut decision-making time by 35%. ",
  },
];
