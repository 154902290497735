import { routesData } from "./routesData";
import { navMenus } from "./navMenus";
import { ourServcesData } from "./ourServicesData";
import { yourPlanData } from "./yourPlanData";
import { caseSliderData } from "./caseSliderData";
import { testimonialData } from "./testimonialData";
import { swiperSliderData } from "./swiperSliderData";
import { uiProcessData } from "./uiProcessData";
import { millstoneListRight } from "./millstoneListRight";
import { millstoneListLeft } from "./millstoneListLeft";
import { whatMakeSpecialData } from "./whatMakeSpecialData";
import AwardWinning from "../components/common/AwardWinning"; // Updated

import { achievementData } from "./achievementData";
import { careerData } from "./careerData";
import { caseStudiesData } from "./caseStudiesData";
import { mapIndexData } from "./mapIndexData";

// what Make Special Data
import { arAndVrSpecialData } from "./whatMakeSpecial/arAndVrSpecialData";
import { awardWinningData } from "./whatMakeSpecial/AwardWinningData";

import { mobileSpecialData } from "./whatMakeSpecial/mobileSpecialData";
import { softwareSpecialData } from "./whatMakeSpecial/softwareSpecialData";
import { uiSpecialData } from "./whatMakeSpecial/uiSpecialData";
import { homeSpecialData } from "./whatMakeSpecial/homeSpecialData";
import { blockChainSpecialData } from "./whatMakeSpecial/blockChainSpecialData";
import { webSpecialData } from "./whatMakeSpecial/webSpecialData";

//services
import { aiServicesData } from "./aiServicesData";
import { blockChainServicesData } from "./blockChainServicesData";

// Technology Stack Data
import { technologyStackAiData } from "./technology/technologyStackAiData";
import { technologyBlockChainData } from "./technology/technologyStackBlockChainData";
import { technologyStackArandVrData } from "./technology/technologyStackArandVRData";
import { technologyStackWebDevelopmentData } from "./technology/technologyStackWebDevelopmentData";
import { technologyStackMobileData } from "./technology/technologyStackMobileData";
import { technologyStackUI } from "./technology/technologyStackUI";
import { technologyStackSoftware } from "./technology/technologyStackSoftware";

// Expertise Data
import { expertiseAiData } from "./expertise/expertiseAiData";
import { expertiseArData } from "./expertise/expertiseArData";
import { expertiseAiChangeData } from "./expertise/expertiseAiChangeData";
import { expertiseWebData } from "./expertise/expertiseWebData";
import { expertiseSoftwareData } from "./expertise/expertiseSoftwareData";

// faqs Data
import { aiFaq } from "./faqs/aiFaqs";
import { arFaqs } from "./faqs/arFaqs";
import { webFaqs } from "./faqs/webFaqs";
import { mobileFaqs } from "./faqs/mobileFaqs";
import { blockFaqs } from "./faqs/blockFaqs";
import { uiFaqs } from "./faqs/uiFaqs";
import { staffFaqs } from "./faqs/staffFaqs";
import { softwareFaqs } from "./faqs/softwareFaqs";
import { clFaqs } from "./faqs/clFaqs";
import { deliverFaqs } from "./faqs/deliverFaqs";
import { deployFaqs } from "./faqs/deployFaqs";
import { supplementFaqs } from "./faqs/supplimentFaqs";

// validation data
import { validationContactUs } from "./validations/validationContactUs";
import { validationResearchInquiry } from "./validations/validationResearchInquiry";
import { validationStartUps } from "./validations/validationStartUps";
import { validationJobApply } from "./validations/validationJobApply";

// Policies
import { privacyPolicy } from "./policies/privacyPolicy";
import { termAndConditions } from "./policies/termAndConditions";
import { refundPolicy } from "./policies/refundPolicy";

// Services Data
import { webDevelopmentServicesData } from "./servicesData/webDevelopmentServicesData";
import { arAndVRServicesData } from "./servicesData/arAndVRServicesData";
import { softwareServicesData } from "./servicesData/softwareServicesData";
import { digitalServicesData } from "./servicesData/digitalServicesData";
import { staffServicesData } from "./servicesData/staffServicesData";
import { mobileServicesData } from "./servicesData/mobileServicesData";
import { uiServicesData } from "./servicesData/uiServicesData";
import { aboutServicesData } from "./servicesData/aboutServicesData";
import { transformtechData } from "./servicesData/transformtechData";
import awardsData from "./awardcard/awardcardData";

// Development Process
import { arProcessData } from "./developmentProcess/arProcessData";

// Integration Data
import { ecommerApiIntegrationData } from "./integrationData/ecommerApiIntegrationData";
import { thirdPartyIntegrationData } from "./integrationData/thirdPartyIntegrationData";
import { crmIntegrationData } from "./integrationData/crmIntegrationData";

// Old Imports
import { customerReviews } from "./customersReviews";
import { teamData } from "./teamData";
import { aboutTeamData } from "./aboutTeamData";
import { chartData } from "./chartData";
import { shippingData } from "./shippingData";
import { blogsData } from "./blogsData";

const data = {
  navMenus,
  ourServcesData,
  yourPlanData,
  caseSliderData,
  teamData,
  aboutTeamData,
  blogsData,
  testimonialData,
  swiperSliderData,
  aiServicesData,
  whatMakeSpecialData,
  AwardWinning,
  achievementData,
  careerData,
  blockChainServicesData,
  uiProcessData,
  millstoneListLeft,
  millstoneListRight,
  caseStudiesData,
  mapIndexData,

  // faqs Data
  aiFaq,
  arFaqs,
  deliverFaqs,
  deployFaqs,
  supplementFaqs,
  webFaqs,
  mobileFaqs,
  blockFaqs,
  uiFaqs,
  staffFaqs,
  softwareFaqs,
  clFaqs,

  // expertise
  expertiseArData,
  expertiseAiData,
  expertiseAiChangeData,
  expertiseWebData,
  expertiseSoftwareData,

  // what make Special
  arAndVrSpecialData,
  awardWinningData,
  mobileSpecialData,
  softwareSpecialData,
  uiSpecialData,
  homeSpecialData,
  blockChainSpecialData,
  webSpecialData,

  // validation
  validationResearchInquiry,
  validationContactUs,
  validationStartUps,
  validationJobApply,

  // Integration
  ecommerApiIntegrationData,
  thirdPartyIntegrationData,
  crmIntegrationData,

  // Development Process
  arProcessData,

  // Technology Stack
  technologyBlockChainData,
  technologyStackAiData,
  technologyStackArandVrData,
  technologyStackWebDevelopmentData,
  technologyStackMobileData,
  technologyStackUI,
  technologyStackSoftware,

  // routesData,
  routesData,

  // policies
  privacyPolicy,
  termAndConditions,
  refundPolicy,

  // servicesData
  arAndVRServicesData,
  webDevelopmentServicesData,
  softwareServicesData,
  digitalServicesData,
  staffServicesData,
  mobileServicesData,
  uiServicesData,
  aboutServicesData,
  transformtechData,
  awardsData,

  // Old Imports
  customerReviews,
  chartData,
  shippingData,
};
export default data;
