import "../style/Timeline.css";
import Icon from "../assets/icon.png";

const data = [
  {
    name: "August 2018:",
    content:
      "Pixelette Technologies is founded in London, UK by Mr. Asif Ashiq Rana, focusing initially on marketing and software development to support Mr. Rana’s existing portfolio companies",
    direction: "right",
  },
  {
    name: "January 2019:",
    content: "Pixelette Technologies opens up for commercial activity.",
    direction: "left",
  },
  {
    name: "April 2019:",
    content:
      "Pixelette Technologies enters the blockchain sphere, inspired by the potential of decentralisation.",
    direction: "right",
  },
  {
    name: "June 2019:",
    content:
      "Our first major blockchain project is launched, marking our entry into innovative technology solutions.",
    direction: "left",
  },
  {
    name: "December 2019:",
    content:
      "Sister Company Recognised as a Think Tank Leader at the APPG AI at the British Parliament",
    direction: "right",
  },
  {
    name: "January 2020:",
    content:
      "Transition into artificial intelligence development, starting with projects aimed at enhancing scalability and performance",
    direction: "left",
  },
  {
    name: "March 2021:",
    content: "Pixelette Technologies opens operations in 4 countries.",
    direction: "right",
  },
  {
    name: "June 2022:",
    content:
      "Sister Company Attains CPD accreditation, underscoring our commitment to professional development and excellence.",
    direction: "left",
  },
  {
    name: "January 2023:",
    content:
      "Achieved recognition as a Top AI and Blockchain Company in the UK, affirming our position on the global tech stage.",
    direction: "right",
  },
  {
    name: "March 2023:",
    content:
      " Named the Most Reviewed Company in the UK, reflecting our widespread approval and excellent client feedback.",
    direction: "left",
  },
  {
    name: "December 2023",
    content:
      "Pixelette Technologies establishes an equity partnership with BlockGuard to launch a massive web 3 ecosystem.",
    direction: "right",
  },
  {
    name: "April 2024: ",
    content:
      "Recognised to be in the top 30 software development companies worldwide.",
    direction: "left",
  },
  {
    name: "May 2024:",
    content: " Pixelette Technologies expands to 13 countries worldwide.",
    direction: "right",
  },
];

const Timeline = () => {
  return (
    <div className="timeline scrollable">
      {data.map(({ name, content, direction }, index) => (
        <div key={index} className={`container ${direction}-Container`}>
          <img src={Icon} alt="logo" />
          <div className="line"></div> 
          <div className="text-box">
            <h2>{name}</h2>
            <p>{content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;

