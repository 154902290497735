import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Components from "./components";
import data from "./data";
import Layout from "./Layout";
// import RefreshOnMobile from "./components/ui/mobile/RefreshOnMobile";

const Routess = () => {
  const location = useLocation();

  const isClutchRoute = location.pathname === "/clutch";
  const isIdiyasRoute = location.pathname === "/idiyas";

  return (
    <>
      {!isClutchRoute && !isIdiyasRoute && <Components.Common.Navbar />}
      <Routes>
        {data.routesData.map((el) => {
          const Element = Layout(el.element, el.title);
          return <Route key={el.path} path={el.path} element={<Element />} />;
        })}
      </Routes>
      {!isClutchRoute && !isIdiyasRoute && <Components.Common.Footer />}
    </>
  );
};

const App = () => (
  <Router>
    <ScrollToTop />
    <Routess />
  </Router>
);

export default App;
