import assets from "../../assets";

export const webDevelopmentServicesData = [
  {
    icon: assets.webDevelopment.s_1,
    name: "Custom Website Design",
    description:
      "Tailor your online presence with fully customised websites designed to meet the unique needs of your business. Our bespoke solutions ensure that every aspect of your site aligns with your brand identity and business objectives.",
  },
  {
    icon: assets.webDevelopment.s_2,
    name: "E-commerce Development",
    description:
      "Launch and scale your online store with our expert e-commerce solutions. We specialise in creating secure, intuitive, and engaging shopping experiences that drive sales and grow your customer base.",
  },
  {
    icon: assets.webDevelopment.s_3,
    name: "Content Management Systems (CMS)",
    description:
      "Empower your team with user-friendly CMS platforms that facilitate seamless content updates and management. Whether it's WordPress, Joomla, or a custom solution, we help you keep your website dynamic and current.",
  },
  {
    icon: assets.webDevelopment.s_4,
    name: "User Experience (UX) and User Interface (UI) Design",
    description:
      "Enhance user satisfaction with cutting-edge UX/UI design. Our designs are focused on usability and aesthetics, ensuring that your website is not only visually appealing but also easy to navigate and interact with.",
  },
  {
    icon: assets.webDevelopment.s_5,
    name: "Support and Maintenance",
    description:
      "Keep your website in top condition with our ongoing support and maintenance services. From regular updates to troubleshooting, we ensure your website performs optimally at all times.",
  },
  {
    icon: assets.webDevelopment.s_6,
    name: "Web Application Development",
    description:
      "Develop powerful web applications tailored to streamline your business processes. Our developers create solutions that are robust, scalable, and capable of handling complex functionalities.",
  },
];
