import assets from "../../assets";

const awardsData = [
  {
    id: 1,
    src: assets.awards.clclutch, // Adjust the path as needed
    alt: "Top AI Development Company",
  },
  {
    id: 2,
    src: assets.awards.clblockchain, // Adjust the path as needed
    alt: "Top Blockchain Company ",
  },
  {
    id: 3,
    src: assets.awards.clclutch2, // Adjust the path as needed
    alt: "Top Blockchain Company ",
  },
  {
    id: 4,
    src: assets.awards.clcomputervision, // Adjust the path as needed
    alt: "Top Generative AI Company ",
  },
  {
    id: 5,
    src: assets.awards.claicompany, // Adjust the path as needed
    alt: "Top Generative AI Company ",
  },
];

export default awardsData;
