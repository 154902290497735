import React from "react";
import Components from "../components";
import data from "../data";

const WebDevelopment = () => {
  return (
    <>
      <Components.UI.HeroSectionWebDevelopment />
      <Components.Common.OurClients />
      <Components.UI.ServicesWebDevelopment />
      {/* <Components.UI.WebProcess /> */}
      <Components.UI.TechnologyStackWebDevelopment />
      <Components.Common.WhatMakesUsSpecial data={data.webSpecialData} />
      <Components.Common.UnlockBusinessPotential
        heading="Discover How Our Web Solutions Can Transform Your Business"
        text="Ready to elevate your digital presence? Schedule your free consultation with our web development experts today and start crafting your future online success."
        btnText="Let's Build Something Great!"
      />
      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.CaseStudies
        heading="Success in Action: Our Web Development Case Studies"
        text="Explore our portfolio of web development successes: real-world examples of how our custom web solutions have propelled businesses forward, showcasing tangible results and transformative impacts."
      />
      {/* <Components.UI.ExpertiseWebDevelopment /> */}
      <Components.Common.ExpertiseGrid
        data={data.expertiseWebData}
        heading="Expertise Across Industries: Custom Web Development Solutions"
        text="From retail to healthcare, our bespoke web solutions drive industry-specific success, enhancing digital presence and user engagement effectively."
      />
      <Components.Common.Partners />
      {/* <Components.Common.AchievementsSection /> */}
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection data={data.webFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default WebDevelopment;
