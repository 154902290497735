import React from "react";
import assets from "../../assets";
import { Link } from "react-scroll";
import Components from "..";

const UnlockBusinessPotential = (props) => {
  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="unlockBussiness" animation="fade-up" duration="500">
          <div>
            <Components.Feature.Heading className="primary">
              {props.heading}
            </Components.Feature.Heading>
            <Components.Feature.Text className="secondry">
              {props.text}
            </Components.Feature.Text>
            <section>
              <a
                href="https://www.instagram.com/pixelettetechnologies/"
                target="blank"
              >
                <img
                  src={assets.commonAssests.insta}
                  alt="icon"
                  data-aos-duration={`700`}
                  data-aos="fade-up"
                />
              </a>
              <a
                href="https://www.facebook.com/pixelette.technologies"
                target="blank"
              >
                <img
                  src={assets.commonAssests.facebook}
                  alt="icon"
                  data-aos-duration={`800`}
                  data-aos="fade-up"
                />
              </a>
              <a
                href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGGDTDsms_blwAAAY_ktSG4-AA1A5q4aprWErdOzwSQpAh7RFRd0vT0nwi8MeWoP3QK_xG2y9TkZb78Ct5WQF5n6KyaKGlohehIXsDYju-Ka854EJZEW-CIo81B9GVoawow5Us=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fpixelettetechnologies%2F"
                target="blank"
              >
                <img
                  src={assets.commonAssests.linkedIn}
                  alt="icon"
                  data-aos-duration={`900`}
                  data-aos="fade-up"
                />
              </a>
              <a href="https://twitter.com/Pixelettetech1" target="blank">
                <img
                  src={assets.commonAssests.twitter}
                  alt="icon"
                  data-aos-duration={`1000`}
                  data-aos="fade-up"
                />
              </a>
            </section>
          </div>
          <Link to="contactUs" smooth={true} duration={500}>
            <Components.Feature.Button className="primary">
              {props.btnText}
            </Components.Feature.Button>
          </Link>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default UnlockBusinessPotential;
