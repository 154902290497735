import React from "react";
import Components from "../components";
import data from "../data";

const Software = () => {
  return (
    <>
      <Components.UI.HeroSectionSoftware />
      <Components.Common.OurClients />
      <Components.UI.ServicesSoftware />
      {/* <Components.UI.SoftwareProcess /> */}
      <Components.Common.TechnologyGrid
        heading="Empowering Your Vision with Our Cutting-Edge Technology Stack"
        text="At Pixelette Technologies, we leverage a dynamic blend of the industry's top technologies to craft bespoke software solutions that propel your business forward. Our tech stack includes:"
        data={data.technologyStackSoftware}
      />
      <Components.Common.WhatMakesUsSpecial
        heading="What Sets Us Apart in Custom Software Development?"
        text="Pixelette Technologies is the custom software development partner you can trust for exceptional solutions. Here's why clients across various sectors choose us:"
        data={data.softwareSpecialData}
      />
      <Components.Common.UnlockBusinessPotential
        heading="Let's Solve Your Challenges"
        text="Ready to transform your business challenges into bespoke solutions? Schedule your free consultation with our software experts today and begin your journey towards innovation and efficiency."
        btnText="Schedule a Consultation"
      />
      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.CaseStudies
        heading="Success in Action: Our Custom Software Case Studies"
        text="Explore our Custom Software Success Stories: real-world examples of how our tailored solutions have driven business transformation, showcasing tangible results and significant impacts."
      />
      <Components.Common.ExpertiseGrid
        data={data.expertiseSoftwareData}
        heading="Expertise Across Industries: Tailored Software Solutions"
        text="Spanning Sectors: From healthcare to finance, our proficiency in crafting custom software solutions is driving transformation across diverse industries with precision-engineered outcomes."
      />
      <Components.Common.Partners />
      {/* <Components.UI.ExpertiseSoftware />
      <Components.Common.AchievementsSection /> */}
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection data={data.softwareFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default Software;
