import React from "react";
import { Link } from "react-router-dom";
import assets from "../../assets";
import Components from "..";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  return (
    <>
      <footer>
        <img src={assets.home.footerBackground} alt="background" />
        <Components.Feature.Container className="main margins">
          <blockquote>
            <img src={assets.home.box_21} alt="box" />
            <img src={assets.home.box_22} alt="box" />
          </blockquote>
          <section>
            <div>
              <img
                src={assets.commonAssests.logo}
                alt="logo"
                data-aos="flip-left"
                data-aos-duration="1000"
              />

              <div>
                <a href="https://www.instagram.com/pixelettetechnologies/" target="blank">
                  <img
                    src={assets.commonAssests.insta}
                    alt="icon"
                    data-aos-duration={`700`}
                    data-aos="fade-up"
                  />
                </a>
                <a href="https://www.facebook.com/pixelette.technologies" target="blank">
                  <img
                    src={assets.commonAssests.facebook}
                    alt="icon"
                    data-aos-duration={`800`}
                    data-aos="fade-up"
                  />
                </a>
                <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGGDTDsms_blwAAAY_ktSG4-AA1A5q4aprWErdOzwSQpAh7RFRd0vT0nwi8MeWoP3QK_xG2y9TkZb78Ct5WQF5n6KyaKGlohehIXsDYju-Ka854EJZEW-CIo81B9GVoawow5Us=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fpixelettetechnologies%2F" target="blank">
                  <img
                    src={assets.commonAssests.linkedIn}
                    alt="icon"
                    data-aos-duration={`900`}
                    data-aos="fade-up"
                  />
                </a>
                <a href="https://twitter.com/Pixelettetech1" target="blank">
                  <img
                    src={assets.commonAssests.twitter}
                    alt="icon"
                    data-aos-duration={`1000`}
                    data-aos="fade-up"
                  />
                </a>
              </div>
            </div>
            <div>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="primary--bold"
              >
                Quicks Links
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="about-us">About Us</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="case-studies">Case Studies</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="pixelette-research">Pixelette Research</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="start-ups">Start-Ups</Link>
              </Components.Feature.Text>
            </div>

            <div>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="primary--bold"
              >
               Company Info
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="blogs">Blogs</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="privacy-policy">Privacy Policy</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="term-conditions">Terms & Conditions</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="refund-policy">Cancellation & Refund Policy</Link>
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <Link to="careers">Careers</Link>
              </Components.Feature.Text>
            </div>

            <div>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="primary--bold"
              >
                Contact Us
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <MdEmail />
                sales@pixelettetech.com
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <FaPhone />
                +44 2045188226
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <FaPhone />
                +1 7732709034
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <HiLocationMarker />
                71-75 Shelton Street, WC2 H9J, London, UK (Headquarter)
              </Components.Feature.Text>
              <Components.Feature.Text
                duration={`800`}
                animation="fade-up"
                className="titory--bold"
              >
                <HiLocationMarker />
                2335. W. Devon, Ave, IL 6065, Chicago, USA (Headquarter)
              </Components.Feature.Text>
            </div>
          </section>
          <center>
            <Components.Feature.Text className="titory--bold">
              Copyright © 2023 Pixelette Technologies | All Rights Reserved
            </Components.Feature.Text>
          </center>
        </Components.Feature.Container>
      </footer>
    </>
  );
};

export default Footer;
