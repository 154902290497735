import Components from "../..";
import assets from "../../../assets";
import data from "../../../data";

const YourPlan = () => {
  return (
    <div className="ourServices">
      <Components.Feature.Container className="main margins">
        <blockquote>
          <img src={assets.home.box_10} alt="box" />
          <img src={assets.home.box_11} alt="box" />
        </blockquote>
      </Components.Feature.Container>
      <Components.Common.YourPlanCardGrid
        heading="Your Plan, Your Way"
        text="Every project is unique, and so are our solutions. All you’ve got to do is select a plan that best aligns with your goals, let us know and we’ll help bring your project to life."
        data={data.yourPlanData}
      />
    </div>
  );
};

export default YourPlan;
