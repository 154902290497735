import React from "react";
import Components from "../components";
import data from "../data";
import assets from "../assets";

const Deploy = () => {
  return (
    <>
      <Components.Common.HeroSectionAiServices
      title="Precision-Driven Teams, Ready to Deploy"
      description="A team that’s all in on your project, next to you, from start to finish."
      cardtitle="How It Works?"
      carddescription1="Our dedicated teams service provides you with a fully assembled, highly skilled team of developers, ready to integrate with your existing processes and drive your project forward."
      carddescription2="We build a dedicated development team based on your project’s specific requirements. This team is fully committed to your project, working under your direction and aligning with your goals. We handle the formation and management of the team, ensuring it’s tailored to meet your objectives."
      carddescription3="Clients can expect faster development cycles, higher efficiency, and a more focused approach to project delivery, with a team that’s fully invested in your success."
      cardimage={assets.home.deliver3}
       />
      <Components.Common.DeliverBenifits
      title=<p>Benefits of Opting for <br/> Dedicated Teams</p>
      title1="Faster Time to Market"
      description1="You can accelerate development with a ready-to-go team that integrates quickly"
      title2="Focused Expertise"
      description2="Your project gets a fully focused team, ensuring commitment to the building process"
      title3="Consistency"
      description3="You work with the same team throughout the project, ensuring continuity and deep understanding"
      title4="Custom Fit"
      description4="You may tailor the team to your project’s specific needs, ensuring the right mix of skills"
       />
      <Components.Common.SelectPlan />
      <Components.Common.ServiceWork />
      <Components.Common.DeployFaqs />
      {/* <Components.Common.ContactUs /> */}
      {/* <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default Deploy;




