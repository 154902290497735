import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesBlockChain = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Blockchain Services and Solutions"
        text="Pixelette Technologies unlocks blockchain potential with tailored services and dedicated expertise, setting new standards for innovation and success."
        data={data.blockChainServicesData}
      />
    </>
  );
};

export default ServicesBlockChain;
