export const whatMakeSpecialData = [
  {
    heading: "Government Endorsement",
    text: "AI leaders by decree: Chairs of the UK Government’s AI Task Force – APPG AI. Also working with 7 other governments worldwide",
  },
  {
    heading: "World-Class Team",
    text: "Elite minds at work: Over 200 staff, 90% in the top 5% of global AI expertise, with senior management in the top 1%",
  },
  {
    heading: "Extensive Expertise & Worldwide Presence",
    text: "Elevating tech landscapes: 200+ projects, 30,000+ hours of development. Global reach, local genius: Active in 13 countries",
  },
  {
    heading: "Proven Case Studies",
    text: "Transforming industry giants: Our AI boosts efficiency for McDonald’s, Lytics, MAF Holdings, Sandoz and more",
  },
  {
    heading: "Cost Effective",
    text: "Maximise value, minimise cost: Streamlined AI solutions without the premium price tag ",
  },
  {
    heading: "Unmatched Success",
    text: "UK’s AI pacesetter 2023 and 2024: A legacy of groundbreaking achievements",
  },
];
