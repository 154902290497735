import assets from "../../assets";

export const ecommerApiIntegrationData = [
  {
    icon: assets.mobileDevelopment.a_13,
    name: "Amazon API",
  },
  {
    icon: assets.mobileDevelopment.a_14,
    name: "Walmart API",
  },
  {
    icon: assets.mobileDevelopment.a_15,
    name: "Lightspeed API",
  },
  {
    icon: assets.mobileDevelopment.a_16,
    name: "Shopify API",
  },
  {
    icon: assets.mobileDevelopment.a_17,
    name: "BigCommerce API",
  },
  {
    icon: assets.mobileDevelopment.a_18,
    name: "3dcart API",
  },
  {
    icon: assets.mobileDevelopment.a_19,
    name: "Magento API",
  },
  {
    icon: assets.mobileDevelopment.a_20,
    name: "Squarespace API",
  },
  {
    icon: assets.mobileDevelopment.a_21,
    name: "Neto API",
  },
  {
    icon: assets.mobileDevelopment.a_22,
    name: "WooCommerce API",
  },
  {
    icon: assets.mobileDevelopment.a_23,
    name: "CommerceHQ API",
  },
  {
    icon: assets.mobileDevelopment.a_24,
    name: "eBay API",
  },
];
