import React from "react";
import Components from "../components";

const StartUps = () => {
  return (
    <>
      <Components.UI.HeroSectionMarketing />
      
      <Components.UI.StartUpsHelpForm />
      <Components.Common.LetsTalkSection />
      {/* <Components.Common.LetsTalkSection /> */}
    </>
  );
};

export default StartUps;
