import assets from "../assets";
// import LogoCard from "../assets/common/LogoCards.png"

export const yourPlanData = [
  {
    icon: assets.home.y_1,
    iconhead: "Deliver",
    name: "Complete Outsourcing",
    path: "deliver",
    description:
      "Entrust us with the entire process, from concept to completion, through our comprehensive outsourcing services.",
    link: 'Learn more',
    linkIcon: assets.home.arrow_top_right,
  },
  {
    icon: assets.home.y_2,
    iconhead: "Supplement",
    name: "Staff Augmentation",
    path: "supplement",
    description:
      "Enhance your team effortlessly by utilising our talent pool, cutting down on the time spent on sourcing, recruiting, and training.",
    link: 'Learn more',
    linkIcon: assets.home.arrow_top_right,
  },
  {
    icon: assets.home.y_3,
    iconhead: "Deploy",
    name: "Dedicated Teams",
    path: "deploy",
    description:
      "Boost your development timeline by hiring our fully functional and expert teams, fitting into your current workflow.",
    link: 'Learn more',
    linkIcon: assets.home.arrow_top_right,
  }
];
