import React from "react";
import Components from "../../components";
import assets from "../../assets";
import { Link } from "react-scroll";
import data from "../../data";
const HeroSectionAiServices = (props) => {

  const imageStyle = {
    width: '100px', // Adjust the width as needed
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover', // Ensure the image covers the entire area without stretching
    borderRadius: '8px', // Optional: Add border radius to the images
  };

  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="heroSectionAiServices-background">
          <img src={assets.aiServices.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div id="heroSectionAiServices" className="heroSectionAiServices">
        <Components.Feature.Container className="main margins">
          
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              {props.title}
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              {props.description}
            </Components.Feature.Text>
            
          </center>
          <section>
          <div className="deliverHeroCard">
            <section>
              <h1>{props.cardtitle}</h1>
              <p>{props.carddescription1}</p>
              <p>{props.carddescription2}</p>
              <p>{props.carddescription3}</p>
            </section>
              <img src={props.cardimage} alt="" />
          </div>          
        </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionAiServices;
