import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const SpecialHome = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="specialHome-background">
          <img src={assets.home.specialHome} alt="background" />
        </div>
      </Components.Feature.Container>

      <div className="specialHome">
        <Components.Feature.Container className="main ">
          <blockquote>
            <img src={assets.home.box_25} alt="box" />
          </blockquote>
        </Components.Feature.Container>
      </div>
      <Components.Common.WhatMakesUsSpecial
        data={data.homeSpecialData}
        text="Pixelette Technologies is the tech partner you need for real-world results. Here’s why our partners and clients love working with us:"
      />
    </>
  );
};

export default SpecialHome;
