import assets from "../../assets";

export const crmIntegrationData = [
  {
    icon: assets.mobileDevelopment.a_7,
    name: "Salesforce  API",
  },
  {
    icon: assets.mobileDevelopment.a_8,
    name: "HubSpot CRM API",
  },
  {
    icon: assets.mobileDevelopment.a_9,
    name: "Close.io API",
  },
  {
    icon: assets.mobileDevelopment.a_10,
    name: "Zoho CRM API",
  },
  {
    icon: assets.mobileDevelopment.a_11,
    name: "Copper CRM API",
  },
  {
    icon: assets.mobileDevelopment.a_12,
    name: "Insightly API",
  },
];
