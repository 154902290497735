import React from "react";
import Components from "..";
import { v4 as uuidv4 } from "uuid";
const technologyGrid = (props) => {
  return (
    <>
      <div className="technologyStack">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="600"
            id="h_ani"
          >
            {props.heading}
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="fade-up"
            duration="700"
          >
            {props.text}
          </Components.Feature.Text>
        </center>
        <Components.Feature.Container className="main margins">
          <div className="margins">
            {props.data.map((item, index) => (
              <div key={uuidv4()} data-aos="fade-up" data-aos-duration={`600`}>
                <img src={item} alt="icon" />
              </div>
            ))}
          </div>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default technologyGrid;
