import React from "react";
import Components from "../..";

const ArProcess = () => {
  return (
    <>
      <Components.Common.FaqsSection
        heading="VR Development Process"
        text="Here is a look at what our VR Development process looks like. However, should the need arise, our development process may be subject to change."
      />
    </>
  );
};

export default ArProcess;
