import React from "react";
import Components from "../..";
import { Formik, Form } from "formik";
import data from "../../../data";

const ApplicantForm = () => {
  const initalData = {
    name: "",
    email: "",
    phone: "",
    currentSalary: "",
    desiredSalary: "",
    totalYearsOfExperience: "",
    description: "",
  };
  const dummyValues = ["One", "Tow", "Three", "Four"];

  return (
    <>
      <div className="applicantForm">
        <section>
          <Components.Feature.Heading className="policyHeading">
            Apply for this position
          </Components.Feature.Heading>

          <Formik
            initialValues={initalData}
            validateOnMount
            validationSchema={data.validationJobApply}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              resetForm();
            }}
          >
            {(formik) => (
              <Form>
                <blockquote className="applicantForm-container">
                  <Components.Feature.FormInput
                    name="name"
                    label="Full Name"
                    place="Enter your full name"
                  />
                  <Components.Feature.FormInput
                    name="email"
                    label="Email"
                    place="Enter your email"
                  />
                  <Components.Feature.InputPhoneNo
                    name="phone"
                    label="Phone Number"
                    place="+1 (555) 000-0000"
                  />
                  <Components.Feature.FormInput
                    name="currentSalary"
                    label="Current Salary"
                    place="Enter current salary"
                  />
                  <Components.Feature.FormInput
                    name="desiredSalary"
                    label="Desired Salary"
                    place="Enter desired salary"
                  />
                  <Components.Feature.FormInputWithDropDown
                    name="totalYearsOfExperience"
                    label="Total years of experience"
                    place="Less than a year"
                    data={dummyValues}
                  />
                  <Components.Feature.FromTextArea
                    name="description"
                    label="Description"
                    place="Tell us more about you..."
                    rows="7"
                    cols="30"
                  />
               
                  <Components.Feature.Button
                    className="primary"
                    animation="fade-up"
                    duration="700"
                  >
                    Submit
                  </Components.Feature.Button>
                </blockquote>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </>
  );
};

export default ApplicantForm;
