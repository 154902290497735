import React from "react";
import Components from "..";

const TransformConceptSection = () => {
  return (
    <Components.Feature.Container className="main margins">
      <div
        className="transformConceptSection"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <Components.Feature.Heading className="primary">
          Transform your concept <br /> into a success
        </Components.Feature.Heading>
        <Components.Feature.Button className="primary">
          Let’s Talk
        </Components.Feature.Button>
      </div>
    </Components.Feature.Container>
  );
};

export default TransformConceptSection;
