import React from "react";
import Components from "..";
import NewServiceCard from "../feature/NewServiceCard";
import { YourPlan } from "../ui";

const YourPlanCardGrid = (props) => {
  return (
    <>
      <div className="yourPlanCardGrid">
        <Components.Feature.Container className="main margins">
          <left>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="300"
              id="h_ani"
            >
              {props.heading}
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="500"
            >
              {props.text}
            </Components.Feature.Text>
          </left>
          <section>
          <Components.Feature.YourPlanCard data={props.data} />
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default YourPlanCardGrid;
