import React from "react";
import Components from "../components";
import data from "../data";
import assets from "../assets";

const TermAndConditions = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="terms-background">
          <img src={assets.policies.termsBackGround} alt="background" />
        </div>
      </Components.Feature.Container>
      <Components.Feature.Container className="main margins">
        <div className="terms">
          <center>
            <Components.Feature.Heading className="heroHeading">
              Terms & Conditions
            </Components.Feature.Heading>
            <Components.Feature.Text className="secondry">
              Before using Pixelette Technologies’ services and solutions, it is
              highly important to go through our company’s terms and conditions.
              A reminder: Pixelette Technologies will not be responsible if our
              customers fail to go through our terms & conditions.
            </Components.Feature.Text>
          </center>
          <Components.Common.DetailsNavigate
            data={data.termAndConditions}
            headingIndex={true}
            overViewIndex={true}
            headerSection={false}
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default TermAndConditions;
