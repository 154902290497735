import Components from "..";
import { TypeAnimation } from "react-type-animation";

const ClutchForm = () => {
  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="evaluateBussiness">
          <header>
            <Components.Feature.Heading
              className="secondry"
              style={{ fontSize: "25rem", color: "yellow " }}
            >
              We&apos;ll bring your <br />
              <TypeAnimation
                className="red-color"
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "WEB3",
                  2500, // wait 1s before replacing "Mice" with "Hamsters"
                  "AI",
                  2500,
                  "APP",
                  2500,
                ]}
                wrapper="span"
                deletionSpeed={20}
                speed={20}
                style={{ display: "inline-block" }}
                repeat={Infinity}
              />
              idea to life or you
              <br />
              get your money back
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="700"
            >
              Pixelette Technologies empowers non-technical founders to get
              software solutions to their exact requirements without hiring
              expensive in-house teams
              <br />
              <br />
              <ul className="custom-list">
                <li>
                  Get on a call to chat about your project scope, or we&apos;ll
                  make it up as we go
                </li>
                <li>
                  Focus on your vision while we handle the technical details
                </li>
                <li>
                  Stay in the loop to provide feedback for a platform your users
                  will love
                </li>
                <li>
                  Receive your completed project ahead of schedule and go to
                  market
                </li>
              </ul>
            </Components.Feature.Text>
          </header>
          <div>
            <Components.Common.ContactUs
              header={true}
              backgrounds={true}
              insideHeading="Book a Discovery Session"
            />
          </div>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default ClutchForm;
