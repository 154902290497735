export const webFaqs = [
  {
    question: "What types of web development services do you offer?",
    answer:
      "We offer a wide range of web development services, including website design, e-commerce development, responsive web design, content management systems and custom web development. Contact us for a complete list of our web development services.",
  },
  {
    question: "How much does a website cost?",
    answer:
      "The cost of a website can vary depending on the complexity of the project and the specific needs of the client. We offer competitive pricing and work closely with our clients to create solutions that fit their budget.",
  },
  {
    question: "How long does it take to build a website?",
    answer:
      "The timeline for building a website can vary depending on the complexity of the project and the client’s requirements. We work closely with our clients to establish a clear timeline and ensure that the project is delivered on time.",
  },
  {
    question: "Do you provide ongoing support and maintenance for websites?",
    answer:
      "Yes, we provide ongoing support and maintenance for websites to ensure that they are always up to date and running smoothly. We are always here to help and answer any questions you may have.",
  },
  {
    question: "Do you design mobile-responsive websites?",
    answer:
      "Yes, we understand the importance of having a website that looks great and works well on all devices. That is why we build responsive websites that adapt to the screen size of the device they are being viewed on. This ensures that your website is accessible to all users, regardless of the device they are using.",
  },
  {
    question: "How do you ensure the security of my website?",
    answer:
      "We take website security very seriously. We use industry-standard security measures to protect your website from potential threats. This includes regular updates, backups, and monitoring of your website to ensure that it is always secure.",
  },
  {
    question: "Can you integrate my website with other systems and platforms?",
    answer:
      "Yes, we have experience integrating websites with a variety of systems and platforms, including e-commerce platforms, social media, and marketing automation tools.",
  },
  {
    question: "Do you work with clients from different industries?",
    answer:
      "Yes, we have experience working with clients from a variety of industries, including retail, healthcare, education, and more. We understand the unique needs of different industries and can deliver solutions that meet those needs.",
  },
];
