import assets from "../../assets";

export const uiServicesData = [
  {
    icon: assets.ui.s_1,
    name: "Integrated UI/UX Design: ",
    description:
      "Streamline your user’s journey with designs that are not only visually appealing but also functionally intuitive, increasing user retention and conversion rates.",
  },
  {
    icon: assets.ui.s_2,
    name: "User Research and Usability Testing: ",
    description:
      "Pinpoint user needs and behaviours with comprehensive testing, ensuring your product is aligned with market demands and is user-centric.",
  },
  {
    icon: assets.ui.s_3,
    name: "Information Architecture and Wireframing:     ",
    description:
      "Structure your digital platforms with clear navigation paths to enhance user engagement and simplify interactions.",
  },
  {
    icon: assets.ui.s_7,
    name: "Prototyping and Interaction Design:",
    description:
      "Quickly visualise and iterate on design concepts to find the most effective user interactions and ensure product viability. ",
  },
  {
    icon: assets.ui.s_8,
    name: "Visual and Responsive Design: ",
    description:
      "Create compelling visuals that adapt seamlessly across devices, providing a consistent and accessible user experience.",
  },
  {
    icon: assets.ui.s_5,
    name: "Accessibility Design: ",
    description:
      "Expand your market reach by designing for all users, ensuring compliance with accessibility standards to accommodate every audience. ",
  },
  {
    icon: assets.ui.s_4,
    name: "Cross-Platform Compatibility",
    description:
      "Deliver a uniform user experience across various platforms and devices, boosting brand consistency and user trust.",
  },
  {
    icon: assets.ui.s_6,
    name: "Ongoing Support and Maintenance",
    description:
      "Keep your application modern and effective with our continuous support and iterative improvements based on user feedback and evolving trends.",
  },
];
