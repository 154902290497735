export const millstoneListRight = [
  {
    date: "August 2018",
    text: "Pixelette Technologies is founded in London, UK by Mr. Asif Ashiq Rana, focusing initially on marketing and software development to support Mr. Rana’s existing portfolio companies.",
  },
  {
    date: "April 2019",
    text: "Pixelette Technologies enters the blockchain sphere, inspired by the potential of decentralisation.",
  },
  {
    date: "December 2019",
    text: "Sister Company Recognised as a Think Tank Leader at the APPG AI at the British Parliament.",
  },
  {
    date: "March 2021",
    text: "Pixelette Technologies opens operations in 4 countries.",
  },
  {
    date: "January 2023",
    text: "Achieved recognition as a Top AI and Blockchain Company in the UK, affirming our position on the global tech stage.",
  },
  {
    date: "December 2023",
    text: "Pixelette Technologies establishes an equity partnership with BlockGuard to launch a massive web 3 ecosystem.",
  },
  {
    date: "May 2024",
    text: "Pixelette Technologies expands to 13 countries worldwide.",
  },
];
