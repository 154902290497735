import React from "react";
import Components from "../..";
import assets from "../../../assets";
import {Link} from "react-scroll";

const HeroSectionMobile = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-mobile-background">
          <img
            src={assets.mobileDevelopment.heroSectionBackground}
            alt="background"
          />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-mobile">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.mobileDevelopment.box_1} alt="box" />
            <img src={assets.mobileDevelopment.box_3} alt="box" />
            <img src={assets.mobileDevelopment.box_2} alt="box" />
            <img src={assets.mobileDevelopment.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              Transform Your Vision into Mobile Excellence
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              Transform your ideas into mobile realities with Pixelette
              Technologies. Our bespoke mobile apps not only captivate but
              convert. By focusing on seamless usability, innovative design, and
              robust performance, we solve common challenges like user
              engagement, market penetration, and tech scalability. Whether it's
              increasing efficiency, enhancing customer interaction, or securing
              data, our apps are crafted to give you a competitive edge. Ready
              to lead in your industry? Connect with us today and set the
              foundation for your digital future.
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="2400"
            >
              Schedule a Consultation
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionMobile;
