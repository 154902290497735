export const staffFaqs = [
  {
    question: "What is staff augmentation?",
    answer:
      "Staff augmentation is a service that provides businesses with additional resources to meet their specific needs. These resources can include employees, contractors, or other professionals who can work on a short-term or long-term basis to help businesses achieve their goals.",
  },
  {
    question: "What industries do you provide staff augmentation services for?",
    answer:
      "We provide staff augmentation services for a variety of industries, including Information Technology (IT), engineering, healthcare, construction, and more. We have a large pool of pre-qualified professionals with specialised knowledge and experience in various fields, so we can match the right resources to meet your specific needs.",
  },
  {
    question: "Can you provide staff on a short-term basis?",
    answer:
      "Yes, we offer both short-term and long-term solutions to meet the specific needs of your business. Whether you need a few extra hands for a special project or ongoing support, we are here to help.",
  },
  {
    question: "Who will carry out my work?",
    answer:
      "We will align our UX/UI experts along with a dedicated account manager who will be in place throughout the project lifecycle. ",
  },
  {
    question: "How do you ensure the quality of the staff provided?",
    answer:
      "We thoroughly screen and pre-qualify all our professionals before they start working with you. We also have a quality assurance process in place to ensure that the work done by our professionals meets the highest standards of quality.",
  },
  {
    question:
      "How do you ensure that the staff provided will be a good fit for my company?",
    answer:
      "We take the time to understand the needs of your business and match the right professionals to meet those needs. We also work closely with you to understand your business culture and the specific skills required for the job to ensure that the staff provided will be a good fit for your company.",
  },
  {
    question: "Can you provide staff on a direct-hire basis?",
    answer:
      "Yes, we offer a range of options to meet your specific needs including contract, temp-to-perm, or direct-hire basis.",
  },
  {
    question: "Can you provide staff on a short-term basis?",
    answer:
      "Yes, we offer both short-term and long-term solutions to meet the specific needs of your business. Whether you need a few extra hands for a special project or ongoing support, we are here to help.",
  },
  {
    question: "How do you handle payroll and benefits for the staff provided?",
    answer:
      "We handle all payroll and benefits for the staff provided. We will provide you with detailed invoices and reports on a regular basis. We will also ensure that all legal and tax compliance is met.",
  },
  {
    question: "How can I get started with your staff augmentation services?",
    answer:
      "To get started with our staff augmentation services, you can contact us and schedule a consultation to discuss your specific needs in more detail. Our team will be happy to answer any questions you may have and work with you to find the best solution for your business.",
  },
];
