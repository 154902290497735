export const millstoneListLeft = [
  {
    date: "January 2019",
    text: "Pixelette Technologies opens up for commercial activity.",
  },
  {
    date: "June 2019",
    text: "Our first major blockchain project is launched, marking our entry into innovative technology solutions.",
  },
  {
    date: "January 2020",
    text: "Transition into artificial intelligence development, starting with projects aimed at enhancing scalability and performance.",
  },
  {
    date: "June 2022",
    text: "Sister Company Attains CPD accreditation, underscoring our commitment to professional development and excellence.",
  },
  {
    date: "March 2023",
    text: "Named the Most Reviewed Company in the UK, reflecting our widespread approval and excellent client feedback.",
  },
  {
    date: "April 2024",
    text: "Recognised to be in the top 30 software development companies worldwide.",
  },
];
