import React from "react";
import Components from "../..";

const CareerDetailWithForm = (props) => {
  const data = props.data;
  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="careerDetailWithForm">
          <Components.UI.JobDetail
            description={data.description}
            skillRequired={data.skillsRequired}
            responsibilities={data.responsibilities}
          />
          {/* <Components.UI.ApplicantForm /> */}
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default CareerDetailWithForm;
