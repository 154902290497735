import React from "react";
import Components from "../..";

const WebProcess = () => {
  return (
    <>
      <Components.Common.FaqsSection
        heading="Web Development Process"
        text="At Pixelette Technologies, we meticulously craft your website, ensuring minimal issues and maximum alignment with your business objectives. Here's our streamlined web development process:"
      />
    </>
  );
};

export default WebProcess;
