import React from "react";
import Components from "..";

const EvaluateBusiness = () => {
  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="evaluateBussiness">
          <header>
            <Components.Feature.Heading className="secondry">
              Elevate Your Business Today
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="700"
            >
              Complete the form to collaborate with our specialists and develop a customised solution that brings your vision alive.
            </Components.Feature.Text>
          </header>
          <div>
            <Components.Common.ContactUs
              header={true}
              backgrounds={true}
              insideHeading="Book a Discovery Session"
            />
          </div>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default EvaluateBusiness;
