import React, { useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const InputPhoneNo = ({ label, place, className, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState("");

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setValue(value);
    helpers.setValue(value); // Update Formik field value
  };


  return (
    <>
    {/* data-aos="fade-up" data-aos-duration={`500`} */}
      
      <section >
        <div
          className={`inputPhoneNumber form-input ${className} ${
            meta.touched && meta.error && "is-invalid"
          }`}
        >
          <label
            htmlFor={field.name}
            className={`form-label ${value !== "" && "form-label-input-value"}`}
          >
            {label}
          </label>
          <section style={{
        border: meta.touched && meta.error ? "1px solid red" : "1px solid grey",
        backgroundColor: 'transparent',
        borderBlockColor: 'gray'
        
      }}>
            <PhoneInput
              country={'us'}
              value={value}
              onChange={handlePhoneChange}
              dropdownStyle={{
                backgroundColor: 'black', // Black background for dropdown
                borderRadius: '5px', // Optional: Add some border radius for style
                border: '1px solid grey', // Grey border for dropdown
                color: 'grey',
              }}
              buttonStyle={{
                backgroundColor: 'transparent', // Transparent background for button
                border: 'none', // Optional: Remove button border for style
                transition: 'background-color 0.3s', // Smooth transition for hover effect
              }}
              inputProps={{
                name: field.name,
                required: true,
                autoFocus: false,
                placeholder: place,
                style: {
                  backgroundColor: 'transparent', // Set background color to black
                  color: 'white', // Set text color to white
                  border: 'none', // Remove border
                  outline: 'none', // Remove outline
                },
                readOnly: false, // Allow typing inside the input field
                ...props,
              }}
              containerClass="intl-tel-input"
              inputClass="form-control"
            />
          </section>
          <ErrorMessage component="div" name={field.name} className="form-error" />
        </div>
      </section>
    </>
  );
};

export default InputPhoneNo;
