import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesAi = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="AI Services and Solutions"
        text="Empowering Innovation: Tailor-Made AI Services and Solutions by Pixelette Technologies."
        data={data.aiServicesData}
      />
    </>
  );
};

export default ServicesAi;
