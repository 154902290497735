import React from "react";
import Components from "../components";
import data from "../data";

const UI = () => {
  return (
    <>
      <Components.UI.HeroSectionUI />
      <Components.Common.OurClients />
      <Components.UI.UnderStandingUI />
      <Components.UI.ServicesUI />
      <Components.UI.UIProcess />
      <Components.UI.TechnologyStackUI />
      <Components.Common.WhatMakesUsSpecial
        text="Pixelette Technologies is the design partner you need for market-leading results. Here's why our partners and clients relish working with us:"
        data={data.uiSpecialData}
      />

      {/* <Components.Common.TransformConceptSection /> */}
      <Components.Common.UnlockBusinessPotential
        heading="Get a Free Design Audit Today"
        text="Discover the potential of your current digital platforms with our complimentary UI/UX audit. Let our experts identify opportunities to enhance user engagement and optimise your interface for better results. Start refining your digital strategy now!"
        btnText="Request Your Free Audit"
      />
      <Components.Common.CaseStudies
        heading="Success in Action: Our Design Case Studies"
        text="Explore our design success stories: real-world examples of how our UX/UI solutions have driven client success, showcasing impactful designs and transformative results."
      />
      <Components.Common.Partners />
      {/* <Components.UI.ExpertiseMobile />
      <Components.Common.AchievementsSection /> */}
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection data={data.uiFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default UI;
