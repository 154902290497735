import assets from "../../assets";

export const technologyStackMobileData = [
  assets.mobileDevelopment.tsw_1,
  assets.mobileDevelopment.tsw_2,
  assets.mobileDevelopment.tsw_3,
  assets.mobileDevelopment.tsw_4,
  assets.mobileDevelopment.tsw_5,
  assets.mobileDevelopment.tsw_6,
  assets.mobileDevelopment.tsw_7,
  assets.mobileDevelopment.tsw_8,
  assets.mobileDevelopment.tsw_9,
  assets.mobileDevelopment.tsw_10,
  assets.mobileDevelopment.tsw_11,
];
