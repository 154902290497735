import React from "react";
import Components from "../components";
import { v4 as uuidv4 } from "uuid";
import data from "../data";
import assets from "../assets";
import { Link } from "react-scroll";

const Career = () => {
  console.log("Data is ", data.careerData);

  return (
    <>
      <div className="career">
        <Components.Feature.Container className="main">
          <div className="career-background">
            <img src={assets.policies.careerBackground} alt="careerBackgound" />
          </div>
        </Components.Feature.Container>
        <center>
          <Components.Feature.Heading
            className="heroHeading"
            animation="zoom-out"
            duration={`500`}
          >
            Are You Pixelette Enough? Join Our Talented Team Today!
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            animation="zoom-in"
            duration="600"
          >
            Pixelette Technologies embodies excellence and creativity with our
            diverse team of skilled professionals across various fields.
          </Components.Feature.Text>
        </center>
        <Components.Feature.Container className="main margins">
          <section>
            {data.careerData.map((el) => (
              <div key={uuidv4()}>
                <Components.Feature.Heading className="blogCardHeading">
                  {el.title}
                </Components.Feature.Heading>
                <Components.Feature.Text className="titory--bold">
                  {el.detail}
                </Components.Feature.Text>
                {/* <Link to={`/career/${el.id}`}> */}
                <Link to="contactUs"  smooth={true} duration={500}>
                  <Components.Feature.Button className="primary">
                    Apply Now
                  </Components.Feature.Button>
                </Link>
              </div>
            ))}
          </section>
        </Components.Feature.Container>
      </div>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default Career;
