import React from "react";
import Components from "../..";
import { v4 as uuidv4 } from "uuid";

const JobDetail = (props) => {
  return (
    <>
      <div className="careerJobDetail">
        <section>
          <Components.Feature.Heading
            className="policyHeading"
            data-aos="fade-up"
            data-aos-duration={`400`}
          >
            Job Description
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            data-aos="fade-up"
            data-aos-duration={`500`}
          >
            {props.description}
          </Components.Feature.Text>
        </section>
        <blockquote>
          <Components.Feature.Text className="primary--bold">
            Job Responsibilities
          </Components.Feature.Text>
          <ul>
            {props.responsibilities.map((el) => (
              <li key={uuidv4()}>
                <Components.Feature.Text className="secondry">
                  {el}
                </Components.Feature.Text>
              </li>
            ))}
          </ul>
        </blockquote>
        <blockquote>
          <Components.Feature.Text className="primary--bold">
            Skills Required
          </Components.Feature.Text>
          <ul>
            {props.skillRequired.map((el) => (
              <li key={uuidv4()}>
                <Components.Feature.Text className="secondry">
                  {el}
                </Components.Feature.Text>
              </li>
            ))}
          </ul>
        </blockquote>
      </div>
    </>
  );
};

export default JobDetail;
