import assets from "../../assets";
export const expertiseWebData = [
  {
    image: assets.webDevelopment.e_1,
    text: "E-commerce",
  },
  {
    image: assets.webDevelopment.e_2,
    text: "Finance and Banking",
  },
  {
    image: assets.webDevelopment.e_3,
    text: "Healthcare",
  },
  {
    image: assets.webDevelopment.e_4,
    text: "Food and Beverage",
  },
  {
    image: assets.webDevelopment.e_5,
    text: "Hospitality and Tourism",
  },
  {
    image: assets.webDevelopment.e_6,
    text: "Education",
  },
  {
    image: assets.webDevelopment.e_7,
    text: "Real Estate",
  },
  {
    image: assets.webDevelopment.e_8,
    text: "Media and Entertainment",
  },
  {
    image: assets.webDevelopment.e_9,
    text: "Automotive",
  },
  {
    image: assets.webDevelopment.e_10,
    text: "Crypto",
  },
];
