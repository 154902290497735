import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Components from "..";
import data from "../../data";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

const MobileNav = (props) => {
  return (
    <>
      <motion.div
      initial={{ x: '-6rem', opacity: 0 }}
      animate={{ x: '0rem', opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mobilenav-main"
    >
      <div>
        {data.navMenus.map((el, index) => (
          el.subMenus ? (
            <MobileNavDropDown
              name={el.name}
              subMenus={el.subMenus}
              key={el.id}
              onClick={props.onClick}
            />
          ) : (
            <NavLink key={el.id} to={el.to} onClick={props.onClick}>
              {el.name}
            </NavLink>
          )
        ))}
        <center>
          <Link to="/contact-us" onClick={props.onClick}>
            <Components.Feature.Button className="primary">
              Contact Us
            </Components.Feature.Button>
          </Link>
        </center>
      </div>
      <figure>
        <RxCross2 onClick={props.onClick} style={{ cursor: 'pointer' }} />
      </figure>
    </motion.div>
    </>
  );
};

export default MobileNav;

const MobileNavDropDown = (props) => {
  const [active, setActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <section onClick={() => setActive(!active)}>
        <p>{props.name}</p>
        <motion.div
          animate={active ? { rotate: -180 } : { rotate: 0 }}
        >
          <IoIosArrowDown />
        </motion.div>
      </section>
      {active && (
        <motion.div
          initial={{ y: '-6rem', opacity: 0 }}
          animate={{ y: '0rem', opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {props.subMenus.map((el) => (
            el.subMenus ? (
              <MobileNavDropDown
                key={el.id}
                name={el.name}
                subMenus={el.subMenus}
                onClick={props.onClick}
              />
            ) : (
              <Link key={el.id} onClick={props.onClick} to={el.to}>
                {el.name}
              </Link>
            )
          ))}
        </motion.div>
      )}
    </div>
  );
};

