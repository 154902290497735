import assets from "../assets";
// import LogoCard from "../assets/common/LogoCards.png"

export const ourServcesData = [
  {
    icon: assets.home.s_1,
    name: "Blockchain",
    path: "blockchain-development",
    description:
      "Harness the power of blockchain with secure, scalable, and transparent decentralised solutions.",
  },
  {
    icon: assets.home.s_2,
    name: "Artificial Intelligence",
    path: "ai-services",
    description:
      "Transform your business with smart AI solutions that drive innovation and efficiency. Explore the power of generative AI, computer vision and more.",
  },
  {
    icon: assets.home.s_3,
    name: "Virtual/Augmented Reality",
    path: "vr-ar",
    description:
      "Create immersive VR/AR experiences that captivate and engage your audience.",
  },
  {
    icon: assets.home.s_5,
    name: "Web Development",
    path: "web-development",
    description:
      "Build dynamic, user-centric websites that serve as powerful digital hubs for your brand.",
  },
  {
    icon: assets.home.s_6,
    path: "custome-software-development",
    name: "Custom Software Development",
    description:
      "Get bespoke software solutions designed to meet your unique business needs.",
  },
  {
    icon: assets.home.s_4,
    path: "mobile-development",
    name: "Mobile App Development",
    description:
      "Develop intuitive and high-performance mobile apps tailored to your vision.",
  },
  {
    icon: assets.home.s_1U,
    path: "ui-ux",
    name: "User Interface (UI) and User Experience (UX) Design",
    description:
      "Enhance user satisfaction with elegant and intuitive UI/UX designs.",
  },
];
