import React from "react";
import assets from "../../assets";
import Components from "..";
import { Link } from "react-router-dom";

const CaseStudiCard = (props) => {
  return (
    <>
      <Link
        to={`/case-study/${props.to}`}
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <div className="caseStudiSectionCard">
          <figure>
            <img src={props.image} alt="case Studies" />
          </figure>
          <div>
            <header>
              {props.tags.map((el) => (
                <span>{el}</span>
              ))}
            </header>
            <Components.Feature.Heading className="blogCardHeading">
              {props.name}
            </Components.Feature.Heading>
            <Components.Feature.Text className="titory--bold">
              {props.description}
            </Components.Feature.Text>
          </div>
        </div>
      </Link>
    </>
  );
};

export default CaseStudiCard;
