import React from "react";
import Components from "../..";
import data from "../../../data";

const TechnologyStackArandVr = () => {
  return (
    <>
      <Components.Common.TechnologyGrid
        heading="Empowering Realities: Our Advanced Technology Stack"
        text="At Pixelette Technologies, we harness the latest advancements in technology to create unparalleled augmented and virtual reality experiences. Our robust technology stack includes:"
        data={data.technologyStackArandVrData}
      />
    </>
  );
};

export default TechnologyStackArandVr;
