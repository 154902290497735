export const termAndConditions = [
  {
    title: "Introduction",
    lists: [
      {
        title: `These terms and conditions (“Terms”) govern your use of the services and solutions provided by Pixelette Technologies (“we”, “us” or “our”). By accessing or using our services, you agree to be bound by these Terms, which constitute a legally binding agreement between you and us. If you do not agree to these Terms, you should not use our services.`,
        subList: [],
      },
    ],
  },
  {
    title: "Services",
    lists: [
      {
        title: `We provide a range of services and solutions related to blockchain technology, including but not limited to the development of blockchain-based applications, smart contract creation, and consulting services. Our services are intended for business and institutional use and may not be suitable for individuals.`,
        subList: [],
      },
    ],
  },
  {
    title: "Payment",
    lists: [
      {
        title: `Our services and solutions are provided on a fee basis. You agree to pay all fees and charges associated with your use of our services in accordance with the pricing and payment terms presented to you at the time of purchase. We reserve the right to change our prices at any time.`,
        subList: [],
      },
    ],
  },
  {
    title: "Intellectual property",
    lists: [
      {
        title: `All intellectual property rights in and to our services and solutions, including but not limited to software, code, and documentation, belong to us or our licensors. You are granted a limited, non-exclusive, non-transferable license to use our services and solutions for the duration of your subscription.`,
        subList: [],
      },
    ],
  },
  {
    title: "Confidentiality",
    lists: [
      {
        title: `You agree to keep confidential any and all information or data that we may disclose to you in connection with our services, including but not limited to any confidential information or data belonging to our clients.`,
        subList: [],
      },
    ],
  },
  {
    title: "Liability",
    lists: [
      {
        title: `To the fullest extent permitted by law, we exclude all liability for any loss or damage of any kind arising in connection with your use of our services, including but not limited to any loss of profits, revenue, or data.`,
        subList: [],
      },
    ],
  },

  {
    title: "Governing law",
    lists: [
      {
        title: `These Terms shall be governed by and construed in accordance with the laws of England and Wales.`,
        subList: [],
      },
    ],
  },
  {
    title: "Dispute resolution",
    lists: [
      {
        title: `Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.`,
        subList: [],
      },
    ],
  },
  {
    title: "Changes to these terms",
    lists: [
      {
        title: `We reserve the right to make changes to these Terms at any time. Your continued use of our services following any changes to these Terms will be deemed to be your acceptance of such changes.`,
        subList: [],
      },
    ],
  },
  {
    title: "Entire agreement",
    lists: [
      {
        title: `These Terms constitute the entire agreement between you and us in relation to your use of our services. They supersede all prior agreements and understandings.`,
        subList: [],
      },
    ],
  },
  {
    title: "Acceptance of terms",
    lists: [
      {
        title: `By using our website, services and solutions, you are agreeing to be bound by these terms and conditions, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this website.`,
        subList: [],
      },
    ],
  },
  {
    title: "Contact",
    description:
      "If you have any questions about this Privacy Policy, please contact us:",
  },
];
