export const chartData = {
    labels: ['Core phase of Token sale', 'Bounties', 'Partner and Advisor', 'Project Team share', 'Bonus & Reserve'],
    datasets: [
      {
        label: 'My Dataset',
        data: [65, 3, 10, 13, 9],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
      },
    ],
  };
  