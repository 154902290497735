import React from "react";
import Components from "../components";
import data from "../data";
import assets from "../assets";

const RefundPolicy = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="refund-background">
          <img src={assets.policies.refundPolicy} alt="background" />
        </div>
      </Components.Feature.Container>
      <Components.Feature.Container className="main margins">
        <div className="refund">
          <center>
            <Components.Feature.Heading className="heroHeading">
              Our Cancellation and Refund Policy
            </Components.Feature.Heading>
            <Components.Feature.Text className="secondry">
              All clients consent to Pixelette Technologies’ cancellation and
              refund policy. This consent is given as soon as all customers
              avail any of our services and solutions.
            </Components.Feature.Text>
          </center>
          <Components.Common.DetailsNavigate
            data={data.refundPolicy}
            headingIndex={false}
            overViewIndex={false}
            headerSection={false}
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default RefundPolicy;
