import React from "react";
import Components from "../..";

const StaffProcess = () => {
  return (
    <Components.Common.FaqsSection
      heading="Our Staff Augmentation Process"
      text="Pixelette Technologies offers a meticulous staff augmentation process, designed to seamlessly integrate skilled professionals into your business."
    />
  );
};

export default StaffProcess;
