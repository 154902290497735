import React from "react";
import Components from "../..";
import data from "../../../data";

const TechnologyStackBlockChain = () => {
  return (
    <>
      <Components.Common.TechnologyGrid
        heading="Empowering Innovation with Blockchain Mastery"
        text="Partner with us for cutting-edge blockchain solutions that set industry benchmarks. Dive confidently into the digital era with secure, scalable, and transformative applications, leading with innovation and efficiency."
        data={data.technologyBlockChainData}
      />
    </>
  );
};

export default TechnologyStackBlockChain;
