// import Components from "../..";
// import assets from "../../../assets";

// const HeroSectionAbout = () => {
//   return (
//     <>
//       <Components.Feature.Container className="main">
//         <div className="heroSectionAbout-background">
//           <img src={assets.aboutUs.heroBackground} alt="background" />
//         </div>
//       </Components.Feature.Container>

//       <div className="heroSectionAbout">
//         <Components.Feature.Container className="main margins ">
//           <header>
//             <Components.Feature.Heading
//               className="heroHeading"
//               animation="zoom-out"
//               duration={`300`}
//             >
//               Leading Innovation in AI and Blockchain
//             </Components.Feature.Heading>
//             <Components.Feature.Text
//               className="primary"
//               animation="zoom-in"
//               duration="500"
//             >
//               Discover Pixelette Technologies' Global Impact From our beginnings
//               in 2018, Pixelette Technologies has rapidly become a world leader
//               in software development, specialising in AI and blockchain
//               technologies. With a presence in 13 countries and a team that
//               ranks among the top 5% globally, we are your trusted partner for
//               cutting-edge solutions that drive business success. Join us in
//               shaping the future of technology.
//             </Components.Feature.Text>
//             <Components.Feature.Button className="primary">
//               Get in Touch and Explore Our Journey
//             </Components.Feature.Button>
//           </header>
//           <section>
//             <header>
//               <Components.Feature.Heading
//                 className="secondry"
//                 animation="fade-up"
//                 duration={`300`}
//               >
//                 Who We Are
//               </Components.Feature.Heading>
//               <Components.Feature.Text
//                 className="titory"
//                 animation="fade-up"
//                 duration="500"
//               >
//                 At Pixelette Technologies, we are more than a tech company; we
//                 are innovators and trailblazers in the fields of Artificial
//                 Intelligence and Blockchain technology. Founded in 2018 by the
//                 visionary entrepreneur Mr. Asif Ashiq Rana, we began as a niche
//                 agency catering to the needs of associated businesses. Quickly
//                 realising the transformative potential of blockchain and AI, we
//                 expanded our horizons. Today, we are recognised among the top 30
//                 global leaders in software development, official secretariat for
//                 the All-Party Parliamentary Group on AI, we shape pivotal policy
//                 discussions, driving innovation that impacts global tech
//                 standards. Driven by a mission to engineer revolutionary
//                 solutions that propel businesses into the future, our unique
//                 strength lies in our team, comprising over 200 of the brightest
//                 minds in tech, recognised globally for their unmatched expertise
//                 and deep industry knowledge.
//               </Components.Feature.Text>
//             </header>
//             <img src={assets.aboutUs.banner} alt="banner" />
//           </section>
//         </Components.Feature.Container>
//       </div>
//     </>
//   );
// };

// export default HeroSectionAbout;

import React from "react";
import Slider from "react-slick";
import Components from "../..";
import assets from "../../../assets";
import { Link } from "react-scroll";

const HeroSectionAbout = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 50,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
    arrows: false,
  };



  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSectionAbout-background">
          <img src={assets.aboutUs.heroBackground} alt="background" />
        </div>
      </Components.Feature.Container>

      <div className="heroSectionAbout">
        
        <Components.Feature.Container className="main margins ">
        <center>
          <header>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`300`}
            >
              Leading Innovation in AI and Blockchain
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="500"
            >
              Discover Pixelette Technologies' Global Impact From our beginnings
              in 2018, Pixelette Technologies has rapidly become a world leader
              in software development, specialising in AI and blockchain
              technologies. With a presence in 13 countries and a team that
              ranks among the top 5% globally, we are your trusted partner for
              cutting-edge solutions that drive business success. Join us in
              shaping the future of technology.
            </Components.Feature.Text>
            <div className="buttonpad"></div>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button className="primary">
              Get in Touch and Explore Our Journey
            </Components.Feature.Button>
            </Link>
          </header>
          </center>
          <div className="weare"></div>
          <section>
            
            <header>
              <Components.Feature.Heading
                className="secondry"
                animation="fade-up"
                duration={`300`}
              >
                Who We Are
              </Components.Feature.Heading>
              <Components.Feature.Text
                className="titory"
                animation="fade-up"
                duration="500"
              >
                At Pixelette Technologies, we are more than a tech company; we
                are innovators and trailblazers in the fields of Artificial
                Intelligence and Blockchain technology. Founded in 2018 by the
                visionary entrepreneur Mr. Asif Ashiq Rana, we began as a niche
                agency catering to the needs of associated businesses. Quickly
                realising the transformative potential of blockchain and AI, we
                expanded our horizons. Today, we are recognised among the top 30
                global leaders in software development, official secretariat for
                the All-Party Parliamentary Group on AI, we shape pivotal policy
                discussions, driving innovation that impacts global tech
                standards. Driven by a mission to engineer revolutionary
                solutions that propel businesses into the future, our unique
                strength lies in our team, comprising over 200 of the brightest
                minds in tech, recognised globally for their unmatched expertise
                and deep industry knowledge.
              </Components.Feature.Text>
            </header>
            <Slider {...settings}>
              <div className="heroSectionAbout-slider-image">
                <img src={assets.aboutUs.AsifMeetup} alt="banner" />
              </div>
              {/* <div className="heroSectionAbout-slider-image">
                <img src={assets.aboutUs.b} alt="banner2" />
              </div> */}
              <div className="heroSectionAbout-slider-image">
                <img src={assets.aboutUs.AsifEvent} alt="banner3" />
              </div>
              {/* <div className="heroSectionAbout-slider-image">
                <img src={assets.aboutUs.e} alt="banner3" />
              </div> */}
              {/* <div className="heroSectionAbout-slider-image">
                <img src={assets.aboutUs.f} alt="banner3" />
              </div> */}
            </Slider>
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionAbout;
