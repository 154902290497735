import React, { useRef } from "react";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";
import assets from "../../assets";
import Components from "..";
import { FiExternalLink } from "react-icons/fi";
import data from "../../data";
const Testimonial = (props) => {
  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  function nextButtonHandler() {
    slider?.current?.slickNext();
  }

  function previousButtonHandler() {
    slider?.current?.slickPrev();
  }

  return (
    <>
      {props.background ? (
        ""
      ) : (
        <Components.Feature.Container className="main">
          <div className="testimonialSection-background">
            <img
              src={assets.home.testimonialBackground}
              alt="testimonialCard"
            />
          </div>
        </Components.Feature.Container>
      )}

      <div className="testimonialSection">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.home.box_16} alt="box" />
          </blockquote>

          <center>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="400"
              id="h_ani"
            >
              Success Stories: Hear What Our Clients Have to Say
            </Components.Feature.Heading>
            <div data-aos-duration="500" data-aos="fade-up">
              <Components.Feature.Text className="primary">
                4.9
              </Components.Feature.Text>
              <img src={assets.home.stars} alt="icon" />
              <Components.Feature.Button className="primary">
               
              <a href="https://clutch.co/profile/pixelette-technologies-0?sort_by=date_desc#reviews" target="_blank" rel="noopener noreferrer">
        21 Reviews <FiExternalLink />
      </a>
              </Components.Feature.Button>
            </div>
          </center>

          <section>
            <div className="id">
              <Slider ref={slider} {...settings}>
              
                {data.testimonialData.map((el, index) => (
                  <a key={uuidv4()} href={el.url}>
                  <Components.Feature.TestimonialCard
                    key={uuidv4()}
                    comment={el.comment}
                    profile={el.user_profile}
                    rating={el.rating}
                    userName={el.user_name}
                    role={el.user_role}
                    animation="fade-up"
                    duration={`${index}00`}
                  />
                  </a>
                ))}
                
              </Slider>
            </div>
          </section>
          <div>
            <img
              src={assets.home.arrowLeftSlider}
              alt="icon"
              onClick={previousButtonHandler}
              data-aos-duration="700"
              data-aos="fade-right"
            />
            <img
              src={assets.home.arrowRightSlider}
              alt="icon"
              onClick={nextButtonHandler}
              data-aos="fade-left"
              data-aos-duration="700"
            />
          </div>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default Testimonial;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <img src={arrowLeftSlider} alt="arrow" />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      <img src={arrowRightSlider} alt="arrow" />
    </div>
  );
}
