import React from "react";
import Components from "../..";
import assets from "../../../assets";
import { FaCheck } from "react-icons/fa";

const Choose = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="chooseSection-background">
          <img src={assets.home.chooseSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>

      <div className="choose">
        <Components.Feature.Container className="main margins">
          <section>
            <img
              src={assets.home.chooseHeroImage}
              alt="hero"
              data-aos="zoom-in"
              data-aos-duration="600"
            />
            <div>
              <Components.Feature.Heading
                className="secondry"
                animation="fade-up"
                duration="400"
              >
                Why Choose Us?
              </Components.Feature.Heading>
              <Components.Feature.Text
                animation="fade-up"
                duration="500"
                className="secondry"
              >
                At Pixelette Technologies, we provide access to a team of
                skilled and experienced experts from the start-up ecosystem, who
                are passionate about helping start-ups achieve success
              </Components.Feature.Text>
              <Item
                duration="200"
                animation="fade-up"
                text="Get funded by Pixelette Technologies"
              />
              <Item
                duration="300"
                animation="fade-up"
                text="Access to a quality network of partners"
              />
              <Item
                duration="400"
                animation="fade-up"
                text="World-class development and marketing services"
              />
              <Item
                duration="500"
                animation="fade-up"
                text="Heavily subsidised fees"
              />
              <Item
                duration="500"
                animation="fade-up"
                text="Give away minimal control"
              />
            </div>
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default Choose;

const Item = (props) => {
  return (
    <div data-aos={props.animation} data-aos-duration={props.duration}>
      <FaCheck />
      <Components.Feature.Text className="titory">
        {props.text}
      </Components.Feature.Text>
    </div>
  );
};
