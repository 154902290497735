import assets from "../assets";

export const testimonialData = [
  {
    rating: 5,
    comment: "Pixelette Technologies has been the perfect partner so far.",
    user_name: "Mushtaq Khalil",
    user_role: "Co-Founder, Buttersmiles Marketplace",
    user_profile: assets.home.t_one,
    url:"https://clutch.co/profile/pixelette-technologies-0?utm_campaign=widget&utm_content=stars&utm_medium=3&utm_source=widget&utm_term=www.pixelettetech.com#review-158221",
  },
  {
    rating: 5,
    comment: "We couldn't ask for a more professional service.",
    user_name: "Anthony Bevan",
    user_role: "CEO, The BlockGuard Technologies",
    user_profile: assets.home.t_two,
    url:"https://clutch.co/profile/pixelette-technologies-0?sort_by=date_desc#review-275026",
  },
  {
    rating: 5,
    comment:"Working with them has been a good experience.",
    user_name: "Anonymous",
    user_role: "Executive, Healthcare Company",
    user_profile: assets.home.t_three,
    url:"https://clutch.co/profile/pixelette-technologies-0?utm_campaign=widget&utm_content=testimonial&utm_medium=12&utm_source=widget&utm_term=www.pixelettetech.com#review-178359",
  },
  {
    rating: 5,
    comment: "The deliverables were there on time and at high quality!",
    user_name: "David Elcombe",
    user_role: "Managing Director, WindWorkX Industry",
    user_profile: assets.home.t_four,
    url:"https://clutch.co/profile/pixelette-technologies-0?sort_by=date_desc#review-255279",
  },
];
