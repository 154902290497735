export const privacyPolicy = [
  {
    title: "Information we collect",
    lists: [
      {
        title: `We collect information such as your name, email address, IP address, and any other information that you provide to us through our website or through our services. We may also collect information about your device, including its operating system and browser type.`,
        subList: [],
      },
    ],
  },

  {
    title: "Use of information",
    lists: [
      {
        title: `We use the information we collect to provide our services, improve our website, and to communicate with you. We may also use your information for marketing and advertising purposes, with your consent. We will only use your information for the purposes for which it was collected, and we will not use it for any other purpose without your prior consent.`,
        subList: [],
      },
    ],
  },

  {
    title: "Sharing of information",
    lists: [
      {
        title: `We may share your information with third parties, such as our partners, service providers, and regulators, in order to provide our services, comply with legal obligations, and to protect the rights and safety of our company and our users. We will only share your information if we have a lawful basis for doing so, and we will ensure that any third parties we share your information with are bound by appropriate confidentiality and data protection obligations.`,
        subList: [],
      },
    ],
  },

  {
    title: "Data security",
    lists: [
      {
        title: `We take reasonable steps to protect your personal information from loss, misuse, and unauthorised access, disclosure, alteration, and destruction. We use appropriate technical and organisational measures to protect your personal information, including encryption and secure server facilities. However, no security measures are 100% secure, and we cannot guarantee the security of your information.`,
        subList: [],
      },
    ],
  },

  {
    title: "Data retention",
    lists: [
      {
        title: `We will retain your information for as long as necessary to provide our services, comply with legal obligations, and resolve disputes. We will securely delete your personal information when it is no longer required for these purposes.`,
        subList: [],
      },
    ],
  },

  {
    title: "International transfer",
    lists: [
      {
        title: `Your information may be transferred to, and processed in, countries other than the country in which you reside. These countries may have different data protection laws than the country in which you reside. We will ensure that any international transfers of your personal information are carried out in accordance with applicable law, including the GDPR and any other applicable data protection legislation.`,
        subList: [],
      },
    ],
  },

  {
    title: "Your rights",
    lists: [
      {
        title: `You have the right to access, correct, delete or restrict the use of your personal information. You also have the right to request a copy of your personal information and to request that your personal information be transferred to another organisation (data portability). You have the right to object to our processing of your personal information for certain purposes, such as for direct marketing. You also have the right to lodge a complaint with a supervisory authority, such as the Information Commissioner’s Office in the UK.`,
        subList: [],
      },
    ],
  },

  {
    title: "Children's privacy",
    lists: [
      {
        title: `Our Service is not intended for children under the age of 18 (“Minors”). We do not knowingly collect any personal information from Minors. In the event that a parent or guardian becomes aware that their Minor has provided us with personal data, we request that they contact us promptly. If it is brought to our attention that we have collected personal data from a Minor without proper verification of parental consent, we will take immediate steps to remove such information from our systems.`,
        subList: [],
      },
    ],
  },

  {
    title: "Changes to this policy",
    lists: [
      {
        title: `We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on our website.`,
        subList: [],
      },
    ],
  },
  {
    title: "Contact",
    description:
      "If you have any questions about this Privacy Policy, please contact us:",
  },
];
