import React from "react";
import Components from "../..";
import assets from "../../../assets";
const CaseStudyHome = () => {
  return (
    <div className="caseStudyHome">
      <Components.Feature.Container className="main margins">
        <blockquote>
          <img src={assets.home.box_12} alt="box" />
          <img src={assets.home.box_13} alt="box" />
          <img src={assets.home.box_23} alt="box" />
        </blockquote>
      </Components.Feature.Container>
      <Components.Common.CaseStudies heading="Showcase of Innovation" />
    </div>
  );
};

export default CaseStudyHome;
