import React, { useRef, useState, useEffect } from "react";
import Components from "../..";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";
import assets from "../../../assets";
import { createClient } from "contentful";

const ExploreProjectsSections = () => {
  const [showPrevious, setShowPrevious] = useState(false);
  const [active, setActive] = useState("Blockchain");
  const [filteredData, setFilteredData] = useState([]);
  const [blogdata, setBlogData] = useState([]);

  const slider = useRef(null);

  useEffect(() => {
    const client = createClient({
      space: "ggtsbq0gqfii",
      accessToken: "VZvVye8dMIc497wF-1pNt5rdYUG-h4E30uX58AcGVUo",
    });

    client
      .getEntries({ content_type: "caseStudies" })
      .then((response) => {
        console.log("response: ", response);
        setBlogData(response.items);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (blogdata.length > 0) {
      const tags = Array.from(
        new Set(blogdata.flatMap((caseStudy) => caseStudy.fields?.tags || []))
      );
      setFilteredData(
        blogdata.filter((caseStudy) => caseStudy.fields?.tags?.includes(active))
      );
    }
  }, [active, blogdata]);

  const sliderData = Array.from(
    new Set(blogdata.flatMap((caseStudy) => caseStudy.fields?.tags || []))
  );

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  function nextButtonHandler() {
    slider?.current?.slickNext();
    setShowPrevious(true);
  }

  function previousButtonHandler() {
    slider?.current?.slickPrev();
  }

  const handleActiveBtn = (element) => {
    setActive(element);
  };

  return (
    <div className="exploreProjectSections">
      <section>
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration="400"
            id="h_ani"
          >
            Explore Our Projects
          </Components.Feature.Heading>
        </center>
      </section>
      <Components.Feature.Container className="main margins">
        <header>
          <div data-aos="fade-up" data-aos-duration="500">
            {showPrevious && (
              <img
                src={assets.home.arrowLeftSlider}
                alt="Previous"
                onClick={previousButtonHandler}
              />
            )}
            <div>
              <Slider ref={slider} {...settings}>
                {sliderData.map((el) => (
                  <button
                    id={active === el ? "toggleActive" : "toggleInActive"}
                    onClick={() => handleActiveBtn(el)}
                    style={{ width: "50rem" }}
                    key={uuidv4()}
                  >
                    {el}
                  </button>
                ))}
              </Slider>
            </div>
            <img
              src={assets.home.arrowRightSlider}
              alt="Next"
              onClick={nextButtonHandler}
            />
          </div>
        </header>
        <section>
          {filteredData.map((el) => (
            <Components.Feature.CaseStudiCard
              key={el.sys?.id}
              to={el.sys?.id}
              image={el.fields?.image?.fields?.file?.url}
              name={el.fields?.shortDescription}
              tags={el.fields?.tags}
              description={el.fields?.longDescription}
            />
          ))}
        </section>
      </Components.Feature.Container>
    </div>
  );
};

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "transparent" }}
//       onClick={onClick}
//     >
//       <img src={assets.home.arrowRightSlider} alt="Next Arrow" />
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "transparent" }}
//       onClick={onClick}
//     >
//       <img src={assets.home.arrowLeftSlider} alt="Previous Arrow" />
//     </div>
//   );
// }

export default ExploreProjectsSections;
