import React from "react";
import Components from "../..";
import data from "../../../data";

const TechnologyStack = () => {
  return (
    <>
      <Components.Common.TechnologyGrid
        heading="Powered by Innovation: Our Technology Stack"
        text="At Pixelette Technologies, our dedicated teams of data scientists,
      designers, solution architects, developers, strategists, project
      managers and technical writers work with the latest technologies to
      deliver excellence."
        data={data.technologyStackAiData}
      />
    </>
  );
};

export default TechnologyStack;
