// import { useState } from "react";
// import Components from "..";
// import { motion } from "framer-motion";
// import { RiArrowDownSLine } from "react-icons/ri";

// const Accordian = (props) => {
//   const [isOpen, setOpen] = useState(false);
//   return (
//     <div
//       data-aos="fade-up"
//       data-aos-duration={`1000`}
//       className="accordian"
//       onClick={() => {
//         setOpen(!isOpen);
//       }}
//     >
//       <header>
//         <Components.Feature.Text className="secondry">
//           {props.question}
//         </Components.Feature.Text>
//         <motion.div
//           animate={
//             isOpen
//               ? {
//                   rotate: -180,
//                 }
//               : { rotate: 0 }
//           }
//         >
//           <RiArrowDownSLine />
//         </motion.div>
//       </header>
//       <section>
//         {isOpen ? (
//           <motion.div
//             initial={{ scaleY: 0, opacity: 0 }}
//             animate={{ scaleY: 1, opacity: 1 }}
//             exit={{ scaleY: 0, opacity: 0 }}
//           >
//             <Components.Feature.Text className="titory">
//               {props.answer}
//             </Components.Feature.Text>
//           </motion.div>
//         ) : (
//           ""
//         )}
//       </section>
//     </div>
//   );
// };

// export default Accordian;

import Components from "..";
import { motion } from "framer-motion";
import { RiArrowDownSLine } from "react-icons/ri";
import star from "../../assets/plus.svg";
import minus from "../../assets/minus.svg";

const Accordian = (props) => {
  return (
    <div className="accordian" onClick={props.onClick}>
      <header>
        <Components.Feature.Text className="secondry">
          {props.question}
        </Components.Feature.Text>
        <motion.div
          animate={
            props.isOpen
              ? {
                  rotate: -180,
                }
              : { rotate: 0 }
          }
        >
          <img src={props.isOpen ? minus : star} alt="toggle icon" />
        </motion.div>
      </header>
      <section>
        {props.isOpen ? (
          <motion.div
            initial={{ scaleY: 0, opacity: 0 }}
            animate={{ scaleY: 1, opacity: 1 }}
            exit={{ scaleY: 0, opacity: 0 }}
          >
            <Components.Feature.Text className="titory">
              {props.answer}
            </Components.Feature.Text>
          </motion.div>
        ) : null}
      </section>
    </div>
  );
};

export default Accordian;
