import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesArAndVr = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Our VR and AR Development Services"
        text="Explore our comprehensive VR and AR solutions at Pixelette Technologies, tailored to elevate your business. We specialise in creating immersive and interactive environments that surpass expectations. Let us transform how you engage with technology."
        data={data.arAndVRServicesData}
      />
    </>
  );
};

export default ServicesArAndVr;
