import assets from "../../assets";
export const expertiseArData = [
  {
    image: assets.arAndVr.e_1,
    text: "Healthcare and Pharmaceuticals",
  },
  {
    image: assets.arAndVr.e_2,
    text: "Education and Training",
  },
  {
    image: assets.arAndVr.e_3,
    text: "Retail",
  },
  {
    image: assets.arAndVr.e_4,
    text: "Real Estate",
  },
  {
    image: assets.arAndVr.e_5,
    text: "Manufacturing",
  },
  {
    image: assets.arAndVr.e_6,
    text: "Tourism and Hospitality",
  },
  {
    image: assets.arAndVr.e_7,
    text: "Entertainment and Media",
  },
  {
    image: assets.arAndVr.e_8,
    text: "Automotive",
  },
  {
    image: assets.arAndVr.e_9,
    text: "Construction and Architecture",
  },
];
