import React from "react";
import Components from "../..";

const MobileProcess = () => {
  return (
    <>
      <Components.Common.FaqsSection
        heading="Mobile Development Process"
        text="At Pixelette Technologies, we meticulously craft your mobile application, ensuring minimal issues and maximum alignment with your business objectives. Here's our streamlined mobile development process:"
      />
    </>
  );
};

export default MobileProcess;
