import React from "react";
import Components from "../..";
import data from "../../../data";
import { Formik, Form } from "formik";


import emailjs from 'emailjs-com';

const ResearchInquiryForm = () => {
  const initalData = {
    fullName: "",
    email: "",
    organization: "",
    timeLine: "",
    title: "",
    researchTopic: "",
    description: "",
    comments: "",
  };

  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="startUpsFrom">
          <center>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="500"
            >
              Research Services Inquiry Form
            </Components.Feature.Heading>
          </center>
          <section data-aos="fade-up" data-aos-duration="2000">
            <div>
              <Formik
                initialValues={initalData}
                validateOnMount
                validationSchema={data.validationResearchInquiry}
                onSubmit={(values, { resetForm }) => {

                  const templateParams = {
                    fullName: values.fullName,
                    email: values.email,
                    organization: values.organization,
                    timeLine:values.timeLine,
                    title:values.title,
                    researchTopic:values.researchTopic,
                    description: values.description,
                    comments: values.comments,
                  
                  };
              
                  emailjs.send('service_5y0itcg', 'template_4aiiawr', templateParams, 'LYS_0H8byHSkeaSrz')
                    .then(response => {
  
                      console.log(values);
                  console.log(response );
  
                     // setStatusMessage('Email sent successfully!');
                    })
                    .catch(error => {
                      console.log(error );
                 
                      //  setStatusMessage('Failed to send email.');
                    });


                  console.log(values);
                  resetForm();
                }}
              >
                {(formik) => (
                  <Form>
                    <section className="startUpsFrom-container">
                      <blockquote>
                        <Components.Feature.FormInput
                          name="fullName"
                          label="Full Name"
                          place="Enter your full name"
                        />
                        <Components.Feature.FormInput
                          name="email"
                          label="Email"
                          place="Enter your email"
                        />
                      </blockquote>
                      <blockquote>
                        <Components.Feature.FormInput
                          name="organization"
                          label="Organization"
                          place="Enter organization"
                        />
                        <Components.Feature.FormInput
                          name="timeLine"
                          label="Timeline"
                          place="Enter timeline"
                        />
                      </blockquote>
                      <blockquote>
                        <Components.Feature.FormInput
                          name="title"
                          label="Title/Position"
                          place="Enter title/position"
                        />
                        <Components.Feature.FormInput
                          name="researchTopic"
                          label="Research Area Of Interest"
                          place="Enter research area of interest"
                        />
                      </blockquote>
                      <Components.Feature.FromTextArea
                        name="description"
                        label="Project Description"
                        place="Write something about project..."
                        rows="7"
                        cols="30"
                      />
                      <Components.Feature.FromTextArea
                        name="comments"
                        label="Additional Comments/Questions"
                        place="Additional Comments/Questions..."
                        rows="7"
                        cols="30"
                      />
                      <Components.Feature.Button
                        className="primary"
                        animation="fade-up"
                        duration="700"
                        type="submit"
                      >
                        Submit
                      </Components.Feature.Button>
                    </section>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </div>
      </Components.Feature.Container>
    </>
  );
};

export default ResearchInquiryForm;
