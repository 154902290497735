export const caseStudiesData = [
  {
    id: 1,
    category: "Blockchain",
    name: "Decentralised Dialogues: Crafting Beowulf’s Blockchain Communication Network",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Case-Study-Mockup-8.png",
    tags: ["Blockchain"],
    projectName: "Beowulf",
    description:
      "Pixelette Technologies embarked on an ambitious journey with Beowulf to manifest a decentralised cloud network aimed at revolutionizing communication for businesses. The project hinged on harnessing blockchain technology to obliterate conventional communication barriers, ",
    projectLink: "https://beowulfchain.com/",
    extactiveSummary:
      "In an era burgeoning with digital innovation, Beowulf envisioned a decentralised cloud network to redefine business communication paradigms. By leveraging blockchain technology, Beowulf aspired to create a platform that eradicates upfront investments, ensures transparent pay-per-use pricing, and extends blockchain-based services to empower businesses to reach a broader audience.",
    stack: [],
    feature: {
      text: "",
      list: [
        {
          heading: "Token Model Creation:",
          text: "Devised a robust token model integral to the functionality and economy of the Beowulf blockchain.",
        },
        {
          heading: "ICO Landing Page:",
          text: "Crafted a visually captivating and user-friendly ICO landing page to entice potential investors.",
        },
        {
          heading: "Comprehensive Campaign Planning Guide:",
          text: "A comprehensive strategy devised for campaign planning to ensure a smooth launch.",
        },
        {
          heading: "Website Development:",
          text: "Executed a well-designed website, encapsulating the essence of Beowulf and its offerings.",
        },
      ],
    },

    problemStatement:
      "The crux of the endeavor was to navigate the complex realms of blockchain technology to develop a decentralised, distributed computing network that is not only scalable but also exhibits low latency for building dApps. The overarching goal was to obviate traditional communication hurdles and provide a conduit for businesses to amplify their outreach with no initial investment.",
    solution: {
      text: "The technical landscape of the project entailed the meticulous creation of a Delegated Proof of Stake (DPoS) consensus mechanism, an innovative variant of the traditional Proof of Stake (PoS) mechanism. Additionally, our blockchain experts ensured the optimisation and security reinforcement of the website, which is crucial for the success of any blockchain project.",
      list: [],
    },

    results: {
      text: "The fruition of this project has resulted in the successful launch of Beowulf. This decentralised cloud network now serves as a linchpin for businesses aiming to transcend traditional communication barriers. The outcome has met the client’s expectations and placed Beowulf on the map as a formidable player in the blockchain communication sphere.",
      list: [],
    },
    conclusion:
      "The collaboration between Pixelette Technologies and Beowulf culminated in a decentralised communication platform that stands as a beacon of innovation in leveraging blockchain for business communications. This project exemplifies our prowess in navigating the complex tapestry of blockchain technology to deliver solutions that propel our clients to the forefront of digital innovation.",
  },
  {
    id: 2,
    category: "Blockchain",
    name: "Luxury Meets Ledger: The Advent of Waifu House NFT Community",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/image-69.png",
    tags: ["Blockchain"],
    projectName: "Waifu House DAO",
    description:
      "Waifu House NFT is a testament to the boundless opportunities that the amalgam of luxury assets and blockchain technology can offer. By envisaging a community where NFT builders and enthusiasts can converge, network, and unlock real-world earning potential, Waifu House NFT sought to carve a niche in the digital asset landscape.",
    projectLink: "https://www.nft-stats.com/collection/waifuhousedao",
    extactiveSummary:
      "Pixelette Technologies joined hands with Waifu House NFT to materialize a unique digital realm that merges luxury assets with the bustling sphere of NFTs. This venture sought to establish an exclusive community of Web3 investors, providing a conduit for networking and real-world earning potential through luxury physical and digital assets generating $WAIFU tokens. Despite the initial hurdles of security and regulatory uncertainties, our adept team navigated through, ensuring a secure, compliant, and efficient product embodying Waifu House NFT’s essence.",
    stack: [],
    feature: [
      {
        heading: "Exclusive Community Access:",
        text: "Developed a platform to foster an exclusive NFT community facilitating networking and engagement.",
      },
      {
        heading: "VIP Access to Assets:",
        text: "Enabled free VIP access to a range of luxury physical and digital assets generating $WAIFU tokens.",
      },
      {
        heading: "Blockchain Integration: ",
        text: "Seamlessly integrated blockchain technology ensures a secure and transparent digital asset experience.",
      },
    ],
    problemStatement:
      "The journey was challenging, chiefly concerning security, compliance with absent regulatory guidelines, and optimizing technological designs to align with the project’s grand vision.",
    solution:
      "Utilizing a robust technical stack of REACT JS, Web3.JS, and Solidity, our team crafted solutions to address the identified challenges. The focal point was to bolster security measures by creating a restricted access ledger and rigorously assessing and rectifying coding vulnerabilities. Moreover, comprehensive regulatory guidelines were laid to ensure compliance, thus creating a secure and efficient platform for Waifu House NFT.    ",
    results:
      "These efforts culminated in the successful development and launch of Waifu House NFT, embodying a secure, compliant, and user-centric platform. The project met the stipulated objectives and set a precedent for integrating luxury assets with blockchain technology.",
    conclusion:
      "Waifu House NFT project is a successful endeavor in exploring the confluence of luxury assets and NFTs. Pixelette Technologies is proud to have steered this project to success, showcasing our capability to maneuver through complex challenges and deliver a product that resonates with the client’s vision.",
  },
  {
    id: 3,
    category: "Blockchain",
    name: "Green Blockchain: MYHEMP.iO’s Leap Towards Transparent Hemp and Marijuana Trade",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Case-Study-Mockup-5.png",
    tags: ["Blockchain"],
    projectName: "MY HEMP",
    description:
      "MYHEMP.iO seeks to revolutionise the legal hemp and marijuana sector by providing a platform that addresses the industry’s multifaceted challenges. Through a decentralised exchange, it empowers consumers and stakeholders by ensuring transparency and efficiency across the supply chain, from agricultural origins to the end consumers.",
    projectLink: "https://myhemp.io/",
    extactiveSummary:
      "Pixelette Technologies had the privilege to collaborate with MYHEMP.iO, an avant-garde decentralised ecosystem aiming to streamline the legal hemp and marijuana industry using blockchain technology. The project envisioned a decentralised Exchange (DEX) for Hemp Tokens, facilitating transparency and verification of agricultural origins of hemp and marijuana products. Our team meticulously tackled challenges, including token model implementation and comprehensive marketing campaign execution, ultimately delivering a secure and highly functional blockchain-based platform that caters to the global hemp and marijuana community.",
    stack: [],
    feature: {
      text: "",
      list: [
        {
          heading: "Token Model Implementation:",
          text: " Developed a robust token model for Hemp Tokens to facilitate trading and verification on the MYHEMP platform.",
        },
        {
          heading: "Decentralised Exchange (DEX): ",
          text: "Created a DEX for seamless trading of Hemp Tokens, ensuring transparency and authenticity verification.",
        },
        {
          heading: "Comprehensive Marketing Campaign:",
          text: "Executed an engaging marketing campaign to promote the ICO and attract potential investors.",
        },
        {
          heading: "Real-Time Transparency Panel:",
          text: "Designed a panel to provide real-time transparency on the funding source, bolstering trust and engagement.",
        },
      ],
    },
    problemStatement:
      "The primary hurdles were implementing a token model, ensuring security, and executing a successful token launch alongside an engaging marketing campaign to garner the desired traction and trust from potential investors and industry participants.",
    solution: {
      text: "Utilizing a tech stack comprising REACT JS, Node JS, Python, GraphQL, and the Tradingview library, our team created a user-centric ICO landing page and a well-designed website. Our blockchain experts ensured the platform’s optimal condition and security, providing a seamless and secure user experience for trading and verifying hemp and marijuana products.",
    },
    results: {
      text: "The successful execution of MYHEMP.iO fostered a secure, transparent, and efficient environment for stakeholders within the legal hemp and marijuana industry. The platform now stands as a testament to the potential of blockchain technology in revolutionizing traditional industries, making MYHEMP a pioneering force in the legal hemp and marijuana sector.",
      list: [],
    },
    conclusion:
      "The MYHEMP.iO project showcases how blockchain technology can significantly impact and streamline operations within traditionally complex industries. Pixelette Technologies is proud to have played a pivotal role in bringing MYHEMP.iO’s vision to fruition, marking a significant milestone in the evolving landscape of the legal hemp and marijuana industry.",
  },
  {
    id: 4,
    category: "Blockchain",
    name: "Trading Transformed: PIP Zero’s Journey Towards Advanced Cryptocurrency Trading Solutions",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Case-Study-Mockup-7.png",
    tags: ["Blockchain"],
    projectName: "PIP Zero",
    description:
      "PIP Zero emerges as a cutting-edge cryptocurrency project aimed at offering advanced trading tools and technologies to the global trading community. The cornerstone of this initiative is the PIPZ token, which is pivotal in fostering a global crypto service company dedicated to delivering premier products and services.",
    projectLink: "https://www.pipzero.com/",
    extactiveSummary:
      "Pixelette Technologies had the honor of partnering with PIP Zero, a revolutionary cryptocurrency project, to develop advanced trading tools, including automated trading robots and smart strategies and introducing a proprietary cryptocurrency, PIPZ. The initiative aimed to transform the traditional cryptocurrency trading arena with groundbreaking technologies and solutions. Despite facing security vulnerabilities and scalability issues, our adept team devised effective strategies to overcome these hurdles, resulting in a secure and efficient platform that is a testament to innovation in the cryptocurrency trading domain.",
    stack: [],
    feature: {
      text: "",
      list: [
        {
          heading: "Automated Trading Robots:",
          text: "Development of automated trading robots to facilitate seamless trading operations.",
        },
        {
          heading: "Smart Strategies:",
          text: "Creating intelligent trading strategies to assist traders in making informed decisions.",
        },
        {
          heading: "PIPZ Token: ",
          text: " Introduction of the proprietary PIPZ token to fuel the ecosystem and provide value to the users.",
        },
        {
          heading: "Advanced Analytical Tools:",
          text: " Provision of sophisticated analytical tools to aid market analysis and decision-making",
        },
      ],
    },

    problemStatement:
      "The journey of developing PIP Zero had its challenges. Key issues included security vulnerabilities, low scalability, and high energy consumption. The imperative was to address these challenges head-on to ensure the platform’s robustness and efficiency.",
    solution: {
      text: "Utilizing a blend of technologies and frameworks, including REACT JS, Node JS, Python, GraphQL, and the Tradingview library, our team managed to craft solutions that addressed the identified challenges:    ",
      list: [],
    },
    results: {
      text: "The meticulous efforts culminated in a highly secure, scalable, and efficient blockchain website now serving as a robust platform for cryptocurrency trading enthusiasts. The positive feedback from clients is a testament to the quality and functionality of the platform, making PIP Zero a significant player in the crypto trading landscape.",
      list: [],
    },
    conclusion:
      "The successful development of PIP Zero highlights the transformative power of innovative technologies in the cryptocurrency trading domain. Pixelette Technologies is proud to have contributed to this pioneering initiative, showcasing advanced trading tools and technologies’ potential to foster a more efficient and secure trading environment.",
    techinialSolutionList: [
      {
        heading: "Security Measures:",
        text: "Implemented stringent security measures to thwart criminal connections and secure the platform.",
      },
      {
        heading: "Scalability Enhancement: ",
        text: " Enhanced scalability to ensure smooth transaction processing and improved user experience.",
      },
      {
        heading: "Energy Consumption Reduction:",
        text: "Adopted consensus algorithms to minimize energy consumption, making the platform more eco-friendly.",
      },
    ],
  },
  {
    id: 5,
    category: "Blockchain",
    name: "Empowering Financial Transparency: The Beyorch Digital Cryptocurrency Lending Platform Development",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Case-Study-Mockup.png",
    tags: ["Blockchain"],
    projectName: "Beyorch Digital",
    description:
      "Beyorch Digital, adhering to the successful business model of Beyorch, Inc., embarked on a journey to create a cryptocurrency lending platform. The objective was to provide a transparent and efficient lending avenue to a diverse clientele by harnessing the power of blockchain technology, smart contracts, and algorithmic processes.    ",
    projectLink: "https://beyorch.com/",
    extactiveSummary:
      "Pixelette Technologies proudly collaborated with Beyorch Digital, a subsidiary of Beyorch, Inc., to develop a cryptocurrency lending platform catering to a broad spectrum of clients, including small businesses, corporations, and major institutions. Following its parent company’s business model, Beyorch Digital sought to leverage advanced technologies like blockchain and smart contracts to enhance transparency and efficiency. Despite facing security, scalability, and energy consumption challenges, our proficient team devised robust solutions, ensuring a secure, scalable, and energy-efficient platform that now stands as a noteworthy achievement in our blockchain project portfolio.",
    stack: [],
    feature: {
      text: "",
      list: [
        {
          heading: "Cryptocurrency Lending Platform:",
          text: "Development of a platform for lending cryptocurrency to diverse clients.",
        },
        {
          heading: "Blockchain Integration: ",
          text: "Incorporation of blockchain technology to ensure transparency and security.",
        },
        {
          heading: "Smart Contracts:",
          text: "Implementation of smart contracts to automate the lending process and ensure compliance.",
        },
        {
          heading: "Algorithmic Processes:",
          text: "Deployment of algorithmic processes to optimize operations.",
        },
      ],
    },
    problemStatement:
      "The development journey unveiled challenges like potential criminal connections, security vulnerabilities, scalability limitations, and high energy consumption. These issues posed significant threats to the project’s success and required meticulous attention and robust solutions.    ",
    solution: {
      text: "Utilizing a combination of technologies and frameworks, including Laravel PHP, MySQL, AJAX, and WEB3.JS, our team managed to tackle the identified challenges effectively:    ",
      list: [],
    },
    results: {
      text: "The meticulous efforts culminated in a highly secure, scalable, and efficient cryptocurrency lending platform, showcasing exceptional design and functionality. The positive feedback from the client and the seamless operation of the platform underscores the success of this endeavor.",
      list: [],
    },
    conclusion:
      "The successful development and deployment of the Beyorch Digital platform underline the transformative potential of blockchain technology in the financial lending sector. Pixelette Technologies is honored to have played a pivotal role in this project, delivering a platform that meets the client’s objectives and contributes to the broader adoption of blockchain technology in the financial industry.    ",
    techinialSolutionList: [
      {
        heading: "Security Enhancements",
        text: "Fortified security measures to prevent cyberattacks and ensure a secure platform.",
      },
      {
        heading: "Scalability Improvements: ",
        text: " Addressed scalability limitations to ensure smooth transactions and a better user experience.",
      },
      {
        heading: "Energy Consumption Reduction: ",
        text: "Implemented consensus algorithms to reduce energy consumption, aligning with eco-friendly practices.",
      },
    ],
  },
  {
    id: 6,
    category: "Blockchain",
    name: "Pioneering Asset Liquidity: The Creation of ‘Investing Together’ Marketplace",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Case-Study-Mockup-2.png",
    tags: ["Blockchain"],
    projectName: "Investing Together",
    description:
      "In the era of digital finance, asset tokenization emerges as a robust solution for asset management and investment. “Investing Together” is an innovative marketplace that endeavors to bridge the conventional asset market with modern blockchain technology, offering a seamless platform for trading tokenized assets.",
    projectLink: "https://investing.ptlearningzone.com/",
    extactiveSummary:
      "Pixelette Technologies spearheaded the development of “Investing Together,” a groundbreaking marketplace designed to revolutionise asset ownership through tokenization. The platform provides a fluid trading avenue for investors to buy and sell tokenized assets alongside opportunities to earn dividends. The transformation of illiquid assets into fully liquid ones facilitates fractional ownership across various asset types, including real estate and farmland. Moreover, Investing Together assists enterprises in creating and selling tokens representing their real-world assets online, embodying simplicity and efficiency.",
    stack: [],
    feature: {
      text: "",
      list: [
        {
          heading: "NFT Marketplace: ",
          text: "A digital marketplace for trading tokenized assets.",
        },
        {
          heading: "Smart Contracts (ERC 721 & ERC 1155)",
          text: " Backend contracts ensuring secure transactions and asset tokenization.",
        },
        {
          heading: "NFT Staking Module: ",
          text: "A feature allowing investors to stake their NFTs.",
        },
        {
          heading: "Wallet Connect",
          text: "Secure wallet connection for managing digital assets.",
        },
        {
          heading: "KYC: ",
          text: "Know Your Customer module ensuring user authenticity.",
        },
        {
          heading: "NFT Listing and Minting:",
          text: "Features for listing and creating new NFTs.",
        },
        {
          heading: "User Management:",
          text: "A robust system for managing user profiles and activities.",
        },
        {
          heading: "Admin Panel:",
          text: "A comprehensive admin panel for managing the platform.",
        },
      ],
    },
    problemStatement:
      "Traditional asset markets often encumber investors with illiquid assets, making buying and selling cumbersome. Additionally, fractional ownership of high-value assets like real estate is nearly impossible. Enterprises also face challenges in moving their assets to an online platform.",
    solution: {
      text: "Utilizing React JS for website development, ERC 721 and ERC 1155 for smart contract development, and other cutting-edge technologies, Pixelette Technologies ensured a robust, secure, and efficient platform that caters to modern investors’ needs.",
      list: [],
    },
    results: {
      text: "Investing Together is a robust platform enabling fluid trading of tokenized assets, making asset ownership more accessible and manageable. Enterprises can effortlessly tokenize and list their assets, opening new revenue channels and better asset management.",
      list: [],
    },
    conclusion:
      "The successful development of Investing Together by Pixelette Technologies marks a significant stride towards modernized asset management and trading. It embodies how blockchain technology can be leveraged to solve traditional market challenges, heralding a new era of digital asset management.",
  },
  // {
  //   id: 7,
  //   category: "blockchain",
  //   name: "Shining Bright in the Digital Realm: The Development of DIAMOND NXT for Revolutionary Diamond Trading    ",
  //   heroImage:
  //     "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Case-Study-Mockup-6.png",
  //   tags: ["Blockchain"],
  //   projectName: "Diamond NXT",
  //   description:
  //     " DIAMOND NXT stands as a beacon of innovation in the diamond industry, aiming to redefine how diamonds are traded and owned. By embracing blockchain technology, DIAMOND NXT provides a secure, transparent, and efficient platform for stakeholders to explore the new horizons of digital diamond trading.",
  //   projectLink: "https://www.diamondnxt.com/",
  //   extactiveSummary:
  //     " Pixelette Technologies played a pivotal role in sculpting the digital facet of the diamond industry through the development of DIAMOND NXT. This cutting-edge platform merges the quintessence of diamonds with modern blockchain technology, offering a unique marketplace for diamond trading and tokenization. The robust digital and technological infrastructure established by Pixelette Technologies provides a cornerstone for DIAMOND NXT’s vision of reshaping the future of diamonds, easing the journey for all stakeholders in the diamond industry.",
  //   stack: [],
  //   feature: [
  //     {
  //       heading: "NFT Marketplace:",
  //       text: "A specialized marketplace for trading tokenized diamonds.",
  //     },
  //     {
  //       heading: "Smart Contracts (ERC 721 & ERC 1155): ",
  //       text: "Backbone contracts for secure and transparent transactions.",
  //     },
  //     {
  //       heading: "Wallet Connect: ",
  //       text: "A feature for secure wallet connections, enabling seamless transactions.",
  //     },
  //     {
  //       heading: "KYC: ",
  //       text: "Know Your Customer module for user verification and platform integrity.",
  //     },
  //     {
  //       heading: "NFT Listing and Minting:",
  //       text: "Facilities for listing and creating new tokenized diamond assets.",
  //     },
  //     {
  //       heading: "User Management:",
  //       text: "Robust user management system for profile and transaction management.",
  //     },
  //     {
  //       heading: "Admin Panel: ",
  //       text: " Comprehensive admin panel for overseeing platform operations.",
  //     },
  //     {
  //       heading: "Content Management System:",
  //       text: "A system for managing and updating platform content dynamically.",
  //     },
  //   ],
  //   problemStatement:
  //     "The traditional diamond industry often grapples with issues related to transparency, liquidity, and accessibility. The mission was to create a platform that addresses these challenges and adds a layer of modernity and efficiency to diamond trading and ownership.",
  //   solution:
  //     "Utilizing React JS for web development, ERC 721, and ERC 1155 for smart contract creations, alongside other state-of-the-art technologies, Pixelette Technologies built a robust platform capable of meeting the modern demands of the diamond industry.",
  //   results:
  //     "DIAMOND NXT now serves as a revolutionary platform that transforms how diamonds are traded and broadens the scope of ownership and investment in the diamond sector. The platform’s integrity and transparency have significantly impacted the diamond journey, making it more accessible and lucrative.",
  //   conclusion:
  //     "The successful deployment of DIAMOND NXT by Pixelette Technologies manifests a significant advancement in bridging traditional diamond trading with modern digital technology. It exemplifies the remarkable potential of blockchain technology in transforming conventional industries.",
  // },

  // App Development
  {
    id: 101,
    category: "Application Development",
    name: "Million Mart Mobile Marketplace",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/Million-Mart.png",
    tags: ["App development"],
    projectName: "Million Mart Mobile Marketplace",
    description:
      "Pixelette Technologies embarked on a project with Million Mart to develop an inclusive online marketplace that simplifies online shopping for users.",
    projectLink: "",
    extactiveSummary:
      "Million Mart Mobile Marketplace, developed by Pixelette Technologies, is an advanced online shopping platform designed for a seamless shopping experience. The objective was to create a user-friendly mobile app with a wide range of products from various vendors.",
    stack: [],
    objectives: {
      text: "",
      list: [
        {
          heading: "",
          text: "Develop an accessible mobile application.",
        },
        {
          heading: "",
          text: "Create a robust multi-vendor platform with diverse products.",
        },
        {
          heading: "",
          text: "Design a user-friendly interface for vendors and customers.",
        },
      ],
    },
    feature: {
      text: "",
      list: [],
    },
    problemStatement: "",
    solution: {
      text: "",
      list: [
        {
          heading: "",
          text: "Crafted an intuitive interface with advanced categorization and search features.",
        },
        {
          heading: "",
          text: "Built a comprehensive multi-vendor system for easy vendor management.",
        },
        {
          heading: "",
          text: "Integrated secure payment gateways and trust badges for safety.          ",
        },
      ],
    },
    challenges: {
      list: [
        {
          text: "Managing over 5000+ product listings for smooth navigation.",
        },
        {
          text: "Developing multi-vendor capabilities with unique dashboards.",
        },
        {
          text: "Ensuring a secure shopping environment.",
        },
      ],
    },

    results: {
      list: [
        {
          text: "Launched a multi-vendor app with 5000+ products.",
        },
        {
          text: "Delivered an easy-to-use interface for efficient shopping.",
        },
      ],
    },
    conclusion:
      "Million Mart praised the functionality, design, and performance of the marketplace app. Pixelette Technologies successfully developed the Million Mart Mobile Marketplace, offering a diverse and efficient online shopping solution. This project stands as a testament to their expertise in creating comprehensive digital platforms.",
  },
  {
    id: 102,
    category: "Application Development",
    name: "Laundryheap Mobile Application",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/laundry-heap.png",
    tags: ["App development"],
    projectName: "Laundryheap Mobile Application",
    description:
      "Laundryheap engaged Pixelette Technologies to create an accessible platform for laundry services, addressing the need for a time-saving laundry solution.",
    projectLink: "",
    extactiveSummary:
      "  Pixelette Technologies developed the Laundryheap mobile app, a comprehensive platform for a range of laundry services, including washing, ironing, dry cleaning, and duvet cleaning. This app facilitates easy scheduling of pickups and deliveries, enhancing the convenience of laundry services.",
    stack: [],
    objectives: {
      text: "Create a user-friendly app for scheduling various laundry services.      ",
      list: [
        {
          heading: "",
          text: "",
        },
      ],
    },
    feature: {
      text: "",
      list: [
        {
          heading: "",
          text: "",
        },
      ],
    },
    problemStatement: "",
    solution: "",
    results: {
      text: "Developed an intuitive interface with clear service options.",
      list: [],
    },
    conclusion: "",
  },

  // {
  //   id: 201,
  //   category: "ai",
  //   name: "",
  //   heroImage: "",
  //   tags: ["AI/ML/BI"],
  //   projectName: "",
  //   description: "",
  //   projectLink: "",
  //   extactiveSummary: "",
  //   stack: [],
  //   objectives: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   feature: {
  //     text: "",
  //     list: [],
  //   },
  //   problemStatement: "",
  //   solution: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   challenges: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },

  //   results: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },
  //   conclusion: "",
  // },

  // AI
  {
    id: 201,
    category: "Artificial Intelligence",
    name: "Adwatch Engine: Automated Ad Detection and Removal System",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2023/11/Case-Study-Mockup-28.png",
    tags: ["AI/ML/BI"],
    projectName: "Adwatch Engine",
    description:
      "Adwatch aimed to create a comprehensive workflow for automatic audio and video content detection, particularly focusing on advertisements and promos within TV input streams. The goal was to ensure uninterrupted monitoring of media news campaigns.    ",
    projectLink: "",
    extactiveSummary:
      "Pixelette Technologies developed the Adwatch Engine for automated ad detection and removal from TV input streams. Utilizing a Linux-Python stack and employing Audfprint, ffmpeg, and moviepy for processing, along with a fusion of VGG-16 and Audfprint for accurate ad detection, the project successfully achieved efficient ad removal. This system serves as a robust tool for monitoring media campaigns by eliminating interruptions caused by commercials, thereby ensuring a streamlined workflow for content analysis.",
    stack: [],
    objectives: {
      text: "",
      list: [
        {
          text: "Develop an efficient ad detection system using a dataset of image and audio files.",
        },
        {
          text: "Implement a workflow for processing video files, detecting and removing ads, and outputting ad-free video streams.",
        },
        {
          text: "Integrate the developed solution into a system for end-to-end automated ad removal.",
        },
      ],
    },
    feature: {
      text: "",
      list: [],
    },
    problemStatement: "",
    solution: {
      text: "",
      list: [
        {
          text: "  Utilized ffmpeg and moviepy for accurate video-to-audio conversion and processing.    ",
        },
        {
          text: "Employed a fusion of Audfprint and a fine-tuned VGG-16 model for precise ad detection.",
        },
        {
          text: "Implemented an efficient ad removal process ensuring the output video remains intact and of high quality",
        },
      ],
    },
    challenges: {
      list: [
        {
          text: "Accurate conversion of video files to audio for processing.",
        },
        {
          text: "Efficient ad detection by matching fingerprints and utilizing CNN for additional frame detection.",
        },
        {
          text: "Seamless removal of ads from the original input without affecting the video quality.",
        },
      ],
    },

    results: {
      list: [
        {
          text: "",
        },
        {
          text: "",
        },
      ],
    },
    timeLines: [
      "Phase 1: Dataset Generation and Pre-processing",
      "Phase 2: Training and Fine-tuning VGG-16 Model",
      "Phase 3: Audfprint Database Generation",
      "Phase 4: Fusion of Audfprint and VGG-16 for Ad Detectio",
      "Phase 5: Testing and Evaluation",
    ],
    lesson:
      "Accurate dataset generation and effective fusion of audio and visual processing techniques are crucial for successful ad detection and removal.",
    clientFeedBack:
      "Adwatch appreciated the accuracy and efficiency of the Adwatch Engine in automating ad detection and removal, which significantly streamlined their media monitoring processes.",
    conclusion:
      "The Adwatch Engine developed by Pixelette Technologies successfully addressed Adwatch’s need for an automated ad detection and removal system. The project demonstrated the effectiveness of employing a combination of audio fingerprinting and visual processing in automating content filtering, significantly improving the efficiency of monitoring media campaigns.",
  },
  {
    id: 202,
    category: "Artificial Intelligence",
    name: "DPHQ Parking Dashboard Development",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2023/11/Case-Study-Mockup-29.png",
    tags: ["AI/ML/BI"],
    projectName: "DPHQ Parking",
    description:
      "Publicis Media sought a dedicated dashboard to analyze and manage the usage of their basement parking effectively. The goal was to create a tool that would allow for a systematic distribution of parking slots among different units based on usage analysis.    ",
    projectLink: "",
    extactiveSummary:
      "Pixelette Technologies developed the DPHQ Parking Dashboard for Publicis Media to facilitate efficient basement parking usage analysis and slot distribution. Utilizing a stack of MS Excel, SharePoint, and PowerBI, the dashboard was crafted based on specific requirements, refined through client feedback, and completed in approximately two weeks. The deployed dashboard now serves as an essential daily tool for managing parking slot distribution at Publicis Media.",
    stack: [],
    objectives: {
      text: "",
      list: [
        {
          text: "Develop a comprehensive parking usage analysis dashboard.",
        },
        {
          text: "Facilitate efficient management and distribution of parking slots.",
        },
        {
          text: "Ensure the dashboard is user-friendly and provides actionable insights for daily usage.",
        },
      ],
    },
    feature: {
      text: "",
      list: [],
    },
    problemStatement: "",
    solution: {
      text: "",
      list: [
        {
          text: "Utilized a combination of MS Excel, SharePoint, and PowerBI to accurately collect, process, and visualize parking usage data.",
        },
        {
          text: "Developed various visualizations based on client requirements and refined them through iterative feedback.",
        },
        {
          text: "Ensured seamless deployment and integration of the dashboard within Publicis Media’s system for daily use.",
        },
      ],
    },
    challenges: {
      list: [
        {
          text: "Accurate collection and representation of parking usage data.",
        },
        {
          text: "Creating intuitive visualizations that cater to client requirements.",
        },
        {
          text: "Seamless integration with Publicis Media’s existing systems.          ",
        },
      ],
    },

    results: {
      list: [
        {
          text: "Improved parking slot distribution efficiency by over 30%.",
        },
        {
          text: "Reduced time spent on manual parking management significantly.",
        },
      ],
    },
    timeLines: [
      "Phase 1: Requirement Gathering and Initial Design (1 week)",
      "Phase 2: Development and Client Feedback (1 week)",
      "Phase 3: Deployment and Integration",
    ],
    riskManagement:
      "Identified risks like data accuracy and system integration. Implemented robust data validation processes and ensured smooth integration with existing systems.",

    composition: [
      "Project Manager",
      "Data Analysts",
      "Backend Developers",
      "Frontend Developers",
      "Quality Assurance Engineers",
    ],
    lesson:
      "Effective client feedback loops are crucial for refining visualizations to meet the exact needs.",
    conclusion:
      "The DPHQ Parking Dashboard developed by Pixelette Technologies successfully addressed Publicis Media’s need for efficient parking usage analysis and slot distribution. The project showcased the effectiveness of utilizing a combination of MS Excel, SharePoint, and PowerBI in creating a useful tool for daily operational management, significantly improving the efficiency of parking slot distribution at Publicis Media.",
    clientFeedBack:
      "Publicis Media expressed satisfaction with the dashboard’s ease of use and the actionable insights it provides for managing parking slots efficiently.",
  },
  // {
  //   id: 201,
  //   category: "ai",
  //   name: "",
  //   heroImage: "",
  //   tags: ["AI/ML/BI"],
  //   projectName: "",
  //   description: "",
  //   projectLink: "",
  //   extactiveSummary: "",
  //   stack: [],
  //   objectives: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   feature: {
  //     text: "",
  //     list: [],
  //   },
  //   problemStatement: "",
  //   solution: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   challenges: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },

  //   results: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },
  //   conclusion: "",
  // },
  // {
  //   id: 201,
  //   category: "ai",
  //   name: "",
  //   heroImage: "",
  //   tags: ["AI/ML/BI"],
  //   projectName: "",
  //   description: "",
  //   projectLink: "",
  //   extactiveSummary: "",
  //   stack: [],
  //   objectives: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   feature: {
  //     text: "",
  //     list: [],
  //   },
  //   problemStatement: "",
  //   solution: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   challenges: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },

  //   results: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },
  //   conclusion: "",
  // },
  // {
  //   id: 201,
  //   category: "ai",
  //   name: "",
  //   heroImage: "",
  //   tags: ["AI/ML/BI"],
  //   projectName: "",
  //   description: "",
  //   projectLink: "",
  //   extactiveSummary: "",
  //   stack: [],
  //   objectives: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   feature: {
  //     text: "",
  //     list: [],
  //   },
  //   problemStatement: "",
  //   solution: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   challenges: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },

  //   results: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },
  //   conclusion: "",
  // },
  // {
  //   id: 201,
  //   category: "ai",
  //   name: "",
  //   heroImage: "",
  //   tags: ["AI/ML/BI"],
  //   projectName: "",
  //   description: "",
  //   projectLink: "",
  //   extactiveSummary: "",
  //   stack: [],
  //   objectives: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   feature: {
  //     text: "",
  //     list: [],
  //   },
  //   problemStatement: "",
  //   solution: {
  //     text: "",
  //     list: [
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //       {
  //         heading: "",
  //         text: "",
  //       },
  //     ],
  //   },
  //   challenges: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },

  //   results: {
  //     list: [
  //       {
  //         text: "",
  //       },
  //       {
  //         text: "",
  //       },
  //     ],
  //   },
  //   conclusion: "",
  // },

  // Digital Marketing
  {
    id: 201,
    category: "Digital Marketing",
    name: "Illuminating Success: Elevating CAST Lighting’s Perimeter Security Solutions Through Multifaceted Digital Marketing",
    heroImage:
      "https://cdn-bnmmm.nitrocdn.com/YUvChxRvcTprTfRRMCsflKVCpVauhGnj/assets/images/optimized/rev-3a39ec4/www.pixelettetech.com/wp-content/uploads/2022/07/image-933.png",
    tags: ["Digital Marketing"],
    projectName: "CAST Lighting's",
    description:
      "CAST Lighting pioneers in providing advanced perimeter security lighting solutions, designed meticulously to align with the needs of security guards, surveillance systems, and outdoor environments. Their disruptive technology redefines perimeter security by mounting lighting fixtures directly onto existing infrastructure, thus drastically cutting down installation time and costs.",
    projectLink: "https://cast-lighting.com/",
    extactiveSummary:
      "Pixelette Technologies collaborated with CAST Lighting to amplify the reach and impact of their innovative perimeter security lighting solutions. Leveraging a holistic digital marketing strategy encompassing SEO, direct outreach, lead generation, and social media marketing (SMM), we propelled CAST Lighting into a higher realm of online visibility and customer engagement. The project not only highlighted the disruptive technology offered by CAST Lighting but also significantly bolstered lead generation and fostered a robust online community around their offerings.",
    goalofProject:
      "  The primary aim was to elevate CAST Lighting’s online presence, generate qualified leads, and establish a resonant brand identity that underscores their technological innovation in perimeter security.",
    stack: [],
    marketAnalysis: [{}],

    objectives: {
      text: "",
      list: [
        {
          heading: "",
          text: "",
        },
        {
          heading: "",
          text: "",
        },
        {
          heading: "",
          text: "",
        },
      ],
    },
    feature: {
      text: "",
      list: [],
    },
    problemStatement: "",
    solution: {
      text: "",
      list: [
        {
          heading: "",
          text: "",
        },
        {
          heading: "",
          text: "",
        },
        {
          heading: "",
          text: "",
        },
      ],
    },
    challenges: {
      text: "In a market previously dominated by adapted lighting solutions, CAST Lighting’s innovative offerings faced the challenge of gaining visibility and demonstrating their unique value proposition. The endeavor was to effectively reach and convince prospective customers of the substantial cost savings and enhanced security provided by their solutions.",
      list: [
        {
          text: "",
        },
        {
          text: "",
        },
        {
          text: "",
        },
      ],
    },

    results: {
      list: [
        {
          text: "",
        },
        {
          text: "",
        },
      ],
    },
    conclusion:
      "The collaboration between Pixelette Technologies and CAST Lighting successfully elevated the brand’s digital presence, generated a consistent flow of qualified leads, and fostered a thriving online community. The project not only showcased the disruptive innovation by CAST Lighting but also set a benchmark in leveraging digital marketing to highlight the real-world benefits of pioneering technology in the realm of perimeter security.",
  },
];
