import assets from "../assets";

export const swiperSliderData = [
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
  {
    image: assets.home.swiper_1,
    name: "Medical",
    description:
      "Enhance the patient experience and set your practice apart by utilizing cutting-edge medical technology.",
  },
];
