export const webSpecialData = [
  {
    heading: "Government and Industry Recognition",
    text: "Recognised by industry leaders and government bodies for excellence in web development, official secretariat for the prestigious All Party Parliamentary Groups on AI and Blockchain in the UK.",
  },
  {
    heading: "World-Class Development Team",
    text: "Boasting a diverse team of over 200 experts, including top industry talent recognised globally. Our senior management featured in the top 1% globally in their respective fields.",
  },
  {
    heading: "Global Impact and Expertise",
    text: "With over 200 web development projects and more than 30,000 hours dedicated to crafting bespoke digital experiences, our reach extends across 13 countries, bringing local expertise to global markets.",
  },
  {
    heading: "Proven Results",
    text: "Our web solutions have transformed operations for major brands, enhancing user engagement and digital strategy effectiveness across various sectors for brands like Dodge, Fiat, Sandoz, and more.",
  },
  {
    heading: "Cost-Effectiveness",
    text: "We deliver premium web development services at competitive pricing, maximizing your ROI without compromising on quality or innovation.",
  },
  {
    heading: "Unmatched Success",
    text: "Our commitment to excellence has made us a benchmark in the industry, with a portfolio featuring high-profile success stories and award-winning web solutions.u",
  },
];
