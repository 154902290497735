import assets from "../../assets";

export const technologyStackWebDevelopmentData = [
  assets.webDevelopment.tsw_1,
  assets.webDevelopment.tsw_2,
  assets.webDevelopment.tsw_3,
  assets.webDevelopment.tsw_4,
  assets.webDevelopment.tsw_5,
  assets.webDevelopment.tsw_6,
  assets.webDevelopment.tsw_7,
  assets.webDevelopment.tsw_8,
  assets.webDevelopment.tsw_9,
  assets.webDevelopment.tsw_10,
  assets.webDevelopment.tsw_11,
  assets.webDevelopment.tsw_12,
  assets.webDevelopment.tsw_13,
  assets.webDevelopment.tsw_14,
  assets.webDevelopment.tsw_15,
  assets.webDevelopment.tsw_16,


];
