import Components from "../..";
import assets from "../../../assets";
import { Link } from "react-router-dom";

const BannerSection = () => {
  return (
    <>
      <div className="bannerSection-container">
        <Components.Feature.Container className="main margins">
          <center>
            <Components.Feature.Heading className="secondry">
              Visionaries at the Helm
            </Components.Feature.Heading>
          </center>
          <div className="bannerSection">
            <section>
              <img src={assets.commonAssests.Gulnaz} alt="banner" />
              <header>
                <Components.Feature.Text className="titory--bold">
                  Introducing Ms. Gulnaz Akhtar, a distinguished leader with
                  extensive experience in program, performance, and change
                  management. As Director of Transformational Recovery at NHS
                  England, Ms. Akhtar excels at driving strategic initiatives
                  and overseeing complex projects. Her exceptional ability to
                  foster multi agency partnerships and implement national
                  policies has significantly advanced healthcare innovation.
                  With a career spanning over a decade, Ms. Akhtars leadership
                  ensures that Pixelette Technologies benefits from her deep
                  expertise and visionary approach.
                </Components.Feature.Text>
                <Components.Feature.Text className="primary--bold">
                  Ms. Gulnaz Akhtar
                </Components.Feature.Text>
                <Components.Feature.Text className="titory">
                  Chairwoman
                </Components.Feature.Text>
                
                <Link to="https://www.linkedin.com/in/rana-khan-asif/"  target="_blank">
                <Components.Feature.Button className="primary">
                  Connect with the Chairwoman
                </Components.Feature.Button></Link>
              </header>
            </section>
            <section>
              <header>
                <Components.Feature.Text className="titory--bold">
                  Introducing Mr. Asif Ashiq Rana, a distinguished leader in
                  technology and real estate. As Founder & CEO of Pixelette
                  Technologies and Managing Director at the Big Innovation
                  Centre, Mr. Rana is not only a trailblazer in AI and
                  blockchain but also ranks in the top 1% globally in AI on
                  LinkedIn. With a career highlighted by eight successful exits,
                  his strategic foresight has positioned Pixelette Technologies
                  at the vanguard of industry innovation. His hands-on approach
                  and commitment to mentoring encompass a broad spectrum of
                  industries, where he empowers companies to harness the
                  transformative power of advanced technologies. Mr. Rana’s
                  leadership assures that partnering with Pixelette Technologies
                  means engaging with a legacy of excellence and
                  forward-thinking solutions.
                </Components.Feature.Text>
                <Components.Feature.Text className="primary--bold">
                  Mr. Asif Ashiq Rana
                </Components.Feature.Text>
                <Components.Feature.Text className="titory">
                Chief Executive Officer
                </Components.Feature.Text>
                
                <Link to="https://www.linkedin.com/in/rana-khan-asif/" target="_blank">
                <Components.Feature.Button className="primary">
                Connect with the CEO
                </Components.Feature.Button>
                </Link>
              </header>
              <img src={assets.aboutUs.Asif} alt="banner" />
            </section>
          </div>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default BannerSection;
