import React from "react";
import Components from "../..";
import assets from "../../../assets";

const HeroSectionCareerDetail = (props) => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSectionCareerDetail-background">
          <img src={assets.policies.careerDetailBackground} alt="background" />
        </div>
      </Components.Feature.Container>

      <div className="heroSectionCareerDetail">
        <center>
          <Components.Feature.Heading className="heroHeading">
            {props.title}
          </Components.Feature.Heading>
          <Components.Feature.Text className="secondry">
            {props.location} | Posted on {props.postedDate}
          </Components.Feature.Text>
        </center>
      </div>
    </>
  );
};

export default HeroSectionCareerDetail;
