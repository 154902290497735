import React from "react";
import Components from "../components";
import data from "../data";
import assets from "../assets";

const PrivacyPolicy = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="privacyPolicy-background">
          <img
            src={assets.policies.privacyPoliciesBackGround}
            alt="background"
          />
        </div>
      </Components.Feature.Container>
      <Components.Feature.Container className="main margins">
        <div className="privacyPolicy">
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              Privacy Policy
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="zoom-in"
              duration="2200"
            >
              Pixelette Technologies is committed to protecting your personal
              information and respecting your privacy. This Privacy Policy
              explains how we collect, use, store and share your personal
              information when you visit our website or use our services. This
              policy is compliant with the General Data Protection Regulation
              (GDPR) of the European Union and the Data Protection Act 2018 of
              the United Kingdom.
            </Components.Feature.Text>
          </center>
          <Components.Common.DetailsNavigate
            data={data.privacyPolicy}
            headingIndex={true}
            overViewIndex={true}
            headerSection={false}
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default PrivacyPolicy;
