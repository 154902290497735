import { useState, useEffect } from "react";
import Routess from "./Routes";
import Aos from "aos";
import RefreshOnMobile from "./components/ui/mobile/RefreshOnMobile";


function App() {
  useEffect(() => {
    <div></div>;
    Aos.init({
      disable: "", // Disable animations on mobile devices
      offset: 50,
      delay: 0,
      duration: 800,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  });

  return (
    <>
      <Routess />
    </>
  );
}

export default App;
