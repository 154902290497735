import React from "react";
import Components from "..";
import assets from "../../assets";

const ServiceWork = () => {
  return (
    <div className="SelectPlan" style={{marginTop: '100px'}}>
      <Components.Feature.Container className="main">
        <center data-aos-duration={`700`} data-aos="fade-up">
          <Components.Feature.Heading className="primary " id="h_ani">
          Here’s How Our Services Work          
          </Components.Feature.Heading>
          <Components.Feature.Button className="primary--light btn-sm mt-2">Book a Free Call</Components.Feature.Button>
        </center>
        <div data-aos-duration={`500`} data-aos="fade-up">
          <Cards />
        </div>
      </Components.Feature.Container>
    </div>
  );
};

const Cards = () => {

  return (
    <>
    <div className="serviceWorkCards">
    <section>
        <h1>01</h1>
        <h3>Free Discovery Call</h3>
        <h5>We chat about your project scope, service type, <br /> tech requirements, timeline, suitable budget <br /> - everything.</h5>
    </section>
    <section>
        <h1>02</h1>
        <h3>Proposal Creation</h3>
        <h5>Then we iron out the details, send you over <br /> everything we’ve meticulously planned for <br /> your project.</h5>
    </section>
    <section>
        <h1>03</h1>
        <h3>Kickoff</h3>
        <h5>If it’s a match, we hit start on whichever <br /> service you’ve opted for and deliver quick!</h5>
    </section>
    </div>
    </>
  );
};

export default ServiceWork;
