import React from "react";
import Components from "..";
import assets from "../../assets";
import data from "../../data";
import { v4 as uuidv4 } from "uuid";
const WhatMakeUsSpecial = (props) => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="specialSection-background">
          <img src={assets.aboutUs.howWeWorkBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="specialSection">
        <center>
          <Components.Feature.Heading
            className="secondry"
            animation="fade-up"
            duration={`400`}
            id="h_ani"
          >
            {props.heading ? props.heading : "What Makes Us Special?"}
          </Components.Feature.Heading>
          <Components.Feature.Text
            className="secondry"
            // animation="fade-up"
            // duration={`500`}
          >
            {props.text
              ? "At Pixelette Technologies, our commitment to innovation and excellence sets us apart. Here’s why clients choose us:"
              : "At Pixelette Technologies, our commitment to innovation and excellence sets us apart. Here’s why clients choose us:"}
          </Components.Feature.Text>
        </center>
        <Components.Feature.Container className="main margins">
          <section>
            <div>
              <div>
                {props.data
                  ? props.data.map((el) => (
                      <Item
                        key={uuidv4()}
                        heading={el.heading}
                        text={el.text}
                      />
                    ))
                  : data.whatMakeSpecialData.map((el) => (
                      <Item
                        key={uuidv4()}
                        heading={el.heading}
                        text={el.text}
                      />
                    ))}
              </div>
            </div>
            <img
              src={assets.commonAssests.specialSecitonHeroImage}
              alt="heroImage"
              data-aos="fade-up"
              data-aos-duration={`600`}
            />
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default WhatMakeUsSpecial;

const Item = (props) => {
  return (
    <>
      <div>
        <section>
          <img src={assets.commonAssests.dot} alt="dot" />
        </section>
        <div>
          <Components.Feature.Text
            className="primary--bold"
            // animation="fade-up"
            // duration={`600`}
          >
            {props.heading}
          </Components.Feature.Text>
          <Components.Feature.Text
            className="titory--bold"
            // animation="fade-up"
            // duration={`400`}
          >
            {props.text}
          </Components.Feature.Text>
        </div>
      </div>
    </>
  );
};
