import React from "react";
import Components from "../components";
import data from "../data";
import assets from "../assets";

const Deliver = () => {
  return (
    <>
      <Components.Common.HeroSectionAiServices
      title="From Concept to Completion, We Deliver"
      description="We handle every step of development so you can concentrate on growth"
      cardtitle="How It Works?"
      carddescription1="Our complete outsourcing services take the entire software development process off your hands. We manage every aspect, from initial planning and design to development, testing, and deployment."
      carddescription2="Once you provide your requirements and objectives, our team takes full control, assembling the right resources and managing the entire project. We work closely with you to ensure alignment with your vision, but the day-to-day execution is our responsibility."
      carddescription3="You can expect a high-quality product delivered on time and within budget, without the need to manage the complexities of development themselves. You’ll have a ready-to-use solution tailored to your business needs, allowing you to focus on growth and strategy."
      cardimage={assets.home.deliver1}
       />
      <Components.Common.DeliverBenifits
      title=<p>Benefits of Complete Development <br/> Outsourcing with Us</p>
      title1="Complete Project Ownership"
      description1="Your project is our responsibility, allowing you to concentrate on other aspects of your business"
      title2="Expert Management"
      description2="Your project is delivered on time and within budget with the help of dedicated project managers"
      title3="Reduced Costs"
      description3="You don’t need to invest in additional infrastructure, tools, talent or management; we handle everything"
      title4="High-Quality Talent Pool"
      description4="You can access our experienced team that delivers fully tested, high-quality products ready for deployment"
       />
      <Components.Common.SelectPlan />
      <Components.Common.ServiceWork />
      <Components.Common.DeliverFaqs/>
      {/* <Components.Common.ContactUs /> */}
      {/* <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default Deliver;
