export const deliverFaqs = [
  {
    question:
      "Is software development outsourcing common among businesses?",
    answer:
      "Yes, software development outsourcing is highly common among businesses. According to a Deloitte survey, 70% of companies outsource to reduce costs, and 57% do so to focus more on their core business functions.",
  },
  {
    question: "Do you offer custom development services?",
    answer:
      "Absolutely. Our team can work with you to design, develop, and deliver a solution that aligns perfectly with your goals and requirements. Whether it's a unique software application, a specialised feature, or a complete system.",
  },
  {
    question:
      "What domains are covered in your development services?",
    answer:
      "Our development services cover a wide range of domains, including AI, blockchain, web development, mobile app development, software solutions, and more. We have the teams and domain expertise to tackle all kinds of development tasks.",
  },
  {
    question: "What is included in your outsourcing development service?",
    answer:
      "Our service covers the entire software development lifecycle, including planning, design, development, testing, and deployment - all in all a comprehensive solution from start to finish.",
  },
  {
    question: "How involved do I need to be in the project?",
    answer:
      "After providing your software services requirements and objectives, your involvement can be as hands-on or hands-off as you prefer. We take care of the day-to-day management, keeping you informed with regular updates.",
  },
  {
    question:
      "How do you ensure the project aligns with my business goals?",
    answer:
      "We work closely with you during the planning phase to understand your vision and objectives, ensuring that the final product is tailored to your specific business needs and goals.",
  },
  {
    question: "What is the typical timeline for project completion?",
    answer:
      "Timelines vary depending on the project's complexity and scope, but we commit to delivering high-quality results on time and within budget. We’ll provide a detailed timeline during the initial planning phase.",
  },
];
