export const mobileSpecialData = [
  {
    heading: "Government Trust and Recognition:",
    text: "Endorsed by governments, including as chairs of the UK Government’s APPGs, our development practices are trusted at the highest levels for security and innovation.",
  },
  {
    heading: "Award-Winning Excellence:",
    text: "Our commitment to excellence in mobile app development has been recognised with numerous industry awards, showcasing our leadership in technology innovation.",
  },
  {
    heading: "Extensive Experience:",
    text: "With over 30,000 hours devoted to mobile app development and more than 200 projects successfully launched, our experience ensures robust and scalable app solutions.",
  },
  {
    heading: "Elite Global Team:",
    text: "Our developers rank in the top 5% globally, bringing world-class expertise to each project and ensuring cutting-edge solutions tailored to your needs. ",
  },
  {
    heading: "Global Presence with Local Expertise:",
    text: "Active in 13 countries, our global presence paired with local expertise allows us to deliver mobile solutions that resonate worldwide and meet specific market needs. ",
  },
  {
    heading: "Cost-Effective Solutions without Compromise:",
    text: "We offer economically efficient mobile app solutions that maintain high-quality standards, ensuring you achieve a significant return on investment without compromising on functionality. ",
  },
];
