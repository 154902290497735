import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesUI = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Empowering Your Digital Presence with Expert UI/UX Design Services"
        text="Unlock your digital potential with Pixelette Technologies. We tailor UI/UX design solutions to solve business challenges effectively, ensuring intuitive, engaging, and functional designs that drive user satisfaction and business success: "
        data={data.uiServicesData}
      />
    </>
  );
};

export default ServicesUI;
