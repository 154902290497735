import React from "react";
import Components from "..";
import assets from "../../assets";
import Marquee from "react-fast-marquee";

const OurClients = (props) => {
  return (
    <div className="deliverBenifits">
      <Components.Feature.Container className="main">
        <center data-aos-duration={`700`} data-aos="fade-up">
          <Components.Feature.Heading className="primary " id="h_ani">
          {props.title}
          </Components.Feature.Heading>
        </center>
        <div data-aos-duration={`500`} data-aos="fade-up">
          <Cards
          title1={props.title1}
          description1={props.description1}
          title2={props.title2}
          description2={props.description2} 
          title3={props.title3} 
          description3={props.description3}
          title4={props.title4} 
          description4={props.description4}
          />
        </div>
      </Components.Feature.Container>
    </div>
  );
};

const Cards = (props) => {

  return (
    <>
    <div className="benefit-line" style={{width:'80%', height: '2px', backgroundColor: 'grey', margin: '0 auto', marginTop: '50px'}}>
        <div style={{position: 'absolute', width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'white'}}></div>
        <div style={{position: 'absolute', width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'white', left: '25%'}}></div>
        <div style={{position: 'absolute', width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'white', left: '50%'}}></div>
        <div style={{position: 'absolute', width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'white', left: '75%'}}></div>
    </div>
    <div className="benifitsCard">
            <div>
            <h1>{props.title1}</h1>
            <p>{props.description1}</p>
            </div>
            <div>
            <h1>{props.title2}</h1>
            <p>{props.description2}</p>
            </div>
            <div>
            <h1>{props.title3}</h1>
            <p>{props.description3}</p>
            </div>
            <div>
            <h1>{props.title4}</h1>
            <p>{props.description4}</p>
            </div>
    </div>
    </>
  );
};

export default OurClients;
