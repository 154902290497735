export const blockFaqs = [
  {
    question: "What is blockchain?",
    answer:
      "Blockchain is a digital ledger technology where transactions are recorded in real-time and in a secure, unchangeable manner across a network of computers.",
  },
  {
    question: "What distinguishes public, private, and consortium blockchains?",
    answer:
      "Public blockchains are open to all and maintain complete transparency. Private blockchains restrict access and are typically managed by a single organisation. Consortium blockchains are managed by a group of organisations, providing a balance between the two.",
  },
  {
    question: "Can anyone create a blockchain?",
    answer:
      "Yes, anyone with the necessary coding skills and understanding of blockchain technology can develop their own blockchain system.",
  },
  {
    question: "How are smart contracts utilised in blockchain?",
    answer:
      "Smart contracts are self-executing contracts with the terms of the agreement between buyer and seller being directly written into code, automating and enforcing contractual obligations.",
  },
  {
    question: "How can blockchain benefit specific industries?",
    answer:
      "Blockchain can bring numerous benefits like improved traceability, increased security, reduced costs, and streamlined processes to industries such as finance, healthcare, supply chain, and more.",
  },
  {
    question:
      "What potential does blockchain hold for increasing transparency and efficiency in my business?",
    answer:
      "Blockchain’s immutable and decentralised nature can enhance transparency in transactions and improve operational efficiency by reducing the need for intermediaries.",
  },
  {
    question:
      "How are cryptocurrencies like Bitcoin and Ether related to blockchain?",
    answer:
      "Cryptocurrencies are digital or virtual currencies that are secured by blockchain technology, which underpins and facilitates the existence of cryptocurrency markets.",
  },
  {
    question: "What are blockchain's security features and their reliability?",
    answer:
      "Blockchain employs cryptographic hashing and consensus protocols to maintain security, making it extremely difficult to alter transaction data.",
  },
  {
    question:
      "How does blockchain manage scalability and resource consumption issues?",
    answer:
      "Innovations like layer-two solutions, sharding, and changes in consensus mechanisms are some of the approaches taken to address scalability and reduce resource consumption in blockchain.",
  },
  {
    question:
      "What legal implications should I consider when using blockchain in my operations?",
    answer:
      "Depending on your region, various regulatory and legal considerations regarding data protection, financial regulation, and contractual enforcement need to be considered when integrating blockchain into your business.",
  },
];
