import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import Components from "../..";
import assets from "../../../assets";

const HeroSection = () => {
  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="heroSection-background">
          <img src={assets.home.heroBackGround} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSection">
        <Components.Feature.Container className="main margins">
          <blockquote>
            <img src={assets.home.box_1} alt="box" />
            <img src={assets.home.box_2} alt="box" />
            <img src={assets.home.box_3} alt="box" />
            <img src={assets.home.box_4} alt="box" />
            <img src={assets.home.box_5} alt="box" />
            <img src={assets.home.box_6} alt="box" />
            <img src={assets.home.box_7} alt="box" />
          </blockquote>
          <h1 data-aos="zoom-out" data-aos-duration={`700`}>
            Leading the way in AI and Blockchain
          </h1>
          <Components.Feature.Text
            animation="zoom-in"
            duration="1000"
            className="primary"
          >
            Welcome to Pixelette Technologies, where we transform the elusive
            ‘what if’ into the tangible ‘what is’. As specialists in AI and
            Blockchain, we deliver end-to-end IT solutions from conception
            through deployment, ensuring seamless integration and support.
          </Components.Feature.Text>
          <div>
            <ScrollLink to="contactUs"  smooth={true} duration={500}>
              <Components.Feature.Button
                className="primary"
                animation="fade-up"
                duration="1100"
              >
                Begin Your Tech Journey
              </Components.Feature.Button>
            </ScrollLink>
            <RouterLink to="start-ups">
              <Components.Feature.Button
                className="primary"
                animation="fade-up"
                duration="1300"
              >
                Explore Funding Options
              </Components.Feature.Button>
            </RouterLink>
          </div>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSection;
