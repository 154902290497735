import React from "react";
import Components from "..";

const SwiperCard = (props) => {
  return (
    <div
      className="swiperCard"
      data-aos={props.animation}
      data-aos-duration={props.duration}
    >
      <figure>
        <img src={props.image} alt="banner" />
      </figure>
      <Components.Feature.Text className="primary--bold">
        {props.name}
      </Components.Feature.Text>
      <Components.Feature.Text className="titory--bold">
        {props.description}
      </Components.Feature.Text>
    </div>
  );
};

export default SwiperCard;
