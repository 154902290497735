import React from "react";
import Components from "../components";

const Research = () => {
  return (
    <>
      <Components.UI.HeroSectionResearch />
      {/* <Components.UI.ResearchInquiryForm /> */}
       <Components.Common.LetsTalkSection /> 
    </>
  );
};

export default Research;
