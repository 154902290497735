import React from "react";
import Components from "../components";

const DigitalMarketing = () => {
  return (
    <>
      <Components.UI.HeroSectionDigital />
      <Components.Common.OurClients />
      <Components.UI.ServicesDigital />
      <Components.UI.DigitalProcess />
      <Components.Common.WhatMakesUsSpecial />
      <Components.Common.TransformConceptSection />
      <Components.Common.CaseStudies />
      <Components.UI.ExpertiseSoftware />
      <Components.Common.AchievementsSection />
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection />
      <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection />
    </>
  );
};

export default DigitalMarketing;
