import  { useState, useEffect } from "react";
import CountUp from "react-countup";
import Components from "../..";
import assets from "../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";

const AboutUsSection = () => {
  const [visibleSections, setVisibleSections] = useState({});

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      // threshold: 1.0,
      threshold: 0.5,
    };

    // const handleIntersect = (entries) => {
    //   entries.forEach((entry) => {
    //     setVisibleSections((prev) => ({
    //       ...prev,
    //       [entry.target.id]: entry.isIntersecting,
    //     }));
    //   });
    // };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.id;
        if (visibleSections[sectionId]) {
          return;
        }
        
        const isIntersecting = entry.isIntersecting;
        
        setVisibleSections((prev) => ({
          ...prev,
          [sectionId]: isIntersecting,
        }));
    
        // Toggle visibility of number and its respective symbol based on intersection
        const numberElement = entry.target.querySelector("h1");
        const symbolElement = entry.target.querySelector("h1 + div");

        if (numberElement && symbolElement) {
          numberElement.style.visibility = isIntersecting ? "visible" : "hidden";
          symbolElement.style.visibility = isIntersecting ? "visible" : "hidden";
        }

        // if (numberElement) {
        //   numberElement.style.visibility = isIntersecting ? "visible" : "hidden";
        // }
    
        // if (symbolElement) {
        //   symbolElement.style.visibility = isIntersecting ? "visible" : "hidden";
        // }
      });
    };
    

    const observer = new IntersectionObserver(handleIntersect, options);

    const targets = document.querySelectorAll(".counter-section");
    targets.forEach((target) => observer.observe(target));

    return () => {
      targets.forEach((target) => observer.unobserve(target));
    };
  }, [visibleSections]);

  return (
    <>
      <Components.Feature.Container className="main">
        <div className="aboutUsSection-background">
          <img src={assets.home.aboutLeftBackground} alt="background" />
          <img src={assets.home.aboutRightBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="aboutUsSection">
        <Components.Feature.Container className="main margins">
          <center>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              id="h_ani"
            >
              Discover Pixelette Technologies
            </Components.Feature.Heading>

            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="400"
            >
              At Pixelette Technologies, we pioneer advanced blockchain, AI, and
              metaverse solutions across the globe. Our team of experts craft
              bespoke strategies that empower businesses to excel in a
              digital-first world.
            </Components.Feature.Text>
            <Link to="about-us">
              <Components.Feature.Button
                className="primary"
                animation="fade-up"
                duration="300"
              >
                Explore Our Story
                <FiExternalLink />
              </Components.Feature.Button>
            </Link>
          </center>
          <section data-aos="fade-up" data-aos-duration="1000">
            <div>
              <Components.Feature.Heading className="secondry">
                Snapshot of Success
              </Components.Feature.Heading>
              <Components.Feature.Text className="secondry">
                With years of global experience and a track record of successful
                projects, our stats are a testament to our excellence in the
                tech industry.
              </Components.Feature.Text>
            </div>
            <div>
              <section className="counter-section" id="counter-section-1">
                <h1>
                  {visibleSections["counter-section-1"] && (
                    <CountUp start={0} end={200} duration={10} suffix="+"/>
                  )}
                </h1>
                <div>
                  <p>Projects Completed</p>
                </div>
              </section>
              <div className="counter-section" id="counter-section-2">
                <h1>
                  {visibleSections["counter-section-2"] && (
                    <CountUp start={0} end={210} duration={10} />
                  )}
                  +
                </h1>
                <div>
                  <p>Global Team Members</p>
                </div>
              </div>
              <section className="counter-section" id="counter-section-3">
                <h1>
                  {visibleSections["counter-section-3"] && (
                    <CountUp start={0} end={30000} duration={10} />
                  )}
                  +
                </h1>
                <div>
                  <p>Hours in Development</p>
                </div>
              </section>
              <div className="counter-section" id="counter-section-4">
                <h1>
                  {visibleSections["counter-section-4"] && (
                    <CountUp start={0} end={100} duration={10} />
                  )}
                  +
                </h1>
                <div>
                  <p>Happy Clients</p>
                </div>
              </div>
              <section className="counter-section" id="counter-section-5">
                <h1>
                  {visibleSections["counter-section-5"] && (
                    <CountUp start={0} end={97} duration={10} />
                  )}
                  %
                </h1>
                <div>
                  <p>Customer Satisfaction</p>
                </div>
              </section>
              <div className="counter-section" id="counter-section-6">
                <h1>
                  {visibleSections["counter-section-6"] && (
                    <CountUp start={0} end={13} duration={10} />
                  )}
                </h1>
                <div>
                  <p>Global Locations</p>
                </div>
              </div>
            </div>
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default AboutUsSection;
