import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import Components from "..";
import data from "../../data";
import MobileNav from "./MobileNav";
import assets from "../../assets";

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  function handleNavMenu() {
    setActiveMenu(!activeMenu);
    console.log("Hello world");
  }

  useEffect(() => {
    document.body.style.overflowY = activeMenu ? "hidden" : "auto";
  }, [activeMenu]);

  return (
    <nav>
      <Components.Feature.Container className="main margins">
        <section>
          <Link to="/">
            <img
              src={assets.commonAssests.logo}
              alt="logo"
              data-aos="flip-left"
              data-aos-duration="1000"
            />
          </Link>
          <div>
            {data.navMenus.map((el, index) => (
              el.subMenus ? (
                <Components.Common.NavbarDropDown
                  name={el.name}
                  subMenus={el.subMenus}
                  key={el.id}
                />
              ) : (
                <NavLink
                  key={el.id}
                  to={el.to}
                  data-aos-duration={`${index}00`}
                  data-aos="fade-down"
                >
                  {el.name}
                </NavLink>
              )
            ))}
          </div>
          <section>
            <Link
              to="/contact-us"
              data-aos-duration={`1000`}
              data-aos="fade-down"
            >
              <Components.Feature.Button className="primary">
                Contact Us
              </Components.Feature.Button>
            </Link>
          </section>
          <blockquote>
            <button onClick={handleNavMenu}>
              {activeMenu ? "" : <IoMenuOutline />}
            </button>
          </blockquote>
        </section>
      </Components.Feature.Container>
      {activeMenu && <MobileNav onClick={handleNavMenu} />}
    </nav>
  );
};

export default Navbar;
