import Components from "..";
import assets from "../../assets";
import Marquee from "react-fast-marquee";

const TrustedClients = () => {
  return (
    <div className="ourClients">
      <Components.Feature.Container className="main">
        <center data-aos-duration={`700`} data-aos="fade-up">
          {/* <Components.Feature.Heading className="primary " id="h_ani">
            Trusted by Visionary Startups, Industry <br />
            Leaders and Governments Alike.{" "}
          </Components.Feature.Heading> */}
        </center>
        <div data-aos-duration={`500`} data-aos="fade-up">
          <ImageMarquee />
        </div>
      </Components.Feature.Container>
    </div>
  );
};

const ImageMarquee = () => {
  const images = [
    assets.commonAssests.ec_1,
    assets.commonAssests.ec_2,
    assets.commonAssests.ec_3,
    assets.commonAssests.ec_4,
    assets.commonAssests.ec_5,
    assets.commonAssests.ec_6,
    assets.commonAssests.ec_7,
    assets.commonAssests.ec_8,
  ];

  return (
    <Marquee speed={60}>
      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        {images.concat(images).map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`icon ${index}`}
            style={{ flex: "0 0 auto", marginRight: "4.8rem" }}
          />
        ))}
      </div>
    </Marquee>
  );
};

export default TrustedClients;
