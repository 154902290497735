export const refundPolicy = [
  {
    title: "",
    lists: [
      {
        title: `Pixelette Technologies Ltd. undertakes all projects under the scope of objectives that determine the direction/route followed by the project. These projects include:  `,
        subList: [
          "Blockchain technology services & solutions",
          "Web Design",
          "Web Development",
          "Mobile Application Development",
          "Software Development",
          "Search Engine optimisation",
          "Digital Marketing/Hosting Services",
          "Maintenance of a website",
        ],
      },
    ],
    description:
      "Pixelette Technologies Ltd. carefully analyses each project before accepting it, leading to the creation of a comprehensive scope document. This document outlines the project’s goals, major deliverables, crucial milestones, timeline, assumptions, and possible constraints. Upon review and agreement, a formal contract is signed with the client to ensure a clear understanding of project requirements and minimise the risk of project cancellations, reversals, or disputes. The scope document serves as a crucial tool for aligning the expectations of both the client and Pixelette Technologies Ltd. and promotes clear and effective communication among all parties involved in the project.",
  },

  {
    title: "Cancellation by the client",
    lists: [
      {
        title: `In the event that a client wants to cancel a project, they must provide written notice to Pixelette Technologies as soon as possible. The following cancellation fees will apply in accordance with the Consumer Contracts (Information, Cancellation, and Additional Charges) Regulations 2013:`,
        subList: [],
      },
    ],
  },
  {
    title: "",
    lists: [
      {
        title: `A: If the cancellation is made more than 14 days before the agreed start date, Pixelette Technologies will refund all payments made to that point, minus a 10% administration fee to cover costs incurred by Pixelette Technologies.`,
        subList: [],
      },
    ],
  },
  {
    title: "",
    lists: [
      {
        title: `B: If the cancellation is made within 14 days of the agreed start date, Pixelette Technologies will keep all payments made to that point, as the project preparations would have already started and significant costs may have already been incurred.`,
        subList: [],
      },
    ],
  },
  {
    title: "Cancellation by Pixelette Technologies",
    lists: [
      {
        title: `Pixelette Technologies reserves the right to cancel a project at any time if the client breaches any of its obligations under the contract or if Pixelette Technologies determines that it is not commercially viable to continue with the project. In such circumstances, Pixelette Technologies will refund all payments made to date, minus any costs incurred by Pixelette Technologies in connection with the project.`,
        subList: [],
      },
    ],
  },
  {
    title: "Refunds",
    lists: [
      {
        title: `Refunds will only be made to the original payment method and must be processed within 14 days of the cancellation, in accordance with the Consumer Rights Act 2015. Refunds will be issued in accordance with the terms outlined in this policy and any applicable law.`,
        subList: [],
      },
    ],
  },
  {
    title: "Disputes",
    lists: [
      {
        title: `In the event of a dispute regarding cancellations or refunds, the parties will make a good faith effort to resolve the matter through mediation or arbitration, as agreed in writing. If the dispute cannot be resolved, it may be referred to the relevant courts for resolution in accordance with the Alternative Dispute Resolution for Consumer Disputes (Competent Authorities and Information) Regulations 2015.`,
        subList: [],
      },
    ],
  },
  {
    title: "Changes to the cancellation and refund policy",
    lists: [
      {
        title: `Pixelette Technologies reserves the right to change this policy at any time, with reasonable notice given to clients of any changes. The most current Pixelette Technologies reserves the right to change this policy at any time, with reasonable notice given to clients of any changes. The most current version of the policy will be available on this webpage.version of the policy will be available on this webpage.`,
        subList: [],
      },
    ],
  },
  {
    title: "Contact",
    description:
      "If you have any questions about this Privacy Policy, please contact us:",
    
  },
];
