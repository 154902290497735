import Components from "..";
import ContactForm from "../ContactForm";

const LetsTalkSection = () => {
  return (
    <>
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default LetsTalkSection;
