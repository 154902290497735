import React from "react";
import Components from "../..";
import assets from "../../../assets";
import { Link } from "react-scroll";
const HeroSectionAiServices = () => {

  const imageStyle = {
    width: '100px', // Adjust the width as needed
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover', // Ensure the image covers the entire area without stretching
    borderRadius: '8px', // Optional: Add border radius to the images
  };

  return (
    <>
      <Components.Feature.Container className="main margins">
        <div className="heroSectionAiServices-background">
          <img src={assets.aiServices.heroSectionBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="heroSectionAiServices">
        <Components.Feature.Container className="main margins">
          <blockquote>
            <img src={assets.aiServices.box_1} alt="box"/>
            <img src={assets.aiServices.box_3} alt="box" style={imageStyle}/>
            <img src={assets.aiServices.box_2} alt="box" />
            <img src={assets.aiServices.box_4} alt="box" style={imageStyle}/>
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`2000`}
            >
              Innovate, Transform and Excel with AI
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="2200"
            >
              Pixelette Technologies offers tailored AI solutions to elevate
              businesses. With our expertise, we streamline operations and drive
              industry innovation. Partner with us to unlock AI's growth
              potential.
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="2400"
            >
              Discover AI
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionAiServices;
