import React from "react";
import Components from "../..";
import data from "../../../data";

const ExpertiseSoftware = () => {
  return (
    <>
      <Components.Common.ExpertiseSection
        heading="Expertise Across Industries: Tailored Blockchain Solutions"
        text="Spanning Sectors: From healthcare to finance, our AI proficiency is shaping the future of diverse industries with precision-crafted solution"
        data={data.expertiseAiData}
      />
    </>
  );
};

export default ExpertiseSoftware;
