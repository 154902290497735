import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";

const ServicesStaff = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Features Of Our Software Development"
        text="Pixelette Technologies provides versatile staff augmentation solutions with a range of convenient features, empowering companies to operate seamlessly at peak efficiency."
        data={data.staffServicesData}
      />
    </>
  );
};

export default ServicesStaff;
