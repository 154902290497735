import React from "react";
import Components from "../components";
import data from "../data";

const StaffAugmented = () => {
  return (
    <>
      <Components.UI.HeroSectionStaff />
      <Components.Common.OurClients />
      <Components.UI.ServicesStaff />
      {/* <Components.UI.StaffProcess /> */}
      <Components.Common.WhatMakesUsSpecial />
      <Components.Common.UnlockBusinessPotential
        heading="Wish to hire on-demand employees, but not sure where to start?"
        text=""
        btnText="Talk to our expert"
      />
      {/* <Components.Common.TransformConceptSection /> */}
      {/* <Components.Common.CaseStudies /> */}
      {/* <Components.UI.ExpertiseStaff /> */}
      {/* <Components.Common.AchievementsSection /> */}
      <Components.Common.Partners />
      <Components.Common.Testimonial />
      <Components.Common.FaqsSection data={data.staffFaqs} />
      {/* <Components.Common.ContactUs />
      <Components.Common.LetsTalkSection /> */}
      <Components.Common.EvaluateBusiness />
    </>
  );
};

export default StaffAugmented;
