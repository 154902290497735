import React from "react";
import Components from "../..";
import data from "../../../data";
import assets from "../../../assets";
const ServicesSoftware = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="cardSectionBackground">
          <img
            src={assets.commonAssests.serviceSectionBackground}
            alt="aiServices"
          />
        </div>
      </Components.Feature.Container>
      <Components.Common.CardSectionGrid
        heading="Custom Software Solutions for Every Business Need"
        text="Enhance your operational efficiency with Pixelette Technologies' tailored software solutions. Explore how we develop software that addresses your specific challenges and drives your business forward."
        data={data.softwareServicesData}
      />
    </>
  );
};

export default ServicesSoftware;
