import React from "react";
import Components from "..";
import NewServiceCard from "../feature/NewServiceCard";

const CardSectionGrid = (props) => {
  return (
    <>
      <div className="cardSectionGrid">
        <Components.Feature.Container className="main margins">
          <center>
            <Components.Feature.Heading
              className="secondry"
              animation="fade-up"
              duration="300"
              id="h_ani"
            >
              {props.heading}
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="500"
            >
              {props.text}
            </Components.Feature.Text>
          </center>
          <section>
          <Components.Feature.NewServiceCard data={props.data} />
          </section>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default CardSectionGrid;
