import React from "react";
import Components from "../..";
import data from "../../../data";

const TechnologyStackUI = () => {
  return (
    <>
      <Components.Common.TechnologyGrid
        heading="Empowering Design Excellence: Our UI/UX Toolkit"
        text="Harness the power of leading UI/UX design tools to transform your ideas into compelling digital experiences."
        data={data.technologyStackUI}
      />
    </>
  );
};

export default TechnologyStackUI;
