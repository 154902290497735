import React from "react";
import Components from "..";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const TeamCard = (props) => {
  return (
    <div className="teamCard" data-aos={props.animation}>
      <img src={props.profile} alt="profile" />
      <h5 data-aos="fade-up">{props.name}</h5>
      <Components.Feature.Text className="primary" animation="fade-up">
        {props.role}
      </Components.Feature.Text>
      <div data-aos="fade-up">
        <FaFacebookF />
        <FaLinkedinIn />
        <FaXTwitter />
      </div>
    </div>
  );
};

export default TeamCard;
