import React from "react";
import Components from "../..";
import assets from "../../../assets";
import {Link} from "react-scroll";

const HeroSectionWebDevelopment = () => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="heroSection-web-background">
          <img
            src={assets.webDevelopment.heroSectionBackground}
            alt="background"
          />
        </div>
      </Components.Feature.Container>
      <div className="heroSection-web">
        <Components.Feature.Container className="main">
          <blockquote>
            <img src={assets.webDevelopment.box_1} alt="box" />
            <img src={assets.webDevelopment.box_3} alt="box" />
            <img src={assets.webDevelopment.box_2} alt="box" />
            <img src={assets.webDevelopment.box_4} alt="box" />
          </blockquote>
          <center>
            <Components.Feature.Heading
              className="heroHeading"
              animation="zoom-out"
              duration={`600`}
            >
              Transform Your Website Experience
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="primary"
              animation="zoom-in"
              duration="700"
            >
              Maximise your digital impact with Pixelette Technologies. Our
              bespoke web development delivers aesthetically pleasing, highly
              functional websites optimised for user engagement and conversion.
              Streamline your path from concept to launch with our tailored
              solutions, designed to meet your unique business needs. Elevate
              your online presence today.
            </Components.Feature.Text>
            <Link to="contactUs"  smooth={true} duration={500}>
            <Components.Feature.Button
              className="primary"
              animation="fade-up"
              duration="800"
            >
              Build Your Site Now
            </Components.Feature.Button>
            </Link>
          </center>
        </Components.Feature.Container>
      </div>
    </>
  );
};

export default HeroSectionWebDevelopment;
