import React from "react";
import Components from "..";
import { LineThin } from "../../assets/common";
import { LineThick } from "../../assets/common";

const BottomForm = () => {
  return (
    <>
      <Components.Feature.Container className="main margins">
        <img src={LineThick} alt="" width="100%" 
        style={{
          paddingTop:"8rem"
        }}
        />
       <div className="evaluateBussiness">
          <header>
            <Components.Feature.Heading className="secondry">
              Take the next step <br />
            </Components.Feature.Heading>
            <Components.Feature.Text
              className="secondry"
              animation="fade-up"
              duration="700"
            >
              <span className="form-description">
                Don’t let technical hurdles stand in your way. Let <br /> us
                help you bring your vision to life with <br /> innovative,
                cost-effective and reliable solutions. <br /> Get in touch.
              </span>
            </Components.Feature.Text>
          </header>
          <div>
            <Components.Common.ContactUs
              header={true}
              backgrounds={true}
              insideHeading="Book a Discovery Session"
            />
          </div>
        </div>
        <img src={LineThin} alt="" width="100%"
        style={{
          paddingTop:"2rem",
          paddingBottom:"2rem"
        }}
        />         
      </Components.Feature.Container>
    
    </>
  );
};

export default BottomForm;
