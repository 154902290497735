import PropTypes from "prop-types";
import Components from "..";
import assets from "../../assets";
import AwardCards from "./AwardCards";

const AwardWinning = ({ heading, text, data }) => {
  return (
    <>
      <Components.Feature.Container className="main">
        <div className="specialSection-background">
          <img src={assets.aboutUs.howWeWorkBackground} alt="background" />
        </div>
      </Components.Feature.Container>
      <div className="specialSection">
        <Components.Feature.Container className="main margins">
          <section>
            <div className="award-text">
              <Components.Feature.Heading
                className="secondry"
                animation="fade-up"
                duration="400"
                id="h_ani"
              >
                {heading ? (
                  heading
                ) : (
                  <>
                    Bagged awards, <br />
                    accreditations <br />
                    and partnerships
                    <p
                      style={{
                        marginTop: "1.5rem",
                        fontSize: "2rem",
                        lineHeight: "3rem",
                        fontWeight: "200",
                      }}
                    >
                      Signifying our commitment to client
                      <br /> satisfaction and quality standards.
                    </p>
                  </>
                )}
              </Components.Feature.Heading>
              <div>
                {(data || []).map((el, index) => (
                  <Item key={index} heading={el.heading} text={el.text} />
                ))}
              </div>
              <div></div>
            </div>
            <AwardCards />
          </section>
        </Components.Feature.Container>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

AwardWinning.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

const Item = ({ heading, text }) => {
  return (
    <>
      <div>
        <section>
          <img src={assets.commonAssests.dot} alt="dot" />
        </section>
        <div>
          <Components.Feature.Text className="primary--bold">
            {heading}
          </Components.Feature.Text>
          <Components.Feature.Text className="titory--bold">
            {text}
          </Components.Feature.Text>
        </div>
      </div>
    </>
  );
};

Item.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default AwardWinning;
